import clsx from "clsx";
import AgentEditField from "./AgentEditField";
import { convertSnakeToTitleCase } from "../../utils/snake-to-capital";

export interface ICombinedAgentEditFieldProps {
  fields: {
    title: string;
    text: string;
    onChange?: (text: string) => void;
    isNumber?: boolean;
  }[];
  showHorizontal?: boolean;
  isDisabled?: boolean;
}

const CombinedAgentEditField = ({
  fields,
  showHorizontal,
  isDisabled,
}: ICombinedAgentEditFieldProps) => {
  return (
    <div
      className={`border-1 border-[#E4E4E7] rounded-md overflow-hidden flex items-stretch bg-white w-full ${showHorizontal ? "flex-row" : "flex-col"}`}
    >
      {fields.map((field, idx) => (
        <div
          className={clsx(
            "w-full h-auto",
            showHorizontal && idx > 0 && idx < fields.length && "border-l-1",
          )}
          key={idx}
        >
          <AgentEditField
            key={idx}
            text={field.text}
            isNumber={field.isNumber}
            onChange={field.onChange}
            title={field.title}
            placeholder={`Add ${convertSnakeToTitleCase(field.title)}`}
            maxRows={99999}
            minRows={showHorizontal ? 4 : 1}
            noBorder
            isDisabled={isDisabled}
          />
        </div>
      ))}
    </div>
  );
};

export default CombinedAgentEditField;
