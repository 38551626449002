import { addNotification } from "@/shared/states/notification";
import { StytchClientType } from "@/shared/types/stytch";
import { setLoginStep } from "../states/login";
import { LOGIN_STEP } from "../types";

export const sendMagicLinkUseCase = async ({
  stytch,
  email,
}: {
  stytch: StytchClientType;
  email: string;
}) => {
  try {
    await stytch.magicLinks.email.discovery.send({
      email_address: email,
      discovery_redirect_url: `${window.location.origin}/auth`,
    });
    setLoginStep(LOGIN_STEP.EMAIL_SUCCESS);
  } catch (error) {
    addNotification({
      message: (error as Error).message || "Error logging in",
      type: "error",
    });
    throw new Error((error as Error).message);
  }
};
