import { ChevronRight } from "lucide-react";
import React, { ReactNode } from "react";

interface BreadcrumbItemProps {
  title: ReactNode;
  href: string;
  description?: string;
  isLast: boolean;
}

interface ZBreadCrumbsProps {
  items: Omit<BreadcrumbItemProps, "isLast">[];
  icon?: React.ReactNode;
}

const BreadcrumbItem: React.FC<BreadcrumbItemProps> = ({
  title,
  description,
  isLast,
}) => (
  <>
    <span
      className={`${isLast ? "text-[#11181C]" : "text-[#67657A]"} cursor-default text-xs `}
    >
      {isLast ? <span>{title}</span> : <span>{title}</span>}
      {description && (
        <span className="ml-2 text-[#006FEE]">• {description}</span>
      )}
    </span>
    {!isLast && <ChevronRight size={14} className="mx-2 text-[#636075]" />}
  </>
);

const ZBreadCrumbs: React.FC<ZBreadCrumbsProps> = ({ items, icon }) => {
  return (
    <nav className="flex items-center">
      {icon && <span className="mr-2">{icon}</span>}
      {items.map((item, index) => (
        <BreadcrumbItem
          key={index}
          {...item}
          isLast={index === items.length - 1}
        />
      ))}
    </nav>
  );
};

export default ZBreadCrumbs;
