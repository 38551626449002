import { simplifyFileTypes } from "@/shared/components/custom/file-upload";
import { AGENT_TYPES, GapAssessmentTypes } from "../../types";


const DEFAULT_ALLOWED_FILE_TYPES = simplifyFileTypes([]).map(
  (key: string) => key
);


export const helper = {
  [AGENT_TYPES.QUESTIONNAIRE]: {
    title: "Auto-populate a Security Questionnaire",
    desc:
      "Zania will refer to previous questionnaires filled by you and auto-fill a new questionnaire in seconds!",
    inputs: [
      {
        label: "Agent Session Title",
        placeholder: "Add title",
        type: "text",
        key: "title",
        isRequired: true,
      },
    ],
    uploads: [
      {
        title: "Upload Questionnaire",
        allowedFileTypes: [
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.ms-excel",
        ],
        maxFiles: 1,
        type: "main",
      },
      {
        title: "Upload Knowledge Base",
        allowedFileTypes: ["application/json", "application/pdf"],
        maxFiles: 30,
        type: "kb",
      },
    ],
    dropdown: null,
    toggle: null,
  },
  [AGENT_TYPES.RISK_ASSESSMENT]: {
    title: "Conduct a Risk Assessment",
    desc:
      "Zania analyzes uploaded documents against the chosen framework, automatically assessing risks and identifying gaps.",
    inputs: [
      {
        label: "Agent Session Title",
        placeholder: "Add title",
        type: "text",
        key: "title",
        isRequired: true,
      },
    ],
    uploads: [
      {
        title: "Upload Evidences",
        allowedFileTypes: DEFAULT_ALLOWED_FILE_TYPES,
        maxFiles: 30,
        type: "main",
      },
    ],
    dropdown: {
      label: "Framework",
      placeholder: "Select a framework",
      key: "framework",
      options: [
        {
          label: "NIST CSF 2",
          value: "nist-csf-2",
          isDefault: true,
        },
      ],
    },
    toggle: null,
  },
  [AGENT_TYPES.GAP_ASSESSMENT]: {
    title: "Conduct Gap Assessment",
    desc: "Zania will compile evidences required to conduct gap assessment.",
    inputs: [
      {
        label: "Agent Session Title",
        placeholder: "Add title",
        type: "text",
        key: "title",
        isRequired: true,
      },
    ],
    uploads: [
      {
        title: "Upload Evidences",
        allowedFileTypes: DEFAULT_ALLOWED_FILE_TYPES,
        maxFiles: 100,
        type: "main",
      },
    ],
    dropdown: {
      label: "Framework",
      placeholder: "Select a framework",
      key: "framework",
      options: [
        {
          label: "SOC 2 Type I",
          value: GapAssessmentTypes.SOC2_TYPE1,
          isDefault: false,
        },
        {
          label: "SOC 2 Type II",
          value: GapAssessmentTypes.SOC2_TYPE2,
          isDefault: true,
        },
        // No DORA in the webapp for now
        // {
        //   label: 'DORA',
        //   value: GapAssessmentTypes.DORA,
        //   isDefault: false,
        // },
      ],
    },
    toggle: {
      label: "Customize Assessment",
    },
  },
  [AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT]: {
    title: "Third-party Risk Assessment",
    desc: "Zania will use AI to conduct comprehensive vendor evaluations",
    inputs: [
      {
        label: "Agent Session Title",
        placeholder: "Add title",
        type: "text",
        key: "title",
        isRequired: true,
      },
      {
        label: "Vendor Name",
        placeholder: "Add vendor name",
        type: "text",
        key: "vendorName",
        isRequired: true,
      },
      {
        label: "Describe the Scope of the Integration",
        placeholder: "Add description",
        type: "textarea",
        key: "scope",
        isRequired: true,
      },
    ],
    dropdown: null,
    uploads: [],
  },
};