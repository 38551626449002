import { cn } from "@/shared/utils/classname-merger";
import { Popover, PopoverContent, PopoverTrigger } from "@nextui-org/react";
import { Check, RefreshCw, X } from "lucide-react";
import { useCallback, useState } from "react";

export interface AcceptAndRejectPopOverProps {
  isOpen: boolean;
  type: "SUCCESS" | "ERROR";
  handleClickForAcceptRetryRejectButtons: (
    action: "CONTINUE" | "DISCARD"
  ) => void;
  handleClose: () => void;
}

export const AcceptAndRejectPopOver = ({
  isOpen,
  handleClickForAcceptRetryRejectButtons,
  type,
  handleClose,
}: AcceptAndRejectPopOverProps) => {
  const handleAcceptAndRetry = useCallback(
    () => handleClickForAcceptRetryRejectButtons("CONTINUE"),
    [handleClickForAcceptRetryRejectButtons]
  );

  const handleDiscard = useCallback(
    () => handleClickForAcceptRetryRejectButtons("DISCARD"),
    [handleClickForAcceptRetryRejectButtons]
  );
  const [count, setcount] = useState(0);

  return (
    <Popover
      isOpen={isOpen}
      onOpenChange={(newIsOpen) => {
        if (!newIsOpen) {
          // handleClose();
        }
      }}
      offset={-4}
      placement="bottom-start"
    >
      <PopoverTrigger>
        <div />
      </PopoverTrigger>
      <PopoverContent className="p-3 pb-1 rounded-lg border-1 border-[#E4E4E7] shadow">
        <div>
          <div
            className={cn(
              "text-sm font-medium",
              type === "ERROR" && "text-[#F31260]"
            )}
          >
            {type === "SUCCESS" ? "Accept revised version?" : "Error occurred"}
          </div>
          <div className="flex flex-col">
            <div className="flex flex-row gap-[6px] py-2 items-center cursor-pointer">
              {type === "SUCCESS" ? (
                <>
                  <div onClick={handleAcceptAndRetry} onKeyDown={handleDiscard}>
                    <Check size={16} />
                  </div>
                  <div
                    className="text-xs font-medium"
                    onClick={handleAcceptAndRetry}
                    onKeyDown={handleDiscard}
                  >
                    Accept
                  </div>
                </>
              ) : (
                <>
                  <div onClick={handleAcceptAndRetry} onKeyDown={handleDiscard}>
                    <RefreshCw size={16} />
                  </div>
                  <div
                    className="text-xs font-medium"
                    onClick={handleAcceptAndRetry}
                    onKeyDown={handleDiscard}
                  >
                    Accept
                  </div>
                </>
              )}
            </div>
            <div className="flex flex-row gap-[6px] py-2 items-center">
              <div
                className="cursor-pointer"
                onClick={handleDiscard}
                onKeyDown={handleDiscard}
              >
                <X size={16} />
              </div>
              <div
                className="text-xs font-medium cursor-pointer"
                onClick={handleDiscard}
                onKeyDown={handleDiscard}
              >
                Discard
              </div>
            </div>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default AcceptAndRejectPopOver;
// box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
