import { Selection } from "@nextui-org/react";
import { ComponentType, SVGProps } from "react";

export interface NavItemData {
  title: string;
  label?: string;
  Icon: ComponentType<SVGProps<SVGSVGElement>>;
  path: string;
}

export interface AgentLogStepsData {
  id: number;
  completed: boolean;
  title: string;
  isCurrent: boolean;
  description: string[];
  isWaiting: boolean;
}

export interface AgentLogStepsStateData {
  riskAssessmentTab: Selection | "1" | "2" | "3" | "4" | "5";
  doraAssessmentTab: Selection | "1" | "2" | "3" | "4" | "5";
  questionnaireTab: Selection | "1" | "2" | "3" | "4" | "5" | "6";
  stepData: AgentLogStepsData[] | [];
  completedSteps: number;
  isOpen: boolean;
}

export enum AgentConsoleNavigation {
  riskAssessment = "agent/risk-assessment",
  doraAssessment = "agent/gap-assessment",
  questionnaire = "agent/questionnaire",
}

export enum AgentConsoleStepType {
  riskAssessment = "riskAssessment",
  doraAssessment = "doraAssessment",
  questionnaire = "questionnaire",
}

export enum AgentLogId {
  Id1 = 1,
  Id2 = 2,
  Id3 = 3,
  Id4 = 4,
  Id5 = 5,
  Id6 = 6,
}
