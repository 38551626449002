import { Button, ButtonGroup } from "@nextui-org/button";
import { ButtonGroupProps, ButtonProps } from "@nextui-org/react";
import { Spinner } from "@nextui-org/spinner";

const ZButton = ({ children, ...props }: ButtonProps) => {
  return (
    <>
      <Button
        color={props.color || "primary"}
        radius="sm"
        {...props}
        isLoading={false}
      >
        {children}
      </Button>
      {props.isLoading && <Spinner className="ml-1" size="sm" />}
    </>
  );
};

const ZButtonGroup = ({ children, ...props }: ButtonGroupProps) => {
  return <ButtonGroup {...props}>{children}</ButtonGroup>;
};

export { ZButton, ZButtonGroup };
