import { SVGProps } from "react";

const SessionsIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g style={{ mixBlendMode: "multiply" }}>
        <path
          d="M11 8H16M16 12H11M16 16H11M6 20H18C18.5304 20 19.0391 19.7893 19.4142 19.4142C19.7893 19.0391 20 18.5304 20 18V6C20 5.46957 19.7893 4.96086 19.4142 4.58579C19.0391 4.21071 18.5304 4 18 4H6C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20Z"
          stroke={props.color ?? "#7A788E"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="8" cy="8" r="1" fill={props.color ?? "#7A788E"} />
        <circle cx="8" cy="12" r="1" fill={props.color ?? "#7A788E"} />
        <circle cx="8" cy="16" r="1" fill={props.color ?? "#7A788E"} />
      </g>
    </svg>
  );
};

export default SessionsIcon;
