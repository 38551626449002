import { SVGProps } from "react";

const CircleWarning = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <g clipPath="url(#clip0_855_32017)">
        <path
          d="M5.5 7.5V2.5H6.5V7.5H5.5ZM5.5 9.5V8.5H6.5V9.5H5.5Z"
          fill="#F31260"
        />
        <mask id="path-2-inside-1_855_32017" fill="white">
          <path d="M11.5 6C11.5 9.03757 9.03757 11.5 6 11.5C2.96243 11.5 0.5 9.03757 0.5 6C0.5 2.96243 2.96243 0.5 6 0.5C9.03757 0.5 11.5 2.96243 11.5 6ZM1.50085 6C1.50085 8.48481 3.51519 10.4991 6 10.4991C8.48481 10.4991 10.4991 8.48481 10.4991 6C10.4991 3.51519 8.48481 1.50085 6 1.50085C3.51519 1.50085 1.50085 3.51519 1.50085 6Z" />
        </mask>
        <path
          d="M11.5 6C11.5 9.03757 9.03757 11.5 6 11.5C2.96243 11.5 0.5 9.03757 0.5 6C0.5 2.96243 2.96243 0.5 6 0.5C9.03757 0.5 11.5 2.96243 11.5 6ZM1.50085 6C1.50085 8.48481 3.51519 10.4991 6 10.4991C8.48481 10.4991 10.4991 8.48481 10.4991 6C10.4991 3.51519 8.48481 1.50085 6 1.50085C3.51519 1.50085 1.50085 3.51519 1.50085 6Z"
          stroke="#F31260"
          strokeWidth="2"
          mask="url(#path-2-inside-1_855_32017)"
        />
      </g>
      <defs>
        <clipPath id="clip0_855_32017">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CircleWarning;
