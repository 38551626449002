import { get } from "@/infra/rest";
import type { ControlViewer } from "../states/control-viewers";

export interface SessionControlsMap {
  control_id: string;
  members: string[];
  }
  
interface ControlStatusResponse {
  success: boolean;
  session_id: string;
  session_controls: SessionControlsMap[];
}
export const getSessionControlStatus = (sessionId: string) => {
  return get<ControlStatusResponse>({
    url: `/v0/agents/session/${sessionId}/control_status`,
  });
};