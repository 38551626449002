import ZBreadCrumbs from "@/shared/components/custom/breadcrumbs";
import { Outlet } from "react-router-dom";

const SettingsLayout = () => {
  return (
    <div className="w-full h-full flex flex-col">
      <div className="flex items-center pb-4 justify-between min-h-[44px] max-h-[44px]">
        <div className="flex gap-4 items-center py-1.5">
          <ZBreadCrumbs items={[{ title: "Settings", href: "/settings" }]} />
        </div>
      </div>
      <div className="grow bg-white overflow-hidden rounded-lg">
        <Outlet />
      </div>
    </div>
  );
};

export default SettingsLayout;
