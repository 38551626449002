import { useTeamState } from "@/modules/team/states";

import { useStytch } from "../states/stytch";

export const useLoggedInMember = () => {
    const { team } = useTeamState();
    const stytch = useStytch();
    const sessionInfo = stytch.session.getInfo();
    
  return team.find(member => member.member_id === sessionInfo.session?.member_id);
};
