import { create } from "zustand";

export interface ControlViewer {
  user_id: string;
  control: string;
  session_id: string;
}

interface ControlViewersState {
  viewersMap: Map<string, Map<string, ControlViewer[]>>;
  actions: {
    setInitialViewers: (sessionId: string, viewers: ControlViewer[]) => void;
    addViewer: (viewer: ControlViewer) => void;
    removeViewer: (sessionId: string, control: string, userId: string) => void;
    clearSessionViewers: (sessionId: string) => void;
  };
}

export const useControlViewersStore = create<ControlViewersState>((set) => ({
  viewersMap: new Map(),
  actions: {
    setInitialViewers: (sessionId, viewers) => 
      set((state) => {
        // Group viewers by control
        const viewersByControl = viewers.reduce((acc, viewer) => {
            const controlViewers = acc.get(viewer.control) || [];
            acc.set(viewer.control, [...controlViewers, viewer]);
            return acc;
          }, new Map<string, ControlViewer[]>());

        const newMap = new Map(state.viewersMap);
        newMap.set(sessionId, viewersByControl);
        return { viewersMap: newMap };
      }),

    addViewer: (viewer) =>
      set((state) => {
        try {
          const newMap = new Map(state.viewersMap);
          const sessionViewers = newMap.get(viewer.session_id) || new Map();
          const controlViewers = sessionViewers.get(viewer.control) || [];
          
          // Validate viewer data
          if (!viewer.user_id || !viewer.control || !viewer.session_id) {
            console.warn('Invalid viewer data:', viewer);
            return state;
          }

          if (!controlViewers.some((v: ControlViewer) => v.user_id === viewer.user_id)) {
            sessionViewers.set(viewer.control, [...controlViewers, viewer]);
            newMap.set(viewer.session_id, sessionViewers);
            return { viewersMap: newMap };
          }
          return state;
        } catch (error) {
          console.error('Error in addViewer:', error);
          return state;
        }
      }),

    removeViewer: (sessionId, control, userId) =>
      set((state) => {
        const newMap = new Map(state.viewersMap);
        const sessionViewers = newMap.get(sessionId);
        if (sessionViewers) {
          const controlViewers = sessionViewers.get(control) || [];
          sessionViewers.set(
            control,
            controlViewers.filter((v) => v.user_id !== userId)
          );
        }
        return { viewersMap: newMap };
      }),

    clearSessionViewers: (sessionId) =>
      set((state) => {
        const newMap = new Map(state.viewersMap);
        newMap.delete(sessionId);
        return { viewersMap: newMap };
      }),
  },
}));