import { ReactNode } from "react";

const AuthLayout = ({ children }: { children: ReactNode }) => {
  return (
    <div className="bg-[url('/background.png')] bg-cover bg-center h-screen w-screen overflow-hidden flex items-center justify-center">
      {children}
    </div>
  );
};

export default AuthLayout;
