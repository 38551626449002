import { SVGProps } from "react";

const EyeClosedIcon = (props: SVGProps<SVGSVGElement>) => {
  const { color, ...rest } = props;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M15.75 12C15.75 12.9946 15.3549 13.9484 14.6517 14.6517C13.9484 15.3549 12.9946 15.75 12 15.75M8.25 12C8.25 11.0054 8.64509 10.0516 9.34835 9.34835C10.0516 8.64509 11.0054 8.25 12 8.25M9.75 18.4905C10.4878 18.6616 11.2426 18.7487 12 18.75C17.385 18.75 21.75 14.25 21.75 12C21.75 10.998 20.8838 9.549 19.4468 8.25M14.625 5.6025C13.7692 5.37048 12.8867 5.25197 12 5.25C6.615 5.25 2.25 9.75 2.25 12C2.25 12.99 3.09525 14.415 4.5 15.7012M5.25 18.75L18.75 5.25"
        stroke={color ?? "#71717A"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EyeClosedIcon;
