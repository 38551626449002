import { cn } from "@/shared/utils/classname-merger";

interface ExcelHeaderProps {
  selectedSheet: string;
  sheetNames: string[];
  handleSheetChange: (sheetName: string) => void;
}

const ExcelHeader = ({
  selectedSheet,
  sheetNames,
  handleSheetChange,
}: ExcelHeaderProps) => {
  return (
    <div className="w-full overflow-x-auto flex gap-2  hide-scrollbar p-2">
      {sheetNames.map((sheetName) => {
        const isSelected = selectedSheet === sheetName;
        return (
          <div
            key={sheetName}
            className={cn(
              "flex gap-2 items-center  py-2 px-2 rounded text-nowrap cursor-pointer",
              isSelected && "font-semibold  bg-gray-100  ",
              !isSelected && "hover:bg-gray-100",
            )}
            onClick={() => handleSheetChange(sheetName)}
          >
            {sheetName}
          </div>
        );
      })}
    </div>
  );
};

export default ExcelHeader;
