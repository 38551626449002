import { SVGProps } from "react";

const GapAssessmentIcon = (props: SVGProps<SVGSVGElement>) => {
  const { color, ...rest } = props;
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M15.5 8C16.4283 8 17.3185 7.63125 17.9749 6.97487C18.6313 6.3185 19 5.42826 19 4.5C19 3.57174 18.6313 2.6815 17.9749 2.02513C17.3185 1.36875 16.4283 1 15.5 1C14.5717 1 13.6815 1.36875 13.0251 2.02513C12.3687 2.6815 12 3.57174 12 4.5M15.5 8C14.5717 8 13.6815 7.63125 13.0251 6.97487C12.3687 6.3185 12 5.42826 12 4.5M15.5 8V12M12 4.5H8M8 4.5C8 2.85 8 2.025 7.487 1.513C6.975 1 6.15 1 4.5 1C2.85 1 2.025 1 1.513 1.513C1 2.025 1 2.85 1 4.5C1 6.15 1 6.975 1.513 7.487C2.025 8 2.85 8 4.5 8C6.15 8 6.975 8 7.487 7.487C8 6.975 8 6.15 8 4.5ZM8 15.5C8 13.85 8 13.025 7.487 12.513C6.975 12 6.15 12 4.5 12C2.85 12 2.025 12 1.513 12.513C1 13.025 1 13.85 1 15.5C1 17.15 1 17.975 1.513 18.487C2.025 19 2.85 19 4.5 19C6.15 19 6.975 19 7.487 18.487C8 17.975 8 17.15 8 15.5ZM8 15.5H12M12 15.5C12 13.85 12 13.025 12.513 12.513C13.025 12 13.85 12 15.5 12M12 15.5C12 17.15 12 17.975 12.513 18.487C13.025 19 13.85 19 15.5 19C17.15 19 17.975 19 18.487 18.487C19 17.975 19 17.15 19 15.5C19 13.85 19 13.025 18.487 12.513C17.975 12 17.15 12 15.5 12"
        stroke={color ?? "#71717A"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default GapAssessmentIcon;
