import { SVGProps } from "react";

const ZaniaExpandedIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 79 24"
      fill="none"
      {...props}
    >
      <g filter="url(#filter0_d_107_55349)">
        <path
          d="M6.69816 1.73335C6.87323 1.47308 7.20354 1.40231 7.46633 1.55683C8.36807 2.09075 10.446 3.21361 12.3185 3.45084C14.3067 3.70342 18.0422 3.10235 19.4347 2.85556C19.637 2.81942 19.7824 3.06635 19.6682 3.25019L15.9294 9.24586C15.7958 9.46053 15.6044 9.62337 15.3756 9.70029C14.4685 10.0023 11.9028 10.7305 9.06147 10.4129C6.42234 10.1185 4.05285 8.94119 3.01153 8.3583C2.70835 8.18796 2.62589 7.76975 2.82558 7.47415L6.69816 1.73335ZM17.2233 20.2696C17.0554 20.5189 16.7436 20.5955 16.4868 20.458C15.5167 19.9415 13.1473 18.7653 11.2283 18.5222C9.26488 18.273 5.84449 18.8599 4.5136 19.1131C4.31032 19.1514 4.16162 18.9025 4.27685 18.7175L7.99518 12.7538C8.138 12.5259 8.34423 12.3561 8.58937 12.2829C9.58482 11.9843 12.4142 11.2511 15.2686 11.5704C17.8926 11.8632 20.0376 13.0268 20.9756 13.6122C21.2596 13.7886 21.3263 14.1869 21.1348 14.4715L17.2233 20.2696Z"
          fill="black"
        />
      </g>
      <path
        d="M6.69816 1.73335C6.87323 1.47308 7.20354 1.40231 7.46633 1.55683C8.36807 2.09075 10.446 3.21361 12.3185 3.45084C14.3067 3.70342 18.0422 3.10235 19.4347 2.85556C19.637 2.81942 19.7824 3.06635 19.6682 3.25019L15.9294 9.24586C15.7958 9.46053 15.6044 9.62337 15.3756 9.70029C14.4685 10.0023 11.9028 10.7305 9.06147 10.4129C6.42234 10.1185 4.05285 8.94119 3.01153 8.3583C2.70835 8.18796 2.62589 7.76975 2.82558 7.47415L6.69816 1.73335ZM17.2233 20.2696C17.0554 20.5189 16.7436 20.5955 16.4868 20.458C15.5167 19.9415 13.1473 18.7653 11.2283 18.5222C9.26488 18.273 5.84449 18.8599 4.5136 19.1131C4.31032 19.1514 4.16162 18.9025 4.27685 18.7175L7.99518 12.7538C8.138 12.5259 8.34423 12.3561 8.58937 12.2829C9.58482 11.9843 12.4142 11.2511 15.2686 11.5704C17.8926 11.8632 20.0376 13.0268 20.9756 13.6122C21.2596 13.7886 21.3263 14.1869 21.1348 14.4715L17.2233 20.2696Z"
        fill="black"
      />
      <path
        d="M30 16.2714L34.3976 10.7903L35.5367 9.79527H30V7.25146H38.7508V9.79527L34.3976 15.3692L33.2817 16.2714H38.7508V18.8153H30V16.2714Z"
        fill="black"
      />
      <path
        d="M40.7381 7.92184C41.3333 7.64497 42.0402 7.42854 42.8588 7.27464C43.6775 7.10529 44.5334 7.02026 45.4258 7.02026C46.2002 7.02026 46.8474 7.12075 47.3681 7.32102C47.8895 7.50584 48.2985 7.77568 48.5965 8.13055C48.9085 8.48471 49.1249 8.90915 49.2437 9.40245C49.3779 9.89575 49.4446 10.4509 49.4446 11.0672C49.4446 11.746 49.4222 12.4318 49.3779 13.1261C49.3329 13.8042 49.3034 14.4753 49.2879 15.138C49.2879 15.8006 49.3104 16.4485 49.3554 17.081C49.3997 17.6972 49.5114 18.2833 49.6899 18.8384H47.3239L46.8552 17.2426H46.7434C46.4455 17.7204 46.0288 18.1371 45.4933 18.4913C44.9726 18.8307 44.2952 19.0001 43.4618 19.0001C42.9411 19.0001 42.4717 18.9235 42.0549 18.7689C41.6382 18.5995 41.2813 18.3683 40.9833 18.0753C40.6861 17.7668 40.4556 17.4119 40.2918 17.0114C40.1281 16.5947 40.0459 16.1323 40.0459 15.6235C40.0459 14.9145 40.1949 14.3207 40.4928 13.8429C40.8048 13.3496 41.244 12.9561 41.8097 12.663C42.3901 12.3552 43.0746 12.1465 43.8637 12.039C44.6669 11.9153 45.5601 11.8767 46.5425 11.9231C46.6465 11.0594 46.5867 10.4432 46.364 10.0728C46.1405 9.68775 45.6423 9.4945 44.8679 9.4945C44.2875 9.4945 43.6698 9.55634 43.0148 9.68002C42.3754 9.80299 41.8469 9.96532 41.4302 10.1656L40.7381 7.92184ZM44.4217 16.5258C45.0021 16.5258 45.4631 16.3944 45.806 16.1323C46.1482 15.8547 46.4012 15.5617 46.5649 15.2539V13.7501C46.1033 13.7037 45.657 13.696 45.2256 13.7269C44.8089 13.7578 44.4364 13.8274 44.109 13.9356C43.7815 14.0431 43.5215 14.1977 43.3276 14.398C43.1343 14.5983 43.0373 14.8527 43.0373 15.1612C43.0373 15.5926 43.1568 15.932 43.395 16.1787C43.648 16.4099 43.9902 16.5258 44.4217 16.5258Z"
        fill="black"
      />
      <path
        d="M58.7109 18.815V12.2468C58.7109 11.3065 58.5767 10.6277 58.309 10.2117C58.056 9.795 57.6168 9.587 56.9921 9.587C56.4412 9.587 55.9725 9.75636 55.5853 10.0958C55.2135 10.4197 54.9458 10.828 54.7821 11.322V18.815H51.8799V7.2512H54.1792L54.5136 8.77748H54.6029C54.9458 8.28418 55.3998 7.85271 55.9647 7.48239C56.5304 7.11276 57.2598 6.92725 58.153 6.92725C58.7032 6.92725 59.1944 7.00454 59.6259 7.15844C60.058 7.31303 60.4227 7.56741 60.72 7.92158C61.0179 8.27645 61.2407 8.76202 61.3897 9.379C61.5386 9.98052 61.6131 10.7282 61.6131 11.6221V18.815H58.7109Z"
        fill="black"
      />
      <path
        d="M64.3135 7.25146H67.2157V18.8153H64.3135V7.25146Z"
        fill="black"
      />
      <path
        d="M69.9727 7.92184C70.5686 7.64497 71.2755 7.42854 72.0935 7.27464C72.9129 7.10529 73.7681 7.02026 74.6612 7.02026C75.4349 7.02026 76.0828 7.12075 76.6035 7.32102C77.1242 7.50584 77.5339 7.77568 77.8311 8.13055C78.1438 8.48471 78.3596 8.90915 78.4783 9.40245C78.6125 9.89575 78.6793 10.4509 78.6793 11.0672C78.6793 11.746 78.6568 12.4318 78.6125 13.1261C78.5676 13.8042 78.538 14.4753 78.5233 15.138C78.5233 15.8006 78.5458 16.4485 78.59 17.081C78.635 17.6972 78.7468 18.2833 78.9252 18.8384H76.5585L76.0898 17.2426H75.9781C75.6808 17.7204 75.2641 18.1371 74.728 18.4913C74.2072 18.8307 73.5298 19.0001 72.6964 19.0001C72.1757 19.0001 71.707 18.9235 71.2903 18.7689C70.8736 18.5995 70.5166 18.3683 70.2187 18.0753C69.9214 17.7668 69.6902 17.4119 69.5265 17.0114C69.3628 16.5947 69.2812 16.1323 69.2812 15.6235C69.2812 14.9145 69.4302 14.3207 69.7275 13.8429C70.0402 13.3496 70.4794 12.9561 71.0443 12.663C71.6248 12.3552 72.3099 12.1465 73.0984 12.039C73.9023 11.9153 74.7947 11.8767 75.7771 11.9231C75.8818 11.0594 75.8221 10.4432 75.5986 10.0728C75.3751 9.68775 74.8769 9.4945 74.1032 9.4945C73.5228 9.4945 72.9051 9.55634 72.2502 9.68002C71.61 9.80299 71.0823 9.96532 70.6649 10.1656L69.9727 7.92184ZM73.6563 16.5258C74.2368 16.5258 74.6984 16.3944 75.0407 16.1323C75.3829 15.8547 75.6359 15.5617 75.7996 15.2539V13.7501C75.3386 13.7037 74.8917 13.696 74.4602 13.7269C74.0435 13.7578 73.6718 13.8274 73.3443 13.9356C73.0169 14.0431 72.7561 14.1977 72.5629 14.398C72.369 14.5983 72.2727 14.8527 72.2727 15.1612C72.2727 15.5926 72.3914 15.932 72.6297 16.1787C72.8826 16.4099 73.2249 16.5258 73.6563 16.5258Z"
        fill="black"
      />
      <path
        d="M64.2598 5.46581L67.3791 4.78559V3.76525V3L64.2598 3.76525V4.78559V5.46581Z"
        fill="black"
      />
      <defs>
        <filter
          id="filter0_d_107_55349"
          x="0.566797"
          y="0.40583"
          width="22.8264"
          height="23.3498"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.08" />
          <feGaussianBlur stdDeviation="1.075" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_107_55349"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_107_55349"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default ZaniaExpandedIcon;
