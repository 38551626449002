import type { ZTableColumnProps } from '@/shared/components/table';
import { Chip } from '@nextui-org/react';
import dayjs from 'dayjs';
import { agentSessionStatusLabelMap, frameworkTypeLabels } from '../constants';
import type { AgentSessions } from '../types';
import { AgentSessionStatus, AgentSessionStepType, AgentSessionType } from '../types';

const baseColumns = (memberId: string | undefined): ZTableColumnProps<AgentSessions>[] => [
  {
    type: "custom",
    fieldName: "name",
    headerName: "Session Title",
    sortable: true,
    getSortValue: ({ session: { name } }) => name ?? "",
    renderCell: ({ session: { name, permissions } }) => (
      <div className="text-[#333] flex gap-2 break-all items-center">
        {name ?? ""}
        {permissions.owner !== memberId && (
          <span className="h-3 flex items-center justify-center text-ellipsis overflow-hidden rounded border-1 border-[#006FEE] bg-[#006FEE33] p-1 text-[8px] leading-3 text-[#006FEE] shrink-0">
            SHARED
          </span>
        )}
      </div>
    )
  },
  {
    type: "custom",
    fieldName: "status",
    headerName: "Status",
    sortable: true,
    getSortValue: ({ session: { status } }) => status,
    renderCell: ({ session: { status }, steps = [] }) => {
      const completedSteps = steps.filter(
        ({ status, type }) =>
          status === AgentSessionStatus.COMPLETE ||
          (type === AgentSessionStepType.MULTI_VENDOR_PROFILE &&
            status === AgentSessionStatus.INPUT_NEEDED) ||
          status === AgentSessionStatus.IN_PROGRESS || status === AgentSessionStatus.UNDER_REASSESSMENT,
      ).length;

      return (
        <div className="flex items-center gap-3">
          <Chip
            variant="bordered"
            radius="full"
            className="h-6 border-1.5"
            classNames={{
              content: "px-1 py-2 flex items-center gap-1",
            }}
            style={{
              color: agentSessionStatusLabelMap[status]?.borderColor ?? "black",
              borderColor: agentSessionStatusLabelMap[status]?.borderColor ?? "black",
              backgroundColor: agentSessionStatusLabelMap[status]?.backgroundColor ?? "transparent",
            }}
          >
            {agentSessionStatusLabelMap[status]?.renderIcon?.()}
          </Chip>
          <div className="text-[#333]">
            {!completedSteps
              ? "Not Started"
              : (agentSessionStatusLabelMap[status]?.label ?? "N/A")}
          </div>
        </div>
      );
    },
  },
  {
    type: "custom",
    fieldName: "lastUpdated",
    headerName: "Last Updated",
    sortable: true,
    getSortValue: ({ updated_at }) => dayjs(updated_at).isValid() ? dayjs(updated_at).toDate().getTime() : 0,
    renderCell: ({ updated_at }) => {
      if (dayjs(updated_at).isValid()) {
        const parsedDate = dayjs.utc(updated_at.replace("T", " ").split(".")[0]);
        const localDate = parsedDate.local();
        return <div className="text-[#333]">{localDate.fromNow()}</div>;
      }
      return "";
    }
  },

];

const getSessionTypeSpecificColumn = (type: AgentSessionType): ZTableColumnProps<AgentSessions> => {
  switch (type) {
    case AgentSessionType.RISK_ASSESSMENT:
    case AgentSessionType.SOC2_GAP:
    case AgentSessionType.DORA_GAP:
      return {
        type: "custom",
        fieldName: "framework",
        headerName: "Framework",
        sortable: true,
        renderCell: ({ session }) => (
          <div className="text-[#333]">{frameworkTypeLabels[session.type] || 'N/A'}</div>
        )
      };
    case AgentSessionType.MULTI_FILE_VENDOR_ASSESSMENT:
      return {
        type: "custom",
        fieldName: "vendorProduct",
        headerName: "Vendor Product",
        sortable: true,
        renderCell: ({ session }) => (
          <div className="text-[#333]">
            {(session.type === AgentSessionType.MULTI_FILE_VENDOR_ASSESSMENT
              ? session.multi_file_vendor_assessment?.vendor_name
              : 'N/A') || 'N/A'}
          </div>
        )
      };
    case AgentSessionType.QA_FILLER:
      return {
        type: "custom",
        fieldName: "customerName",
        headerName: "Customer Name",
        sortable: true,
        renderCell: ({ session }) => (
          <div className="text-[#333]">{session.name || 'N/A'}</div>
        )
      };
    default:
      return {
        type: "custom",
        fieldName: "framework",
        headerName: "Framework",
        sortable: true,
        renderCell: () => <div className="text-[#333]">N/A</div>
      };
  }
};

export const getSessionColumns = (sessionType: AgentSessionType, memberId: string | undefined): ZTableColumnProps<AgentSessions>[] => {
  const specificColumn = getSessionTypeSpecificColumn(sessionType);
  const columns = [...baseColumns(memberId)];
  columns.splice(1, 0, specificColumn);
  return columns;
};