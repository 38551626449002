const KeyIcon = () => {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.086 16.4142C1.7109 16.7891 1.50011 17.2978 1.5 17.8282V20.0002C1.5 20.2654 1.60536 20.5197 1.79289 20.7073C1.98043 20.8948 2.23478 21.0002 2.5 21.0002H5.5C5.76522 21.0002 6.01957 20.8948 6.20711 20.7073C6.39464 20.5197 6.5 20.2654 6.5 20.0002V19.0002C6.5 18.7349 6.60536 18.4806 6.79289 18.2931C6.98043 18.1055 7.23478 18.0002 7.5 18.0002H8.5C8.76522 18.0002 9.01957 17.8948 9.20711 17.7073C9.39464 17.5197 9.5 17.2654 9.5 17.0002V16.0002C9.5 15.7349 9.60536 15.4806 9.79289 15.2931C9.98043 15.1055 10.2348 15.0002 10.5 15.0002H10.672C11.2024 15 11.711 14.7893 12.086 14.4142L12.9 13.6002C14.2898 14.0843 15.8028 14.0825 17.1915 13.5949C18.5801 13.1074 19.7622 12.163 20.5444 10.9163C21.3265 9.66957 21.6624 8.19433 21.4971 6.7319C21.3318 5.26946 20.6751 3.90641 19.6344 2.86573C18.5937 1.82505 17.2307 1.16834 15.7683 1.00305C14.3058 0.837755 12.8306 1.17366 11.5839 1.9558C10.3372 2.73795 9.39279 3.92003 8.90525 5.30868C8.41771 6.69733 8.41585 8.21033 8.9 9.60016L2.086 16.4142Z"
        stroke="#006FEE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default KeyIcon;
