import React, { useState, useEffect } from "react";

const useMemoAsync = <T>(
  asyncFn: () => Promise<T | null>,
  deps: React.DependencyList,
) => {
  const [value, setValue] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <asyncFn is not required in deps>
  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);
        const result = await asyncFn();
        if (isMounted) {
          setValue(result);
        }
      } catch (err) {
        if (isMounted) {
          setValue(null);
          setError(err instanceof Error ? err : new Error(String(err)));
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, deps);

  return { value, loading, error };
};

export default useMemoAsync;
