import { getAgentStateActions } from "@/modules/agent/states";
import {
  AGENT_TYPES,
  AgentData,
  AgentSourceFile,
  GapAssessmentTypes,
  MultiVendorAssessmentTypes,
  QuestionnaireTypes,
  RiskAssessmentTypes,
} from "@/modules/agent/types";
import { QuestionnaireFileData } from "@/modules/agent/types/questionnaire";
import {
  generateResponseData,
  processFileForReview,
  processManualMapping,
} from "@/modules/agent/use-cases/quesitionnaire.use-case";
import { processFileForRiskReview } from "@/modules/agent/use-cases/risk.use-case";
import { processFileForSOC2Type2Audit } from "@/modules/agent/use-cases/soc2.use-case";
import { getSheetDataFromUrl } from "@/modules/agent/utils/excel-parser";
import { getFileNameFromUrl } from "@/modules/agent/utils/get-filename-from-url";
import { addNotification } from "@/shared/states/notification";
import { userStateSelector } from "@/shared/states/user";
import {
  AgentSessionStatus,
  AgentSessionStep,
  AgentSessionStepType,
  AgentSessionType,
  DoraAgentSession,
  ManualQAAgentStep,
  MultiVendorAgentSession,
  QaAgentSession,
  RiskAgentSession,
  SOC2GapAgentSession,
  SOC2Type1GapAgentSession,
  SOC2Type2NewGapAgentSession,
} from "../types";

import { processFileForVendorAssessmentResponse } from "@/modules/agent/use-cases/vendor.use-case";

import { handleContinueCustomizeControls } from "@/modules/agent/use-cases/customize-control.use-case";
import { processFileForDoraAudit } from "@/modules/agent/use-cases/dora.use-case";
import { AiResponseType } from "@/shared/types/user";

export const handleContinueRiskAssessment = async ({
  session,
  steps,
}: {
  session: RiskAgentSession;
  steps: AgentSessionStep[];
}) => {
  try {
    const { setAgentData } = getAgentStateActions();
    //FIXME
    const selectedResponse =
      userStateSelector.getState().aiResponseType === AiResponseType.NONE
        ? AiResponseType.LITE
        : userStateSelector.getState().aiResponseType;
    const agentData: AgentData<AGENT_TYPES.RISK_ASSESSMENT, RiskAssessmentTypes.NIST_CSF_2> = {
      agentType: AGENT_TYPES.RISK_ASSESSMENT,
      sessionData: session,
      subType: RiskAssessmentTypes.NIST_CSF_2,
      stepData: steps,
      responseQuality: selectedResponse,
      mainData: {
        sourceFilesUrls: new Map(
          session.risk_assessment.source_urls.map((file) => [
            getFileNameFromUrl(file),
            {
              url: file,
              fileName: getFileNameFromUrl(file),
            },
          ]),
        ),
        approvedIds: [],
        editedIds: [],
      },
    };
    setAgentData(session.id, agentData);

    const currentStep = steps.find(
      ({ status }) => status === AgentSessionStatus.IN_PROGRESS || status === AgentSessionStatus.INPUT_NEEDED,
    );

    if (currentStep && currentStep.type === AgentSessionStepType.GENERATE_RISK_ASSESSMENT_RESPONSE) {
      return;
    }

    if (
      currentStep &&
      currentStep.type === AgentSessionStepType.EDIT_RESPONSE &&
      (currentStep.status === AgentSessionStatus.IN_PROGRESS || currentStep.status === AgentSessionStatus.INPUT_NEEDED)
    ) {
      if (currentStep.data && "url" in currentStep.data) {
        await processFileForRiskReview(currentStep.data.url, session.id);
      }
    }
    const prepareReportStep = steps.find((step) => step.type === AgentSessionStepType.PREPARE_REPORT);
    const editResponseStep = steps.find((step) => step.type === AgentSessionStepType.EDIT_RESPONSE);
    if (
      prepareReportStep &&
      prepareReportStep.status === AgentSessionStatus.COMPLETE &&
      editResponseStep &&
      editResponseStep.status === AgentSessionStatus.COMPLETE
    ) {
      await processFileForRiskReview(editResponseStep.data.url, session.id);
    }

    if (!currentStep && prepareReportStep && prepareReportStep.status === AgentSessionStatus.PENDING) {
      const editResponseStep = steps.find((step) => step.type === AgentSessionStepType.EDIT_RESPONSE);
      if (editResponseStep && editResponseStep.data && "url" in editResponseStep.data) {
        await processFileForRiskReview(editResponseStep.data.url, session.id);
      }
    }
  } catch (error) {
    console.error(error);
    addNotification({
      title: "Error",
      message: "An error occurred while continuing the risk assessment.",
      type: "error",
    });
  }
};

export const handleContinueSoc2GapAssessment = async ({
  session,
  steps,
}: {
  session: SOC2GapAgentSession | SOC2Type2NewGapAgentSession | SOC2Type1GapAgentSession;
  steps: AgentSessionStep[];
}) => {
  try {
    const { setAgentData } = getAgentStateActions();
    const responseQuality =
      userStateSelector.getState().aiResponseType === AiResponseType.NONE
        ? AiResponseType.LITE
        : userStateSelector.getState().aiResponseType;

    const sourceUrls =
      session.type === AgentSessionType.SOC2_GAP
        ? session.soc2_type2_audit.source_urls
        : session.type === AgentSessionType.SOC2_GAP_TYPE1
          ? session.soc2_type1.source_urls
          : session.soc2_type2.source_urls;

    const agentType: AGENT_TYPES = AGENT_TYPES.GAP_ASSESSMENT;
    const agentSubType: GapAssessmentTypes =
      session.type === AgentSessionType.SOC2_GAP_TYPE1
        ? GapAssessmentTypes.SOC2_TYPE1
        : session.type === AgentSessionType.SOC2_GAP_TYPE2
          ? GapAssessmentTypes.SOC2_TYPE2
          : GapAssessmentTypes.SOC2;
    const sourceFilesUrls: Map<string, AgentSourceFile> = new Map(
      sourceUrls.map((file) => [
        getFileNameFromUrl(file),
        {
          url: file,
          fileName: getFileNameFromUrl(file),
        },
      ]),
    );

    const agentData: AgentData<typeof agentType, typeof agentSubType> = {
      agentType: agentType,
      sessionData: session,
      subType: agentSubType,
      stepData: steps,
      responseQuality: responseQuality,
      mainData: {
        sourceFilesUrls: sourceFilesUrls,
        approvedIds: [],
        editedIds: [],
      },
    };
    setAgentData(session.id, agentData);

    const currentStep = steps.find(
      ({ status }) => status === AgentSessionStatus.IN_PROGRESS || status === AgentSessionStatus.INPUT_NEEDED,
    );

    if (currentStep && currentStep.type === AgentSessionStepType.SOC2_TYPE2_AUDIT) {
      return;
    }

    if (currentStep && currentStep.type === AgentSessionStepType.CONTROL_SELECTION) {
      if (currentStep.data && "url" in currentStep.data) {
        handleContinueCustomizeControls(
          steps,
          session.type as AgentSessionType.SOC2_GAP_TYPE2 | AgentSessionType.SOC2_GAP_TYPE1,
        );
      }
    }

    if (
      currentStep &&
      currentStep.type === AgentSessionStepType.EDIT_RESPONSE &&
      (currentStep.status === AgentSessionStatus.IN_PROGRESS || currentStep.status === AgentSessionStatus.INPUT_NEEDED)
    ) {
      if (currentStep.data && "url" in currentStep.data) {
        await processFileForSOC2Type2Audit(currentStep.data.url, session.id);
      }
    }

    const prepareReportStep = steps.find((step) => step.type === AgentSessionStepType.PREPARE_REPORT);
    const editResponseStep = steps.find((step) => step.type === AgentSessionStepType.EDIT_RESPONSE);

    if (
      prepareReportStep &&
      prepareReportStep.status === AgentSessionStatus.COMPLETE &&
      editResponseStep &&
      editResponseStep.status === AgentSessionStatus.COMPLETE
    ) {
      await processFileForSOC2Type2Audit(editResponseStep.data.url, session.id);
    }

    if (!currentStep && prepareReportStep && prepareReportStep.status === AgentSessionStatus.PENDING) {
      const editResponseStep = steps.find((step) => step.type === AgentSessionStepType.EDIT_RESPONSE);
      if (editResponseStep && editResponseStep.data && "url" in editResponseStep.data) {
        await processFileForSOC2Type2Audit(editResponseStep.data.url, session.id);
      }
    }
  } catch (error) {
    console.error(error);

    addNotification({
      title: "Error",
      message: "An error occurred while continuing the gap assessment.",
      type: "error",
    });
  }
};

export const handleContinueDoraGapAssessment = async ({
  session,
  steps,
}: {
  session: DoraAgentSession;
  steps: AgentSessionStep[];
}) => {
  try {
    const { setAgentData } = getAgentStateActions();
    const responseQuality =
      userStateSelector.getState().aiResponseType === AiResponseType.NONE
        ? AiResponseType.LITE
        : userStateSelector.getState().aiResponseType;
    const agentType: AGENT_TYPES = AGENT_TYPES.GAP_ASSESSMENT;
    const agentSubType: GapAssessmentTypes = GapAssessmentTypes.DORA;
    const sourceFilesUrls: Map<string, AgentSourceFile> = new Map(
      session.dora_assessment.source_urls.map((file) => [
        getFileNameFromUrl(file),
        {
          url: file,
          fileName: getFileNameFromUrl(file),
        },
      ]),
    );

    const agentData: AgentData<typeof agentType, typeof agentSubType> = {
      agentType: agentType,
      sessionData: session,
      subType: agentSubType,
      stepData: steps,
      responseQuality: responseQuality,
      mainData: {
        sourceFilesUrls: sourceFilesUrls,
        approvedIds: [],
        editedIds: [],
      },
    };
    setAgentData(session.id, agentData);

    const currentStep = steps.find(
      ({ status }) => status === AgentSessionStatus.IN_PROGRESS || status === AgentSessionStatus.INPUT_NEEDED,
    );

    if (currentStep && currentStep.type === AgentSessionStepType.DORA_ASSESSMENT) {
      return;
    }

    if (
      currentStep &&
      currentStep.type === AgentSessionStepType.EDIT_RESPONSE &&
      (currentStep.status === AgentSessionStatus.IN_PROGRESS || currentStep.status === AgentSessionStatus.INPUT_NEEDED)
    ) {
      if (currentStep.data && "url" in currentStep.data) {
        await processFileForDoraAudit(currentStep.data.url, session.id);
      }
    }

    const prepareReportStep = steps.find((step) => step.type === AgentSessionStepType.PREPARE_REPORT);

    const editResponseStep = steps.find((step) => step.type === AgentSessionStepType.EDIT_RESPONSE);
    if (
      prepareReportStep &&
      prepareReportStep.status === AgentSessionStatus.COMPLETE &&
      editResponseStep &&
      editResponseStep.status === AgentSessionStatus.COMPLETE
    ) {
      await processFileForDoraAudit(editResponseStep.data.url, session.id);
    }

    if (!currentStep && prepareReportStep && prepareReportStep.status === AgentSessionStatus.PENDING) {
      const editResponseStep = steps.find((step) => step.type === AgentSessionStepType.EDIT_RESPONSE);
      if (editResponseStep?.data && "url" in editResponseStep.data) {
        await processFileForDoraAudit(editResponseStep.data.url, session.id);
      }
    }
  } catch (error) {
    console.error(error);
    addNotification({
      title: "Error",
      message: "An error occurred while continuing DORA Assessment.",
      type: "error",
    });
  }
};

type handleContinueMultiVendorAssessmentProps = {
  session: MultiVendorAgentSession;
  steps: AgentSessionStep[];
};
export const handleContinueMultiVendorAssessment = async ({
  session,
  steps,
}: handleContinueMultiVendorAssessmentProps) => {
  try {
    const { setAgentData } = getAgentStateActions();
    const selectedResponse =
      userStateSelector.getState().aiResponseType === AiResponseType.NONE
        ? AiResponseType.LITE
        : userStateSelector.getState().aiResponseType;
    const agentData: AgentData<AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT, MultiVendorAssessmentTypes.VENDOR_ASSESSMENT> =
      {
        agentType: AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT,
        sessionData: session,
        subType: MultiVendorAssessmentTypes.VENDOR_ASSESSMENT,
        stepData: steps,
        responseQuality: selectedResponse,
        mainData: {
          sourceFilesUrls: new Map(),
          approvedIds: [],
          editedIds: [],
          vendorName: session.multi_file_vendor_assessment.vendor_name,
          scopeOfIntegration: session.multi_file_vendor_assessment.scope_of_integration,
        },
      };
    setAgentData(session.id, agentData);
    const currentStep = steps.find((step) => {
      return step.status === AgentSessionStatus.IN_PROGRESS || step.status === AgentSessionStatus.INPUT_NEEDED;
    });
    if (currentStep && currentStep.type === AgentSessionStepType.MULTI_VENDOR_PROFILE) {
      return;
    }

    if (
      currentStep &&
      currentStep.type === AgentSessionStepType.EDIT_RESPONSE &&
      (currentStep.status === AgentSessionStatus.IN_PROGRESS || currentStep.status === AgentSessionStatus.INPUT_NEEDED)
    ) {
      if (currentStep.data && "url" in currentStep.data) {
        await processFileForVendorAssessmentResponse(currentStep.data.url, session.id);
      }
    }
    const prepareReportStep = steps.find((step) => step.type === AgentSessionStepType.PREPARE_REPORT);
    const editResponseStep = steps.find((step) => step.type === AgentSessionStepType.EDIT_RESPONSE);

    if (
      prepareReportStep &&
      prepareReportStep.status === AgentSessionStatus.COMPLETE &&
      editResponseStep &&
      editResponseStep.status === AgentSessionStatus.COMPLETE
    ) {
      await processFileForVendorAssessmentResponse(editResponseStep.data.url, session.id);
    }

    if (!currentStep && prepareReportStep && prepareReportStep.status === AgentSessionStatus.PENDING) {
      const editResponseStep = steps.find((step) => step.type === AgentSessionStepType.EDIT_RESPONSE);
      if (editResponseStep?.data && "url" in editResponseStep.data) {
        await processFileForVendorAssessmentResponse(editResponseStep.data.url, session.id);
      }
    }
  } catch (error) {
    console.error(error);
    addNotification({
      title: "Error",
      message: "An error occurred while continuing the third-party assessment.",
      type: "error",
    });
  }
};

export const handleContinueQuestionnaire = async ({
  session,
  steps,
}: {
  session: QaAgentSession;
  steps: AgentSessionStep[];
}) => {
  try {
    let selectedSheet = "";
    const { setAgentData, setSheetMappingData, setSelectedSheet } = getAgentStateActions();
    const sourceFilesUrls = session.qa_filler.kb_url
      ? [session.qa_filler.kb_url]
      : [...(session.qa_filler.multiple_source_urls ?? [])];
    const selectedResponse =
      userStateSelector.getState().aiResponseType === AiResponseType.NONE
        ? AiResponseType.LITE
        : userStateSelector.getState().aiResponseType;

    const { sheetNames, workbook } = await getSheetDataFromUrl(session.qa_filler.excel_url);

    const fileDetails: QuestionnaireFileData = {
      url: session.qa_filler.excel_url,
      fileName: getFileNameFromUrl(session.qa_filler.excel_url),
      workbook,
      sheetNames,
      sheetData: {},
    };

    for (let i = 0; i < sheetNames.length; i++) {
      if (!sheetNames[i].endsWith("::hidden")) {
        selectedSheet = sheetNames[i];
        break;
      }
    }

    const agentData: AgentData<AGENT_TYPES.QUESTIONNAIRE, QuestionnaireTypes.QA> = {
      agentType: AGENT_TYPES.QUESTIONNAIRE,
      sessionData: session,
      subType: QuestionnaireTypes.QA,
      stepData: steps,
      responseQuality: selectedResponse,
      mainData: {
        questionnaireFileData: fileDetails,
        sourceFilesUrls: new Map(
          sourceFilesUrls.map((file) => [
            getFileNameFromUrl(file),
            {
              url: file,
              fileName: getFileNameFromUrl(file),
            },
          ]),
        ),
        selectedSheet,
        approvedIds: [],
        editedIds: [],
        questionnaireMapping: {},
        questionnaireFiller: [],
      },
    };
    setAgentData(session.id, agentData);

    const currentStep = steps.find(
      ({ status }) => status === AgentSessionStatus.IN_PROGRESS || status === AgentSessionStatus.INPUT_NEEDED,
    );

    await setSelectedSheet(session.id, selectedSheet);

    const prepareReportStep = steps.find((step) => step.type === AgentSessionStepType.PREPARE_REPORT);
    const editResponseStep = steps.find((step) => step.type === AgentSessionStepType.EDIT_RESPONSE);

    if (
      prepareReportStep &&
      prepareReportStep.status === AgentSessionStatus.COMPLETE &&
      editResponseStep &&
      editResponseStep.status === AgentSessionStatus.COMPLETE
    ) {
      await processFileForReview(editResponseStep.data.url, session.id);
      return;
    }

    if (!currentStep) {
      const firstPending = steps.find(({ status }) => status === AgentSessionStatus.PENDING);
      if (firstPending && firstPending.type === AgentSessionStepType.EXTRACT_QUESTIONS) {
        await processManualMapping({
          agent_session_id: session.id,
        });
        await generateResponseData({ agentId: session.id });
      } else
        addNotification({
          title: "Could not resume session",
          message: "No in-progress step found",
          type: "error",
        });
      return;
    }
    if (currentStep.type === AgentSessionStepType.MANUAL_EXCEL_STRUCTURE_MAPPING) {
      if (!currentStep.data || !currentStep.data.input) {
        await processManualMapping({
          agent_session_id: session.id,
          shouldNavigate: true,
        });
        return;
      }
      const inputData = (currentStep as ManualQAAgentStep).data.input;
      if (!inputData) {
        addNotification({
          title: "Could not resume session",
          message: "No input data found",
          type: "error",
        });
        return;
      }
      Object.entries(inputData).forEach(([sheetName, sheetData]) => {
        setSheetMappingData(session.id, {
          sheetName,
          mappingData: sheetData,
        });
      });
    } else if (currentStep.type === AgentSessionStepType.EDIT_RESPONSE) {
      const url = currentStep.data?.url;
      if (!url) {
        addNotification({
          title: "Could not resume session",
          message: "No file url found",
          type: "error",
        });
        return;
      }
      await processFileForReview(url, session.id);
    }
  } catch (error) {
    console.error(error);
    addNotification({
      title: "Could not resume session",
      message: "An error occurred while processing the file",
      type: "error",
    });
  }
};
