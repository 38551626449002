import capitalizeFirstLetter from "@/shared/utils/capitalize-first-letter";
import { cn } from "@/shared/utils/classname-merger";
import { useEffect, useState } from "react";

type Props = {
  name: string;
  textSize?: number;
  size?: number | string; // single property for size
  shadow?: string;
  backgroundColor?: string;
  textColor?: string;
};

const ZAvatarGradient = (props: Props) => {
  const {
    name,
    textSize = 12,
    size = 28,
    backgroundColor,
    shadow = `shadow-md`,
    textColor = "text-white",
  } = props;

  const getInitialsFromName = (fullName: string) => {
    const names = fullName.split(" ").filter((string) => string.trim().length);

    if (!names.length) {
      return '';
    }

    if (names.length === 1) {
      return capitalizeFirstLetter(names[0].substring(0, 2));
    }

    return `${Array.from(names[0])[0]}${Array.from(names[names.length - 1])[0]}`.toUpperCase();
  };

  return (
    <div
      className={cn(`flex justify-center items-center cursor-default`, shadow)}
      style={{
        backgroundImage: backgroundColor
          ? undefined
          : `linear-gradient(to bottom, rgb(221, 154, 176), rgb(169, 130, 207))`,
        backgroundColor: backgroundColor,
        width: size,
        minWidth: size,
        maxWidth: size,
        height: size,
        minHeight: size,
        maxHeight: size,
        borderRadius: "50%",
      }}
    >
      <p
        className={cn(`font-semibold`, textColor)}
        style={{ fontSize: textSize }}
      >
        {getInitialsFromName(name)}
      </p>
    </div>
  );
};

export default ZAvatarGradient;
