import { useEffect, useCallback } from "react";
import { useControlViewersStore } from "../states/control-viewers";
import {
	getSessionControlStatus,
	type SessionControlsMap,
} from "../requests/control-status";
import { addNotification } from "@/shared/states/notification";
import { getZaniaSocket } from "@/infra/sockets";

export const useControlStatus = (sessionId: string) => {
	const { actions } = useControlViewersStore();

	const fetchControlStatus = useCallback(async () => {
		try {
		   const response = await getSessionControlStatus(sessionId);
		   if (response.session_controls) {
			  const viewers = response.session_controls.flatMap(
			  (control: SessionControlsMap) =>
			    control.members.map((memberId) => ({
				user_id: memberId,
				control: control.control_id,
				session_id: response.session_id,
			  }))
			);

			actions.setInitialViewers(sessionId, viewers);
			}
		} catch (error) {
			console.error("Error fetching control status:", error);
			addNotification({
				type: "error",
				title: "Error",
				message: "Failed to fetch current viewers status",
			});
		}
	}, [sessionId, actions]);

	useEffect(() => {
		const socket = getZaniaSocket();

		// only fetch initial data when socket is connected
		if (socket.connected) {
			fetchControlStatus();
		}

		const handleConnect = () => {
			fetchControlStatus();
		};

		socket.on("connect", handleConnect);

		return () => {
			socket.off("connect", handleConnect);
		};
	}, [fetchControlStatus]);

	return { refreshControlStatus: fetchControlStatus };
};
