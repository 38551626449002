import { cn } from "@/shared/utils/classname-merger";
import { MouseEvent, useCallback, useMemo } from "react";
import {
  CellPosition,
  ExcelItemTypes,
  HighlightData,
  Selection,
} from "../../types/excel-mapper";
import { Merges } from "../../types/questionnaire";

interface ExcelMapperCellProps {
  rowIndex: number;
  columnIndex: number;
  cell: string;
  selection?: Selection;
  merges?: Merges;
  dragStart?: CellPosition;
  dragEnd?: CellPosition;
  dragging: boolean;
  isHighlighted?: HighlightData;
  allowSelection?: boolean;
  isFirstRow?: boolean;
  isLastRow?: boolean;
  handleSelection: (e: MouseEvent<HTMLTableCellElement>) => void;
  handleMouseDown: (rowIndex: number, columnIndex: number) => void;
  handleMouseUp: () => void;
  handleMouseMove: (rowIndex: number, columnIndex: number) => void;
}

const commonCellStyle = "min-w-[200px] max-w-[300px]";

const ExcelMapperCell = ({
  rowIndex,
  columnIndex,
  cell,
  selection,
  merges,
  dragStart,
  dragEnd,
  dragging,
  isHighlighted,
  allowSelection,
  isFirstRow,
  isLastRow,
  handleMouseDown,
  handleMouseUp,
  handleMouseMove,
  handleSelection,
}: ExcelMapperCellProps) => {
  const cellId = `${rowIndex}-${columnIndex}`;

  const isSelected =
    selection?.type === ExcelItemTypes.CELL && selection.cellId === cellId;
  const isColumnSelected =
    selection?.type === ExcelItemTypes.COLUMN &&
    selection.column === columnIndex;
  const isRowSelected =
    selection?.type === ExcelItemTypes.ROW && selection.row === rowIndex;

  const isCellMerged = useCallback(
    (row: number, col: number) => {
      return merges?.find(
        (merge) =>
          row >= merge.s.r &&
          row <= merge.e.r &&
          col >= merge.s.c &&
          col <= merge.e.c,
      );
    },
    [merges],
  );
  const merge = useMemo(
    () => isCellMerged(rowIndex, columnIndex),
    [columnIndex, isCellMerged, rowIndex],
  );

  const cellProps = useMemo(() => {
    if (merge) {
      if (merge.s.r === rowIndex && merge.s.c === columnIndex) {
        const rowspan = merge.e.r - merge.s.r + 1;
        const colspan = merge.e.c - merge.s.c + 1;
        return { rowSpan: rowspan, colSpan: colspan };
      }
      return { hidden: true };
    }
    return {};
  }, [merge, rowIndex, columnIndex]);

  const isInSelectionRange = useCallback(
    (start: CellPosition, end: CellPosition, row: number, col: number) => {
      const [startRow, endRow] = [start.rowIndex, end.rowIndex].sort(
        (a, b) => a - b,
      );
      const [startCol, endCol] = [start.columnIndex, end.columnIndex].sort(
        (a, b) => a - b,
      );
      return (
        row >= startRow && row <= endRow && col >= startCol && col <= endCol
      );
    },
    [],
  );

  const isInDragSelection = useMemo(
    () =>
      dragStart &&
      dragEnd &&
      isInSelectionRange(dragStart, dragEnd, rowIndex, columnIndex),
    [dragStart, dragEnd, isInSelectionRange, rowIndex, columnIndex],
  );

  const isInMultiDragSelection = useMemo(
    () =>
      selection?.type === ExcelItemTypes.MULTI_CELL_DRAG &&
      isInSelectionRange(selection.start, selection.end, rowIndex, columnIndex),
    [selection, isInSelectionRange, rowIndex, columnIndex],
  );

  const isCellInMultiSelection = useMemo(
    () =>
      selection?.type === ExcelItemTypes.MULTI_CELL &&
      selection.cells.includes(cellId),
    [selection, cellId],
  );

  const cellClassNames = cn(
    commonCellStyle,
    "[&:not(:last-child)]:border-r-[1px] border-b-[1px] bg-[#F9F9FA] p-1 text-tiny",
    allowSelection && "hover:cursor-pointer",
    allowSelection &&
      (isSelected ||
        isCellInMultiSelection ||
        isColumnSelected ||
        isRowSelected) &&
      "bg-gray-100",
    dragging && "select-none",
    isInDragSelection && dragging && "bg-blue-200",
    !dragging && "hover:bg-gray-100",
    isInMultiDragSelection && "bg-blue-200",
    isInMultiDragSelection && !dragging && "hover:bg-blue-300",
  );

  const highlightStyle = useMemo(() => {
    if (isHighlighted && !merge) {
      return {
        backgroundColor: "transparent",
      };
    }
    return {};
  }, [isHighlighted, merge]);

  return (
    <td
      data-selectionid={cellId}
      data-type={ExcelItemTypes.CELL}
      onClick={handleSelection}
      onMouseDown={() => handleMouseDown(rowIndex, columnIndex)}
      onMouseUp={handleMouseUp}
      onMouseMove={() => handleMouseMove(rowIndex, columnIndex)}
      className={cellClassNames}
      style={{ ...highlightStyle }}
      {...cellProps}
    >
      {cell}
    </td>
  );
};

export default ExcelMapperCell;
