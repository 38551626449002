const IgnoredSVG = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="63"
      height="52"
      viewBox="0 0 63 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.919 1.21154C35.2592 -0.452402 27.8321 -2.15073 22.117 1.63719C16.5777 5.30849 17.2824 13.1875 14.6518 19.2838C11.1134 27.4842 0.318522 34.898 4.31477 42.8941C8.26912 50.8064 20.426 47.7496 29.2499 48.4931C35.8039 49.0453 42.8524 50.4965 48.1308 46.5858C53.0867 42.9141 52.4259 35.7771 54.3263 29.9145C56.4244 23.4419 62.3625 17.268 59.6605 11.0174C56.8766 4.57709 48.7347 2.91443 41.919 1.21154Z"
        fill="url(#paint0_linear_1712_12037)"
      />
      <path
        opacity="0.9"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.919 1.21154C35.2592 -0.452402 27.8321 -2.15073 22.117 1.63719C16.5777 5.30849 17.2824 13.1875 14.6518 19.2838C11.1134 27.4842 0.318522 34.898 4.31477 42.8941C8.26912 50.8064 20.426 47.7496 29.2499 48.4931C35.8039 49.0453 42.8524 50.4965 48.1308 46.5858C53.0867 42.9141 52.4259 35.7771 54.3263 29.9145C56.4244 23.4419 62.3625 17.268 59.6605 11.0174C56.8766 4.57709 48.7347 2.91443 41.919 1.21154Z"
        fill="white"
      />
      <path
        d="M25.067 10.3651C25.2179 10.221 25.4186 10.1406 25.6272 10.1406H40.3794C40.8274 10.1406 41.1905 10.5038 41.1905 10.9518V45.2981C41.1905 45.7461 40.8274 46.1093 40.3794 46.1093H14.4635C14.0155 46.1093 13.6523 45.7461 13.6523 45.2981V21.6114C13.6523 21.3897 13.743 21.1777 13.9033 21.0247L25.067 10.3651Z"
        fill="white"
        stroke="#71717A"
        strokeWidth="1.5"
      />
      <circle
        cx="41.8822"
        cy="38.3705"
        r="7.76501"
        fill="white"
        stroke="#71717A"
        strokeWidth="1.5"
      />
      <line
        x1="36.5147"
        y1="32.6103"
        x2="47.5341"
        y2="43.6297"
        stroke="#71717A"
        strokeWidth="1.5"
      />
      <path
        d="M14.3086 21.8009H24.3002C25.1625 21.8009 25.8614 21.1019 25.8614 20.2397V10.248"
        stroke="#71717A"
        strokeWidth="1.5"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1712_12037"
          x1="39.0147"
          y1="0.364466"
          x2="25.2764"
          y2="51.6364"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DD9AB0" />
          <stop offset="1" stopColor="#A982CF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default IgnoredSVG;
