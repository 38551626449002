import {
  CircularProgress,
  CircularProgressProps,
  Progress,
  ProgressProps,
} from "@nextui-org/progress";

const ZProgress = ({ children, ...props }: ProgressProps) => {
  return <Progress {...props}>{children}</Progress>;
};

const ZCircularProgress = ({ children, ...props }: CircularProgressProps) => {
  return <CircularProgress {...props}>{children}</CircularProgress>;
};

export { ZProgress, ZCircularProgress };
