import { Socket } from "socket.io-client";
import { create } from "zustand";

interface SocketState {
  isConnected: boolean;
  socket: Socket;
  lastUpdatedTimestamp: number;
  isErrored?: boolean;
}

type SocketData = Record<string, SocketState>;

interface SocketActions {
  addSocket: (socketName: string, socket: SocketState) => void;
  removeSocket: (socketName: string) => void;
  editSocket: (socketName: string, socket: Partial<SocketState>) => void;
}

type SocketStore = {
  sockets: SocketData;
  actions: SocketActions;
};

const useSocketStore = create<SocketStore>((set) => ({
  sockets: {},
  actions: {
    addSocket: (socketName, socket) =>
      set((state) => ({
        sockets: {
          ...state.sockets,
          [socketName]: socket,
        },
      })),
    removeSocket: (socketName) =>
      set((state) => {
        const { [socketName]: _, ...rest } = state.sockets;
        return {
          sockets: rest,
        };
      }),
    editSocket: (socketName, socket) =>
      set((state) => ({
        sockets: {
          ...state.sockets,
          [socketName]: {
            ...state.sockets[socketName],
            ...socket,
          },
        },
      })),
  },
}));

export const useSocketActions = () => useSocketStore((state) => state.actions);
export const useSocketData = (socketName: string) =>
  useSocketStore((state) => state.sockets[socketName]);
