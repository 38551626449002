import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@nextui-org/react";
import { Menu } from "lucide-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { mappingHelper } from "../../constants/helpers";
import { useAgentSelector } from "../../states";
import {
  getSheetStatus,
  useQuestionnaireFile,
  useSelectedSheetData,
  useSelectedSheetMapping,
} from "../../states/questionnaire";
import {
  ExcelItemTypes,
  HighlightData,
  Selection,
} from "../../types/excel-mapper";
import {
  AnswerColumnMappingData,
  ColumnMappingData,
  MappingType,
  SheetStatus,
} from "../../types/questionnaire";
import ExcelMapper from "../excel-mapper";
import QuestionnaireCustomHeader from "./custom-header";
import SheetSelector from "./sheet-selector";

interface Params {
  id: string;
}

const QuestionnaireMapping = () => {
  const { id: agentId } = useParams<keyof Params>() as Params;

  const { setSelectedSheet, updateSheetMapping, setSheetStatus } =
    useAgentSelector.useActions();

  const { sheetNames } = useQuestionnaireFile(agentId);
  const { selectedSheetId, sheetData } = useSelectedSheetData(agentId);
  const data = useSelectedSheetMapping(agentId);

  const [selectionActive, setSelectionActive] = useState<
    MappingType | undefined
  >(undefined);

  const [highlightedColumn, setHighlightedColumn] = useState<
    Map<number, HighlightData>
  >(new Map());

  const [highlightedRow, setHighlightedRow] =
    useState<Map<number, HighlightData>>();

  const newColumnHighlights = useMemo(() => {
    if (!data || !selectedSheetId) return new Map();
    const highlights: Map<number, HighlightData> = new Map();

    if (data.questions) {
      data.questions.forEach((value) => {
        highlights.set(value.columnIndex, {
          backgroundColor: mappingHelper[MappingType.QUESTIONS].bg,
          borderColor: mappingHelper[MappingType.QUESTIONS].text,
        });
      });
    }
    if (data.answers) {
      data.answers.forEach((value) => {
        highlights.set(value.columnIndex, {
          backgroundColor: mappingHelper[MappingType.ANSWERS].bg,
          borderColor: mappingHelper[MappingType.ANSWERS].text,
        });
      });
    }
    if (data.comments) {
      data.comments.forEach((value) => {
        highlights.set(value.columnIndex, {
          backgroundColor: mappingHelper[MappingType.COMMENTS].bg,
          borderColor: mappingHelper[MappingType.COMMENTS].text,
        });
      });
    }

    if (data.questionType) {
      data.questionType.forEach((value) => {
        highlights.set(value.columnIndex, {
          backgroundColor: mappingHelper[MappingType.QUESTION_TYPE].bg,
          borderColor: mappingHelper[MappingType.QUESTION_TYPE].text,
        });
      });
    }

    return highlights;
  }, [data, selectedSheetId]);

  useEffect(() => {
    setHighlightedColumn(newColumnHighlights);
  }, [newColumnHighlights]);

  useEffect(() => {
    if (!data || !selectedSheetId) return;

    const newRowHiglights: Map<number, HighlightData> = new Map();

    if (
      typeof data.questionStartRow === "number" &&
      !isNaN(data.questionStartRow)
    ) {
      newRowHiglights.set(data.questionStartRow, {
        backgroundColor: mappingHelper[MappingType.QUESTION_START_ROW].bg,
        borderColor: mappingHelper[MappingType.QUESTION_START_ROW].text,
      });
    }

    setHighlightedRow(newRowHiglights);
  }, [data, selectedSheetId]);

  const selectionType = useMemo(() => {
    switch (selectionActive) {
      case MappingType.QUESTIONS:
      case MappingType.COMMENTS:
      case MappingType.QUESTION_TYPE:
      case MappingType.ANSWERS:
        return [ExcelItemTypes.COLUMN, ExcelItemTypes.ROW];
      case MappingType.QUESTION_START_ROW:
        return [ExcelItemTypes.ROW];
      default:
        return [ExcelItemTypes.ROW];
    }
  }, [selectionActive]);

  const resetSelection = useCallback(() => {
    setSelectionActive(undefined);
  }, []);

  const handleSelection = useCallback(
    (selection?: Selection) => {
      if (!selection) return;
      const { type } = selection;

      switch (type) {
        case ExcelItemTypes.COLUMN:
          if (
            selectionActive === MappingType.QUESTIONS ||
            selectionActive === MappingType.COMMENTS ||
            selectionActive === MappingType.QUESTION_TYPE ||
            selectionActive === MappingType.ANSWERS
          ) {
            updateSheetMapping(agentId, {
              sheetName: selectedSheetId,
              type: selectionActive,
              action: "add",
              value: {
                columnIndex: selection.column,
                columnLetter: String.fromCharCode(65 + selection.column),
              },
            });
            if (selectionActive === MappingType.QUESTIONS) {
              if (typeof data.questionStartRow !== "number") {
                setSelectionActive(MappingType.QUESTION_START_ROW);
              } else {
                resetSelection();
              }
            }
            if (
              selectionActive === MappingType.COMMENTS ||
              selectionActive === MappingType.QUESTION_TYPE
            ) {
              resetSelection();
            }
          }
          break;
        case ExcelItemTypes.MULTI_COLUMN:
          if (selectionActive === MappingType.ANSWERS) {
            const columns = selection.columns.map((column) => {
              return {
                columnIndex: column,
                columnLetter: String.fromCharCode(65 + column),
              };
            });

            updateSheetMapping(agentId, {
              sheetName: selectedSheetId,
              type: selectionActive,
              action: "add",
              value: columns,
              replace: true,
            });
          }
          break;
        case ExcelItemTypes.ROW:
          updateSheetMapping(agentId, {
            sheetName: selectedSheetId,
            type: MappingType.QUESTION_START_ROW,
            action: "add",
            value: +selection.row,
          });
          if (data.questions.length > 0) {
            setSelectionActive(MappingType.ANSWERS);
          } else {
            setSelectionActive(MappingType.QUESTIONS);
          }
          break;
      }
    },
    [
      agentId,
      data.questionStartRow,
      data.questions.length,
      resetSelection,
      selectedSheetId,
      selectionActive,
      updateSheetMapping,
    ],
  );

  const removeSelection = useCallback(
    (type: MappingType, value: ColumnMappingData | AnswerColumnMappingData) => {
      if (type === MappingType.QUESTION_START_ROW) return;
      updateSheetMapping(agentId, {
        sheetName: selectedSheetId,
        type,
        action: "remove",
        value,
      });
    },
    [agentId, selectedSheetId, updateSheetMapping],
  );

  const handleSheetChange = useCallback(
    async (sheetName: string) => {
      await setSelectedSheet(agentId, sheetName);
      setSelectionActive(undefined);
    },
    [agentId, setSelectedSheet, setSelectionActive],
  );

  const handleOnClickIgnoreSheet = useCallback(
    (sheetName?: string, selectNextSheet = true) => {
      const sheetNameToIgnore = sheetName ?? selectedSheetId;
      const sheetStatus = getSheetStatus(agentId, sheetNameToIgnore);
      if (sheetStatus !== SheetStatus.IGNORED) {
        setSheetStatus(agentId, sheetNameToIgnore, SheetStatus.IGNORED);
        if (selectNextSheet) {
          const index = sheetNames.indexOf(sheetNameToIgnore);
          if (sheetNames.length > 1 && index + 1 < sheetNames.length) {
            let nextIndex = index + 1;
            while (
              nextIndex < sheetNames.length &&
              (sheetNames[nextIndex].endsWith("::hidden") ||
                getSheetStatus(agentId, sheetNames[nextIndex]) ===
                  SheetStatus.IGNORED)
            ) {
              nextIndex++;
            }
            if (nextIndex < sheetNames.length) {
              void setSelectedSheet(agentId, sheetNames[nextIndex]);
            }
          }
        }
      } else {
        setSheetStatus(agentId, sheetNameToIgnore, undefined);
      }
    },
    [setSheetStatus, agentId, selectedSheetId, sheetNames, setSelectedSheet],
  );

  if (!data) return null;

  return (
    <div className="flex flex-col bg-white h-full">
      <div className="px-4 py-4 flex items-center justify-between border-b">
        <span className="font-semibold text-md">Verify Mapping</span>
        <div className="flex gap-3 items-center">
          <span className="text-sm">{selectedSheetId}</span>
          <Button
            variant="bordered"
            size="sm"
            color={
              getSheetStatus(agentId, selectedSheetId) !== undefined &&
              getSheetStatus(agentId, selectedSheetId) === SheetStatus.IGNORED
                ? "primary"
                : "danger"
            }
            className="text-tiny p-0 px-2 1 h-7 rounded-md"
            onClick={() => handleOnClickIgnoreSheet()}
          >
            {getSheetStatus(agentId, selectedSheetId) === SheetStatus.IGNORED
              ? "Reset"
              : "Ignore Sheet"}
          </Button>
        </div>
      </div>
      <QuestionnaireCustomHeader
        agentId={agentId}
        setSelectionActive={setSelectionActive}
        data={data}
        removeSelection={removeSelection}
        selectionActive={selectionActive}
        handleOnClickIgnoreSheet={handleOnClickIgnoreSheet}
        resetSelection={resetSelection}
      />
      <div className="flex flex-col flex-grow overflow-hidden">
        <div
          className="flex-grow overflow-hidden"
          style={{ height: "calc(100% - 40px)" }}
          data-isselectbutton={true}
        >
          <ExcelMapper
            selectedSheetData={{
              selectedSheet: selectedSheetId,
              merges: sheetData?.merges || [],
              data: sheetData?.data || [],
            }}
            sheetNames={sheetNames}
            setSelectedSheet={(sheet) => {
              void setSelectedSheet(agentId, sheet);
            }}
            showHeader={false}
            allowSelection={!!selectionActive}
            selectionType={selectionType}
            onSelectionChange={handleSelection}
            highlightedColumn={highlightedColumn}
            highlightedRow={highlightedRow}
            resetSelectionOnChange
          />
        </div>
        <div className="flex-shrink-0 overflow-x-auto flex gap-2 bg-white p-1 items-center">
          <Popover placement="top-start">
            <PopoverTrigger>
              <Button isIconOnly variant="light" aria-label="Show sheets">
                <Menu size={20} />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="rounded-md">
              <div className="py-2">
                {sheetNames.map((sheetName) => {
                  if (sheetName.endsWith("::hidden")) return null;
                  return (
                    <div key={sheetName} className="w-full">
                      <SheetSelector
                        sheetName={sheetName}
                        selectedSheetId={selectedSheetId}
                        handleSheetChange={handleSheetChange}
                        agentId={agentId}
                        showMenu={false}
                        handleOnClickIgnoreSheet={handleOnClickIgnoreSheet}
                      />
                    </div>
                  );
                })}
              </div>
            </PopoverContent>
          </Popover>
          {sheetNames.map((sheetName) => {
            if (sheetName.endsWith("::hidden")) return null;
            return (
              <SheetSelector
                key={sheetName}
                sheetName={sheetName}
                selectedSheetId={selectedSheetId}
                handleSheetChange={handleSheetChange}
                agentId={agentId}
                handleOnClickIgnoreSheet={handleOnClickIgnoreSheet}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default QuestionnaireMapping;
