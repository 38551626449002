import { UploadedFile } from "@/shared/types/file-upload";

export const doesFileHasError = (fileObj: UploadedFile) => {
  return (
    fileObj.status === "failed" ||
    fileObj.status === "too_large" ||
    fileObj.status === "too_many_files" ||
    fileObj.status === "duplicate_files"
  );
};
