import { SVGProps } from "react";

const CircleTickHalf = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.1215 8.15756L3 6.03556L3.707 5.32856L5.1215 6.74256L7.9495 3.91406L8.657 4.62156L5.1215 8.15756Z"
        fill={props.color || "#F5A524"}
      />
      <path
        d="M2.11091 9.88909C2.75048 10.5287 3.5379 11.0006 4.40343 11.2632C5.26897 11.5257 6.1859 11.5708 7.073 11.3943C7.9601 11.2179 8.78999 10.8254 9.48916 10.2516C10.1883 9.67776 10.7352 8.94039 11.0813 8.10476C11.4275 7.26913 11.5622 6.36103 11.4735 5.46091C11.3849 4.56078 11.0756 3.69641 10.5731 2.94436C10.0706 2.19232 9.39036 1.5758 8.59268 1.14943C7.795 0.723064 6.90448 0.5 6 0.5V1.50085C6.73989 1.50085 7.46836 1.68333 8.12088 2.03211C8.77341 2.38089 9.32984 2.88521 9.7409 3.50041C10.152 4.1156 10.405 4.82268 10.4775 5.55901C10.55 6.29533 10.4398 7.03818 10.1567 7.72175C9.87352 8.40532 9.42617 9.00851 8.85423 9.47789C8.28229 9.94727 7.60341 10.2683 6.87774 10.4127C6.15207 10.557 5.402 10.5202 4.69397 10.3054C3.98594 10.0906 3.3418 9.70456 2.81862 9.18138L2.11091 9.88909Z"
        fill={props.color || "#F5A524"}
      />
    </svg>
  );
};

export default CircleTickHalf;
