import { memo } from "react";
import CustomizeControlSearchBar from "./CustomizeControlSearchBar";
import CustomizeControlTopLeftSection from "./CustomizeControlTopLeftSection";
import CustomizeControlTopRIghtSection from "./CustomizeControlTopRIghtSection";

const CustomizeControlTopSection = () => {
  return (
    <div className="px-6 py-4 grid grid-cols-3 min-h-[69px] bg-white  gap-2 ">
      <div className="col-span-1 items-center my-auto">
        <CustomizeControlTopLeftSection />
      </div>
      <div className="col-span-1">
        <CustomizeControlSearchBar />
      </div>
      <div className="col-span-1 flex justify-end items-center my-auto">
        <div className="flex flex-row gap-1">
          <CustomizeControlTopRIghtSection />
        </div>
      </div>
    </div>
  );
};

export default memo(CustomizeControlTopSection);
