import { useAgentStepData } from "@/modules/agent/states";
import { AgentSessionStepType } from "@/modules/sessions/types";
import { AgentSessionStatus } from "@/modules/sessions/types";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  cn,
} from "@nextui-org/react";
import { Ellipsis, SquarePen, Trash } from "lucide-react";
import { useParams } from "react-router-dom";
import { IdParams } from "../Overview/Overview";

export const SupplyChainKebabMenu = ({
  handleDelete,
  handleEdit,
  isReportReady,
}: {
  handleEdit: () => void;
  handleDelete: () => void;
  isReportReady: boolean;
}) => {
  return (
    <div className="relative flex justify-end items-center h-10 group-hover:visible invisible">
      <Dropdown className={cn("table-checkbox")}>
        <DropdownTrigger>
          <Button isIconOnly className={cn("bg-transparent")}>
            <Ellipsis className="h-5 w-5" color="#717179" />
          </Button>
        </DropdownTrigger>
        <DropdownMenu>
          <DropdownItem
            key="edit-sub-services"
            onClick={handleEdit}
            isDisabled={isReportReady}
          >
            <div className="flex gap-2 items-center">
              <SquarePen size={16} color="#52525B" />
              <span className="text-sm highlight">Edit</span>
            </div>
          </DropdownItem>
          <DropdownItem
            key="remove-sub-services"
            onClick={handleDelete}
            isDisabled={isReportReady}
          >
            <div className="flex gap-2 items-center">
              <Trash size={16} color="#f31260" />
              <span className="text-red-500 text-sm highlight">Delete</span>
            </div>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};
