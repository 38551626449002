import {
  ZCard,
  ZCardBody,
  ZCardDescription,
  ZCardHeader,
  ZCardTitle,
} from "@/shared/components/card";
import ZaniaExpandedIcon from "@/shared/icons/zania-expanded";
import { ReactNode } from "react";

interface LoginCardProps {
  hideHeader?: boolean;
  children: ReactNode;
  title?: string | ReactNode;
  description?: string;
}
const LoginCard = ({
  hideHeader = false,
  children,
  title,
  description,
}: LoginCardProps) => {
  return (
    <ZCard className="w-full max-w-md overflow-hidden">
      {!hideHeader ? (
        <ZCardHeader className="p-6 flex flex-col gap-2 items-start border-b border-divider">
          <ZaniaExpandedIcon className="h-10 " />
        </ZCardHeader>
      ) : null}
      <ZCardBody className="grid gap-6 p-6">
        {title || description ? (
          <div className="flex flex-col gap-2">
            {title && <ZCardTitle className="text-lg ">{title}</ZCardTitle>}
            {description && (
              <ZCardDescription
                className="text-medium text-[#777780]"
                dangerouslySetInnerHTML={{ __html: description }}
              ></ZCardDescription>
            )}
          </div>
        ) : null}
        {children}
      </ZCardBody>
    </ZCard>
  );
};

export default LoginCard;
