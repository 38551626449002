import { ReactNode } from "react";
import { Merges } from "./questionnaire";

export enum ExcelItemTypes {
  COLUMN = "COLUMN",
  ROW = "ROW",
  CELL = "CELL",
  MULTI_CELL = "MULTI_CELL",
  MULTI_CELL_DRAG = "MULTI_CELL_DRAG",
  MULTI_COLUMN = "MULTI_COLUMN",
}

export interface ColumnSelected {
  type: ExcelItemTypes.COLUMN;
  column: number;
}

export interface RowSelected {
  type: ExcelItemTypes.ROW;
  row: number;
}

export interface CellSelected {
  type: ExcelItemTypes.CELL;
  cellId: string;
}

export interface MultiCellDragSelected {
  type: ExcelItemTypes.MULTI_CELL_DRAG;
  start: CellPosition;
  end: CellPosition;
  cells: string[];
}

export interface MultiCellSelected {
  type: ExcelItemTypes.MULTI_CELL;
  cells: string[];
}

export interface MultiColumnSelected {
  type: ExcelItemTypes.MULTI_COLUMN;
  columns: number[];
}
export interface CellPosition {
  rowIndex: number;
  columnIndex: number;
}

export type Selection =
  | ColumnSelected
  | RowSelected
  | CellSelected
  | MultiCellSelected
  | MultiCellDragSelected
  | MultiColumnSelected;

export type SelectionType = ExcelItemTypes[] | "all";

export interface SelectedSheetDataExcel {
  selectedSheet: string;
  data: string[][];
  merges?: Merges;
}

export interface ExcelMapperCommonProps {
  selectedSheetData: SelectedSheetDataExcel;
  sheetNames?: string[];
  highlightedColumn?: Map<number, HighlightData>;
  highlightedRow?: Map<number, HighlightData>;
  highlightedCell?: Map<string, HighlightData>;
  customHeader?: ReactNode;
  showHeader?: boolean;
  setSelectedSheet: (sheetName: string) => void;
  onSelectionChange?: (selection: Selection | undefined) => void;
  resetSelectionOnChange?: boolean;
  scrollToRowSelection?: boolean | number;
}

export interface ExcelSelectionAllowed extends ExcelMapperCommonProps {
  allowSelection: true;
  selectionType: SelectionType;
}

export interface ExcelSelectionNotAllowed extends ExcelMapperCommonProps {
  allowSelection: false;
}

export type ExcelMapperProps = ExcelSelectionAllowed | ExcelSelectionNotAllowed;

export interface HighlightData {
  borderColor: string;
  backgroundColor?: string;
}
