import { ROUTES } from "@/shared/constants/routes";
import { RouteObject } from "react-router-dom";
import AgentSessionsHistory from "../../sessions/components/index";

export const AgentSessionRoutes: RouteObject[] = [
  {
    path: ROUTES.AGENT_SESSIONS,
    // lazy: importLazy(() => import("@/modules/sessions/components")),
    Component: AgentSessionsHistory,
    children: [],
  },
];
