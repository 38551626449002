import IgnoredSVG from "@/shared/icons/ignored";
import { cn } from "@/shared/utils/classname-merger";
import { XIcon } from "lucide-react";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useAgentSelector } from "../../states";
import { useSelectedSheet } from "../../states/questionnaire";
import {
  AnswerColumnMappingData,
  ColumnMappingData,
  MappingData,
  MappingType,
  SheetStatus,
} from "../../types/questionnaire";

interface QuestionnaireCustomHeaderProps {
  setSelectionActive: (type: MappingType | undefined) => void;
  removeSelection: (
    type: MappingType,
    value: ColumnMappingData | AnswerColumnMappingData,
  ) => void;
  data: MappingData;
  agentId: string;
  selectionActive: MappingType | undefined;
  handleOnClickIgnoreSheet: () => void;
  resetSelection: () => void;
}

enum InstructionType {
  SELECT_COLUMN = "Select Column",
  ADD_INSTRUCTIONS = "Add Instructions",
}

const QuestionnaireCustomHeader = ({
  setSelectionActive,
  removeSelection,
  data,
  agentId,
  selectionActive,
  resetSelection,
}: QuestionnaireCustomHeaderProps) => {
  const { updateSheetMapping } = useAgentSelector.useActions();

  const selectedSheetId = useSelectedSheet(agentId);

  const [instructionType, setInstructionType] = useState<
    InstructionType | undefined
  >(undefined);

  const handleOnChangeAnswerType = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const columnIndex = e.currentTarget.getAttribute("data-column");
      if (!columnIndex) return;
      const answerType = value;
      const currentData = data[MappingType.ANSWERS].find(
        (value) => value.columnIndex === Number(columnIndex),
      );
      if (!currentData) return;

      updateSheetMapping(agentId, {
        sheetName: selectedSheetId,
        type: MappingType.ANSWERS,
        action: "edit",
        value: { ...currentData, answerType },
        oldColumnIndex: currentData.columnIndex,
      });
    },
    [agentId, data, selectedSheetId, updateSheetMapping],
  );

  const renderInstructionType = () => {
    switch (instructionType) {
      case InstructionType.SELECT_COLUMN: {
        return (
          <div className="rounded border text-tiny overflow-hidden inline-flex items-center w-fit">
            <span className="px-2 py-1   text-[#67657A]">
              Read instructions from
            </span>

            <button
              className={cn(
                "px-2 py-1 font-medium text-[#006FEE] hover:bg-blue-50 border-x",
                data[MappingType.QUESTION_TYPE].length > 0 &&
                  "bg-[#E6F1FE] text-[#002E62] hover:bg-[#eef4fb]",
                selectionActive === MappingType.QUESTION_TYPE &&
                  "bg-blue-50 text-[#006FEE] border border-dashed border-[#006FEE]",
              )}
              onClick={() => {
                if (data[MappingType.QUESTION_TYPE].length > 0) {
                  removeSelection(
                    MappingType.QUESTION_TYPE,
                    data[MappingType.QUESTION_TYPE][0],
                  );
                }
                setSelectionActive(MappingType.QUESTION_TYPE);
              }}
              data-isselectbutton={
                !data[MappingType.QUESTION_TYPE]?.[0]?.columnLetter
              }
            >
              {data[MappingType.QUESTION_TYPE]?.[0]?.columnLetter
                ? `Column ${data[MappingType.QUESTION_TYPE][0].columnLetter}`
                : "Select Column"}
            </button>
            <button
              className="px-2 py-1 text-[#67657A] hover:bg-gray-100"
              onClick={() => {
                setSelectionActive(undefined);
                setInstructionType(undefined);
                removeSelection(
                  MappingType.QUESTION_TYPE,
                  data[MappingType.QUESTION_TYPE][0],
                );
              }}
            >
              <XIcon size={16} />
            </button>
          </div>
        );
      }
      case InstructionType.ADD_INSTRUCTIONS: {
        const answerColumns = data[MappingType.ANSWERS];
        const rows = Math.ceil(answerColumns.length / 2);

        return (
          <div className="flex flex-col gap-2">
            {Array.from({ length: rows }).map((_, rowIndex) => (
              <div
                key={rowIndex}
                className="flex items-center gap-2 text-tiny rounded border w-fit"
              >
                <span className="text-[#67657A] px-1">
                  {rowIndex === 0 ? "Fill" : "and"}
                </span>
                <input
                  placeholder="Enter Value"
                  className="border-x px-2 w-24 h-6 text-tiny"
                  data-column={answerColumns[rowIndex * 2]?.columnIndex}
                  onChange={handleOnChangeAnswerType}
                  value={answerColumns[rowIndex * 2]?.answerType}
                />
                <span className="text-[#67657A] px-1">in</span>
                <span className="border-x h-full inline-flex items-center px-2 bg-[#F9F0FF] text-[#7820BC]">
                  Column {answerColumns[rowIndex * 2]?.columnLetter}
                </span>
                {rowIndex * 2 + 1 < answerColumns.length && (
                  <>
                    <span className="text-[#67657A] px-1">and</span>
                    <input
                      placeholder="Enter Value"
                      className="border-x px-2 w-24 h-6 text-tiny"
                      data-column={answerColumns[rowIndex * 2 + 1]?.columnIndex}
                      onChange={handleOnChangeAnswerType}
                      value={answerColumns[rowIndex * 2 + 1]?.answerType}
                    />
                    <span className="text-[#67657A] px-1">in</span>
                    <span className="h-full border-l inline-flex items-center px-2 bg-[#F9F0FF] text-[#7820BC]">
                      Column {answerColumns[rowIndex * 2 + 1]?.columnLetter}
                    </span>
                  </>
                )}
                {rowIndex === rows - 1 && (
                  <button
                    className="text-gray-400 hover:text-gray-600 pr-2"
                    onClick={() => {
                      setInstructionType(undefined);
                    }}
                  >
                    <XIcon size={16} />
                  </button>
                )}
              </div>
            ))}
          </div>
        );
      }
      default:
        return null;
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        event.target instanceof HTMLElement &&
        (event.target.dataset.isselectbutton === "true" ||
          event.target.closest('[data-isselectbutton="true"]'))
      ) {
        return;
      }
      setSelectionActive(undefined);
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectionActive, setSelectionActive]);

  useEffect(() => {
    if (data.answers.length === 0) {
      if (data.questionType.length > 0) {
        removeSelection(MappingType.QUESTION_TYPE, data.questionType[0]);
      }
      setInstructionType(undefined);
    }
  }, [data.answers.length, data.questionType, removeSelection]);

  return (
    <div className="w-full px-3 border-b-[1px] flex flex-col bg-white bg-opacity-75">
      <div className="relative">
        {data?.status === SheetStatus.IGNORED ? (
          <div className="w-full py-3">
            <div className="px-10 rounded-md py-5 flex max-h-36 items-center bg-[#f8f8f8] justify-center gap-4">
              <IgnoredSVG
                style={{
                  maxHeight: "100%",
                  width: "auto",
                }}
              />
              <div className="flex flex-col items-start gap-2">
                <p className="text-lg">Sheet Ignored from Mapping</p>
                <p className="text-sm text-[#71717A]">
                  Zania will not extract questions from this sheet.
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="p-2 pb-4 flex bg-white justify-between">
            <div className="flex-1 flex flex-col gap-2">
              <div className="rounded border text-tiny overflow-hidden inline-flex items-center w-fit">
                <span className="px-2 py-1 text-[#67657A]">
                  Extract questions from
                </span>
                <button
                  className={cn(
                    "px-2 py-1 font-medium border-x text-[#006FEE] hover:bg-blue-50",
                    data[MappingType.QUESTIONS].length > 0 &&
                      "bg-[#FFF6E6] hover:bg-[#fbf4e8] text-[#A35200]",
                    selectionActive === MappingType.QUESTIONS &&
                      "bg-blue-50 text-[#006FEE] border border-dashed border-[#006FEE]",
                  )}
                  onClick={() => {
                    if (data[MappingType.QUESTIONS].length > 0) {
                      removeSelection(
                        MappingType.QUESTIONS,
                        data[MappingType.QUESTIONS][0],
                      );
                    }
                    setSelectionActive(MappingType.QUESTIONS);
                  }}
                  data-isselectbutton="true"
                >
                  {data[MappingType.QUESTIONS]?.[0]?.columnLetter
                    ? `Column ${data[MappingType.QUESTIONS][0].columnLetter}`
                    : "Select Column"}
                </button>
                <button
                  className={cn(
                    "px-2 py-1 text-[#006FEE] hover:bg-blue-50  font-medium",
                    typeof data[MappingType.QUESTION_START_ROW] === "number" &&
                      "bg-[#EFFBEF] text-[#297A3A] hover:bg-[#eff9ef]",
                    selectionActive === MappingType.QUESTION_START_ROW &&
                      "bg-blue-50 text-[#006FEE] border border-dashed border-[#006FEE]",
                  )}
                  onClick={() => {
                    if (
                      typeof data[MappingType.QUESTION_START_ROW] === "number"
                    ) {
                      updateSheetMapping(agentId, {
                        sheetName: selectedSheetId,
                        type: MappingType.QUESTION_START_ROW,
                        action: "remove",
                      });
                    }
                    if (selectionActive === MappingType.QUESTION_START_ROW) {
                      resetSelection();
                    } else {
                      setSelectionActive(MappingType.QUESTION_START_ROW);
                    }
                  }}
                  data-isselectbutton="true"
                >
                  {typeof data[MappingType.QUESTION_START_ROW] === "number"
                    ? `Row ${data[MappingType.QUESTION_START_ROW] + 1} and below`
                    : "Select Row"}
                </button>
              </div>
              <div className="rounded border text-tiny overflow-hidden inline-flex items-center w-fit">
                <span className="px-2 py-1  text-[#67657A] border-r">
                  Fill answers at
                </span>
                {data[MappingType.ANSWERS].map((answer) => (
                  <button
                    key={answer.columnIndex}
                    className={cn(
                      "px-2 py-1 font-medium border-r bg-[#F9F0FF] text-[#7820BC]",
                    )}
                    onClick={() => {
                      removeSelection(MappingType.ANSWERS, answer);
                    }}
                  >
                    Column {answer.columnLetter}
                  </button>
                ))}
                {data.answers.length < 5 ? (
                  <button
                    className={cn(
                      "px-2 py-1 font-medium  text-[#006FEE] hover:bg-blue-50 cursor-pointer",
                      selectionActive === MappingType.ANSWERS &&
                        "bg-blue-50 text-[#006FEE] border border-dashed border-[#006FEE]",
                    )}
                    onClick={() => {
                      if (selectionActive === MappingType.ANSWERS) {
                        resetSelection();
                      } else {
                        setSelectionActive(MappingType.ANSWERS);
                      }
                    }}
                    data-isselectbutton="true"
                  >
                    {data[MappingType.ANSWERS].length > 0
                      ? "Add column(s)"
                      : "Select Column"}
                  </button>
                ) : null}
              </div>
              <div className="rounded border text-tiny overflow-hidden inline-flex items-center w-fit">
                <span className="px-2 py-1 border-r text-[#67657A]">
                  Fill comments (optional) at
                </span>

                <button
                  className={cn(
                    "px-2 py-1 font-medium text-[#006FEE] hover:bg-blue-50",
                    data[MappingType.COMMENTS].length > 0 &&
                      "bg-[#FFEBF5] text-[#BD2864] hover:bg-[#fffdfe]",
                    selectionActive === MappingType.COMMENTS &&
                      "bg-blue-50 text-[#006FEE] border border-dashed border-[#006FEE]",
                  )}
                  onClick={() => {
                    if (data[MappingType.COMMENTS].length > 0) {
                      removeSelection(
                        MappingType.COMMENTS,
                        data[MappingType.COMMENTS][0],
                      );
                    }
                    setSelectionActive(MappingType.COMMENTS);
                  }}
                  data-isselectbutton="true"
                >
                  {data[MappingType.COMMENTS]?.[0]?.columnLetter
                    ? `Column ${data[MappingType.COMMENTS][0].columnLetter}`
                    : "Select Column"}
                </button>
              </div>
            </div>
            <div className="flex-1 flex  flex-col gap-2">
              <div className="flex flex-col gap-2 text-tiny">
                <span className=" text-[#777777]">
                  Add Auto-fill Instructions (optional)
                </span>
                {!instructionType ? (
                  <div className="flex gap-2">
                    <button
                      className="px-2 py-1 font-medium border rounded-md border-[#006FEE] text-[#006FEE]  hover:bg-blue-50
                        disabled:opacity-50
                        disabled:cursor-not-allowed
                      "
                      onClick={() => {
                        setInstructionType(InstructionType.SELECT_COLUMN);
                        setSelectionActive(MappingType.QUESTION_TYPE);
                      }}
                      disabled={data[MappingType.ANSWERS].length === 0}
                    >
                      Select Instruction Column
                    </button>
                    <span className="px-2 py-1 text-[#67657A]">or</span>
                    <button
                      className="px-2 py-1 font-medium border rounded-md border-[#006FEE] text-[#006FEE] hover:bg-blue-50
                        disabled:opacity-50
                        disabled:cursor-not-allowed
                      "
                      onClick={() =>
                        setInstructionType(InstructionType.ADD_INSTRUCTIONS)
                      }
                      disabled={data[MappingType.ANSWERS].length < 2}
                    >
                      Add Instructions
                    </button>
                  </div>
                ) : (
                  renderInstructionType()
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuestionnaireCustomHeader;
