import { SVGProps } from "react";

const DoubleArrowIcon = (props: SVGProps<SVGSVGElement>) => {
  const { stroke = "white", width = "12", height = "12", ...rest } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      {...rest}
    >
      <path
        d="M9.375 9.75L5.625 6L9.375 2.25"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.625 9.75L1.875 6L5.625 2.25"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DoubleArrowIcon;
