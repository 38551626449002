import { SVGProps } from "react";

const CircleTick = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_855_32104)">
        <path
          d="M5.1215 8.15701L3 6.03501L3.707 5.32801L5.1215 6.74201L7.9495 3.91351L8.657 4.62101L5.1215 8.15701Z"
          fill="#45D483"
        />
        <path
          d="M11.5 6C11.5 9.03757 9.03757 11.5 6 11.5C2.96243 11.5 0.5 9.03757 0.5 6C0.5 2.96243 2.96243 0.5 6 0.5C9.03757 0.5 11.5 2.96243 11.5 6ZM1.50085 6C1.50085 8.48481 3.51519 10.4991 6 10.4991C8.48481 10.4991 10.4991 8.48481 10.4991 6C10.4991 3.51519 8.48481 1.50085 6 1.50085C3.51519 1.50085 1.50085 3.51519 1.50085 6Z"
          fill="#45D483"
        />
      </g>
      <defs>
        <clipPath id="clip0_855_32104">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CircleTick;
