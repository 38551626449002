import { ROUTES } from "@/shared/constants/routes";
import { useStytch } from "@/shared/states/stytch";
import { isEmailAllowed } from "@/shared/utils/email";
import {
  checkValidDomain,
  getEmailCompanySlug,
} from "@/shared/utils/organization-helper";
import ZaniaLogoLoader from "@components/page-loader";
import { DiscoveredOrganization } from "@stytch/vanilla-js";
import { useCallback, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { sessionDurationMins } from "../../constants";
import { useAuthActions } from "../../states";
import { useLoginActions } from "../../states/login";
import { LOGIN_STEP } from "../../types";

const Authenticate = () => {
  const [searchParams] = useSearchParams();

  const token = searchParams.get("token");
  const tokenType = searchParams.get("stytch_token_type");
  const { setAuth } = useAuthActions();
  const navigate = useNavigate();
  const {
    setLoginStep,
    setLoginDetails,
    setDiscoveredOrganizations,
  } = useLoginActions();
  const stytch = useStytch();

  const completeAuth = useCallback(() => {
    setAuth({
      isAuthenticated: true,
      isAuthenticationInProgress: false,
    });
  }, [setAuth]);

  useEffect(() => {
    setLoginStep(LOGIN_STEP.AUTHENTICATING);
  }, []);

  const authenticate = useCallback(async () => {
    if (token && tokenType) {
      try {
        let discovered_organizations: DiscoveredOrganization[] = [];
        let emailAddress: string | undefined;
        switch (tokenType) {
          case "discovery": {
            const {
              discovered_organizations: orgs,
              email_address,
            } = await stytch.magicLinks.discovery.authenticate({
              discovery_magic_links_token: token,
            });
            emailAddress = email_address;
            discovered_organizations = orgs;
            break;
          }
          case "discovery_oauth": {
            const {
              discovered_organizations: orgs,
              email_address,
            } = await stytch.oauth.discovery.authenticate({
              discovery_oauth_token: token,
            });
            emailAddress = email_address;
            // if (!isEmailAllowed(email_address)) {
            //   throw new Error('Email not allowed');
            // }
            discovered_organizations = orgs;
            break;
          }
          case "multi_tenant_magic_links": {
            const {
              member_authenticated,
            } = await stytch.magicLinks.authenticate({
              magic_links_token: token,
              session_duration_minutes: sessionDurationMins,
            });
            if (!member_authenticated) {
              throw new Error("Member not authenticated");
            }
            completeAuth();
            return;
          }
          case "sso": {
            const {
              member_authenticated,
              member: { email_address },
            } = await stytch.sso.authenticate({
              sso_token: token,
              session_duration_minutes: sessionDurationMins,
            });
            if (!member_authenticated) {
              throw new Error("Member not authenticated");
            }

            const organization = await stytch.discovery.organizations.list();
            discovered_organizations = organization.discovered_organizations;
            emailAddress = email_address;

            if (organization.discovered_organizations.length > 1) {
              discovered_organizations = organization.discovered_organizations;
              setDiscoveredOrganizations(organization.discovered_organizations);
              setLoginStep(LOGIN_STEP.SELECT_ORG);
              navigate(`/${ROUTES.LOGIN}`);
            } else {
              completeAuth();
              setLoginStep(LOGIN_STEP.MAIN);
              navigate(`/${ROUTES.AGENT}`);
            }

            return;
          }
          default:
            throw new Error("Invalid token type");
        }

        if (!emailAddress) {
          throw new Error("Email address not found");
        }

        const organizationSlug = getEmailCompanySlug(emailAddress);
        const emailDomainArr: Array<string> = discovered_organizations.flatMap(
          (domain) =>
            domain.organization.email_allowed_domains?.map((domain) =>
              domain.toLowerCase()
            )
        );

        if (
          discovered_organizations.length > 0 &&
          !checkValidDomain(emailAddress, emailDomainArr)
        ) {
          setLoginStep(LOGIN_STEP.ACCESS_RESTRICTED);
          navigate(`/${ROUTES.LOGIN}`);
          return;
        }

        setLoginDetails(emailAddress, organizationSlug);
        setDiscoveredOrganizations(discovered_organizations);

        const {
          organization: organizationFound,
        } = await stytch.organization.getBySlug({
          organization_slug: organizationSlug,
        });

        if (organizationFound && discovered_organizations.length === 0) {
          navigate(`/${ROUTES.SIGNUP}?orgBlank=true`);
          return;
        }

        if (discovered_organizations.length === 0) {
          if (!isEmailAllowed(emailAddress)) {
            setLoginStep(LOGIN_STEP.ACCESS_RESTRICTED);
            navigate(`/${ROUTES.LOGIN}`);
            return;
          }
          navigate(`/${ROUTES.SIGNUP}`);
          return;
        }

        if (discovered_organizations.length > 1) {
          setLoginStep(LOGIN_STEP.SELECT_ORG);
          navigate(`/${ROUTES.LOGIN}`);
          return;
        }

        if (discovered_organizations.length === 1) {
          await stytch.discovery.intermediateSessions.exchange({
            organization_id:
              discovered_organizations[0].organization.organization_id,
            session_duration_minutes: sessionDurationMins,
          });

          completeAuth();
        }
      } catch (e) {
        console.error(e);
        setLoginStep(LOGIN_STEP.ERROR);
        navigate(`/${ROUTES.LOGIN}`);
      }
    } else {
      setLoginStep(LOGIN_STEP.MAIN);
      navigate(`/${ROUTES.LOGIN}`);
    }
  }, [
    completeAuth,
    navigate,
    setDiscoveredOrganizations,
    setLoginDetails,
    setLoginStep,
    stytch.discovery.intermediateSessions,
    stytch.magicLinks,
    stytch.oauth.discovery,
    stytch.organization,
    stytch.sso,
    token,
    tokenType,
  ]);

  useEffect(() => {
    void authenticate();
  }, [authenticate]);

  return <ZaniaLogoLoader />;
};

export default Authenticate;
