import { ROUTES } from "@/shared/constants/routes";
import { importLazy } from "@/shared/utils/import-lazy";
import { RouteObject } from "react-router-dom";
import SettingsLayout from "../components/layout";
import SettingsMainPage from "../components/main";

export const SettingsRoutes: RouteObject[] = [
  {
    path: ROUTES.SETTINGS,
    // lazy: importLazy(() => import("@/modules/settings/components/layout")),
    Component: SettingsLayout,
    children: [
      {
        index: true,
        // lazy: importLazy(() => import("@/modules/settings/components/main")),
        Component: SettingsMainPage
      },
    ],
  },
];
