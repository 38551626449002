import { StytchClientType } from "@/shared/types/stytch";

export const signInWithMicrosoftOauth = async ({
  stytch,
}: {
  stytch: StytchClientType;
}) => {
  try {
    await stytch.oauth.microsoft.discovery.start({
      discovery_redirect_url: `${window.location.origin}/auth`,
    });
  } catch (error) {
    throw new Error((error as Error).message);
  }
};
