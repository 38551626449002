import {
  CellSelected,
  ColumnSelected,
  ExcelItemTypes,
  MultiCellDragSelected,
  MultiCellSelected,
  MultiColumnSelected,
  RowSelected,
  Selection,
} from "../types/excel-mapper";

export const isSameSelection = (current: Selection, next: Selection) => {
  if (current.type !== next.type) return false;

  switch (current.type) {
    case ExcelItemTypes.COLUMN:
      return current.column === (next as ColumnSelected).column;
    case ExcelItemTypes.ROW:
      return current.row === (next as RowSelected).row;
    case ExcelItemTypes.CELL:
      return current.cellId === (next as CellSelected).cellId;
    case ExcelItemTypes.MULTI_CELL:
      return (
        current.cells.length === (next as MultiCellSelected).cells.length &&
        current.cells.every(
          (cell, idx) => cell === (next as MultiCellSelected).cells[idx],
        )
      );
    case ExcelItemTypes.MULTI_CELL_DRAG:
      return (
        current.start.rowIndex ===
          (next as MultiCellDragSelected).start.rowIndex &&
        current.start.columnIndex ===
          (next as MultiCellDragSelected).start.columnIndex &&
        current.end.rowIndex === (next as MultiCellDragSelected).end.rowIndex &&
        current.end.columnIndex ===
          (next as MultiCellDragSelected).end.columnIndex
      );
    case ExcelItemTypes.MULTI_COLUMN:
      return (
        current.columns.length ===
          (next as MultiColumnSelected).columns.length &&
        current.columns.every(
          (col, idx) => col === (next as MultiColumnSelected).columns[idx],
        )
      );
    default:
      return false;
  }
};

export function getNewSelection(
  type: ExcelItemTypes,
  selectionId: string | null,
  column: string | null,
  selectionTypes: Set<string>,
  selection: Selection | undefined,
): Selection | undefined {
  switch (type) {
    case ExcelItemTypes.COLUMN:
      if (!isSelectionAllowed(ExcelItemTypes.COLUMN, selectionTypes)) return;
      return column
        ? { type: ExcelItemTypes.COLUMN, column: +column }
        : undefined;
    case ExcelItemTypes.ROW:
      if (!isSelectionAllowed(ExcelItemTypes.ROW, selectionTypes)) return;
      return selectionId
        ? { type: ExcelItemTypes.ROW, row: +selectionId }
        : undefined;
    case ExcelItemTypes.CELL:
      return selectionId
        ? isSelectionAllowed(ExcelItemTypes.MULTI_CELL, selectionTypes)
          ? {
              type: ExcelItemTypes.MULTI_CELL,
              cells: [
                ...(selection && selection.type === ExcelItemTypes.MULTI_CELL
                  ? selection.cells
                  : []),
                selectionId,
              ],
            }
          : isSelectionAllowed(ExcelItemTypes.CELL, selectionTypes)
            ? { type: ExcelItemTypes.CELL, cellId: selectionId }
            : undefined
        : undefined;
    case ExcelItemTypes.MULTI_COLUMN:
      return column
        ? isSelectionAllowed(ExcelItemTypes.MULTI_COLUMN, selectionTypes)
          ? {
              type: ExcelItemTypes.MULTI_COLUMN,
              columns: [
                ...(selection && selection.type === ExcelItemTypes.MULTI_COLUMN
                  ? selection.columns
                  : []),
                +column,
              ],
            }
          : undefined
        : undefined;
    default:
      return undefined;
  }
}

export const isSelectionAllowed = (
  type: ExcelItemTypes,
  selectionTypes: Set<string>,
) => {
  return selectionTypes.has("all") || selectionTypes.has(type);
};
