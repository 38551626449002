import { cn } from "@/shared/utils/classname-merger";

export const LoadingSpinner = ({
  containerClassName,
  className,
  variant = "default",
}: {
  containerClassName?: string;
  className?: string;
  variant?: "default" | "centered" | "inline";
}) => {
  const containerStyles = {
    default: "flex justify-center items-center",
    centered: "flex justify-center items-center w-full h-full",
    inline: "inline-flex items-center justify-center",
  };

  return (
    <div className={cn(containerStyles[variant], containerClassName)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={cn("animate-spin", className)}
      >
        <path d="M21 12a9 9 0 1 1-6.219-8.56" />
      </svg>
    </div>
  );
};
