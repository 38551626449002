import { useEffect, useRef, useState } from 'react';
import { useControlEvents } from '@/modules/agent/hooks/useControlEvents';
import { useLoggedInMember } from '@/shared/hooks/use-login-member';
import UserAvatarGroup from '@/shared/components/avatar-group/userAvatarGroup';

// global state to track currently active control
let activeControlId: string | null = null;

export const withControlTracking = (WrappedComponent: React.ComponentType<any>) => {
  return function WithControlTrackingComponent(props: any) {
    const { sessionId, controlId, title } = props;
    const loggedInUser = useLoggedInMember();
    const controlRef = useRef<HTMLDivElement>(null);
    const [isActive, setIsActive] = useState(false);
    
    const { emitControlOpened, emitControlClosed } = useControlEvents(
      sessionId,
      controlId,
      loggedInUser?.member_id
    );

    useEffect(() => {
      // set initial active state based on global activeControlId
      setIsActive(activeControlId === controlId);

      const handleClickOutside = (event: MouseEvent) => {
        if (controlRef.current && 
            !controlRef.current.contains(event.target as Node) && 
            isActive && 
            activeControlId === controlId) {
          setIsActive(false);
          activeControlId = null;
          emitControlClosed();
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        if (isActive && activeControlId === controlId) {
          activeControlId = null;
          emitControlClosed();
        }
      };
    }, [emitControlClosed, isActive, controlId]);

    const handleControlClick = () => {
      if (!isActive) {
        // close previous active control
        if (activeControlId && activeControlId !== controlId) {
          emitControlClosed();
        }
        
        // set new active control
        activeControlId = controlId;
        setIsActive(true);
        emitControlOpened();
      }
    };

    const enhancedHeader = (
      <div className="flex justify-between items-center w-full">
        <p className="self-start">{title}</p>
        <UserAvatarGroup sessionId={sessionId} control={controlId} />
      </div>
    );

    return (
      // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
      <div ref={controlRef} onClick={handleControlClick}>
        <WrappedComponent {...props} enhancedHeader={enhancedHeader} />
      </div>
    );
  };
}; 