import { useEffect, useCallback } from "react";
import { useStytchMember } from "@stytch/react/b2b";
import { getZaniaSocket } from "@/infra/sockets";
import { getSessionJWT } from "@/infra/stytch";
import { addNotification } from "@/shared/states/notification";
import { useControlViewersStore } from "../states/control-viewers";
export const useSessionSubscription = (sessionId: string) => {
  const { member: loggedInUser } = useStytchMember();
  const emitSessionOpened = useCallback(() => {
    try {
      const EVENT_ID = "shared:doc:opened";
      const socket = getZaniaSocket();
      
      if (!loggedInUser?.member_id) {
        console.warn('No user ID available');
        return;
      }
      const payload = {
        agent_session_id: sessionId,
        control_id: "None",
        token: getSessionJWT(),
      };
      
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        socket.emit(EVENT_ID, payload, (response: any) => {
        if (response?.error) {
          console.error('Error emitting session opened:', response.error);
          addNotification({
            type: 'error',
            message: 'Failed to sync session status'
          });
        }
      });
    } catch (error) {
      console.error('Error in emitSessionOpened:', error);
    }
  }, [sessionId, loggedInUser?.member_id]);
  useEffect(() => {
    if (loggedInUser?.member_id) {
      emitSessionOpened();
    }
  }, [emitSessionOpened, loggedInUser?.member_id]);
};