import { ZInput } from "@/shared/components/input";
import { cn } from "@/shared/utils/classname-merger";
import {
  InputProps,
  Textarea,
  TextAreaProps
} from "@nextui-org/react";
import { useEffect, useMemo, useRef, useState } from "react";

type ZTextAreaWithOnChangeDelayProps = TextAreaProps & {
  forceActive?: boolean;
  strikeContent?: boolean;
};

export const ZTextAreaWithOnChangeDelay = (
  props: ZTextAreaWithOnChangeDelayProps
) => {
  const [value, setValue] = useState(props.value ?? "");
  const [isFocus, setIsFocus] = useState(false);

  const isActive = useMemo(() => {
    return isFocus || (props.forceActive ?? false);
  }, [isFocus, props.forceActive]);

  useEffect(() => {
    if (props.value !== undefined) setValue(props.value);
  }, [props.value]);

  const timerref = useRef<NodeJS.Timeout | null>(null);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.value.trimStart() !== value) {
      setValue(e.target.value);
      if (timerref.current) {
        clearTimeout(timerref.current);
      }
      timerref.current = setTimeout(() => {
        props.onChange?.(e);
      }, 500);
    }
  };

  return props.strikeContent ? (
    <s>
      <Textarea
        {...props}
        className={cn(
          props.className ?? "",
          "placeholder:text-[#A5A5A5]",
          isActive && "bg-white data-[hover=true]:bg-white"
        )}
        classNames={{
          ...(props.classNames ?? {}),
          base: cn(
            props.classNames?.base ?? "",
            isActive && "bg-white data-[hover=true]:bg-white "
          ),
          inputWrapper: cn(
            props.classNames?.inputWrapper ?? "",
            isActive && "bg-white data-[hover=true]:bg-white p-0 "
          ),
          clearButton: cn(
            props.classNames?.clearButton ?? "",
            isActive && "bg-white data-[hover=true]:bg-white "
          ),
          description: cn(
            props.classNames?.description ?? "",
            isActive && "bg-white data-[hover=true]:bg-white "
          ),
          errorMessage: cn(
            props.classNames?.errorMessage ?? "",
            isActive && "bg-white data-[hover=true]:bg-white "
          ),
          helperWrapper: cn(
            props.classNames?.helperWrapper ?? "",
            isActive && "bg-white data-[hover=true]:bg-white "
          ),
          innerWrapper: cn(
            props.classNames?.innerWrapper ?? "",
            isActive &&
            "bg-white data-[hover=true]:bg-white  rounded-[4px] border-1 border-[#A5A5A5] ",
            props.forceActive && "border-b-0 rounded-b-none"
          ),
          input: cn(
            props.classNames?.input ?? "",
            "text-zinc-700",
            "placeholder:text-zinc-400",
            isActive && "bg-white data-[hover=true]:bg-white   text-zinc-900"
          ),
          label: cn(
            props.classNames?.label ?? "",
            isActive && "bg-white data-[hover=true]:bg-white  "
          ),
          mainWrapper: cn(
            props.classNames?.mainWrapper ?? "",
            isActive && "bg-white data-[hover=true]:bg-white  "
          ),
        }}
        value={value}
        onChange={handleChange}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        onMouseDown={(e) => {
          const textarea = e.target as HTMLTextAreaElement;
          const clickPosition = textarea.selectionStart;
          textarea.focus();
          textarea.setSelectionRange(clickPosition, clickPosition);
        }}
      />
    </s>
  ) : (
    <Textarea
      {...props}
      className={cn(
        props.className ?? "",
        "placeholder:text-[#A5A5A5]",
        isActive && "bg-white data-[hover=true]:bg-white  "
      )}
      classNames={{
        ...(props.classNames ?? {}),
        base: cn(
          props.classNames?.base ?? "",
          isActive && "bg-white data-[hover=true]:bg-white  "
        ),
        inputWrapper: cn(
          props.classNames?.inputWrapper ?? "",
          isActive && "bg-white data-[hover=true]:bg-white p-0  "
        ),
        clearButton: cn(
          props.classNames?.clearButton ?? "",
          isActive && "bg-white data-[hover=true]:bg-white  "
        ),
        description: cn(
          props.classNames?.description ?? "",
          isActive && "bg-white data-[hover=true]:bg-white  "
        ),
        errorMessage: cn(
          props.classNames?.errorMessage ?? "",
          isActive && "bg-white data-[hover=true]:bg-white  "
        ),
        helperWrapper: cn(
          props.classNames?.helperWrapper ?? "",
          isActive && "bg-white data-[hover=true]:bg-white  "
        ),
        innerWrapper: cn(
          props.classNames?.innerWrapper ?? "",
          isActive &&
          "bg-white data-[hover=true]:bg-white  rounded-[4px] border-1 border-[#A5A5A5]",
          props.forceActive &&
          props.strikeContent !== undefined &&
          "border-t-0 rounded-t-none"
        ),
        input: cn(
          props.classNames?.input ?? "",
          "text-zinc-700",
          "placeholder:text-zinc-400",
          isActive && "bg-white data-[hover=true]:bg-white   text-zinc-900"
        ),
        label: cn(
          props.classNames?.label ?? "",
          isActive && "bg-white data-[hover=true]:bg-white  "
        ),
        mainWrapper: cn(
          props.classNames?.mainWrapper ?? "",
          isActive && "bg-white data-[hover=true]:bg-white  "
        ),
      }}
      value={value}
      onChange={handleChange}
      onFocus={() => setIsFocus(true)}
      onBlur={() => setIsFocus(false)}
      onMouseDown={(e) => {
        const textarea = e.target as HTMLTextAreaElement;
        const clickPosition = textarea.selectionStart;
        textarea.focus();
        textarea.setSelectionRange(clickPosition, clickPosition);
      }}
    />
  );
};

export const ZInputWithOnChangeDelay = (
  props: InputProps & {
    inputValidation?: (newValue: string) => boolean;
  }
) => {
  const [value, setValue] = useState(props.value ?? "");
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (props.value !== undefined) setValue(props.value);
  }, [props.value]);

  const timerref = useRef<NodeJS.Timeout | null>(null);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.value.trimStart() !== value) {
      if (
        props.inputValidation === undefined ||
        props.inputValidation(e.target.value)
      ) {
        setValue(e.target.value);
        if (timerref.current) {
          clearTimeout(timerref.current);
        }
        timerref.current = setTimeout(() => {
          props.onChange?.(e);
        }, 500);
      }
    }
  };

  return (
    <ZInput
      {...props}
      className={cn(
        props.className ?? "",
        "placeholder:text-zinc-400",
        props.inputValidation &&
        isActive &&
        "bg-white data-[hover=true]:bg-white rounded-[4px] border-1 border-[#A5A5A5]"
      )}
      classNames={{
        ...(props.classNames ?? {}),
        base: cn(
          props.classNames?.base ?? "",
          "placeholder:text-zinc-400",
          props.inputValidation &&
          isActive &&
          "bg-white data-[hover=true]:bg-white rounded-[4px] border-1 border-[#A5A5A5] "
        ),
        inputWrapper: cn(
          props.classNames?.inputWrapper ?? "",
          props.inputValidation &&
          isActive &&
          "bg-white data-[hover=true]:bg-white p-0 rounded-[4px] border-1 border-[#A5A5A5] "
        ),
        clearButton: cn(
          props.classNames?.clearButton ?? "",
          props.inputValidation &&
          isActive &&
          "bg-white data-[hover=true]:bg-white rounded-[4px] border-1 border-[#A5A5A5]"
        ),
        description: cn(
          props.classNames?.description ?? "",
          props.inputValidation &&
          isActive &&
          "bg-white data-[hover=true]:bg-white rounded-[4px] border-1 border-[#A5A5A5]"
        ),
        errorMessage: cn(
          props.classNames?.errorMessage ?? "",
          props.inputValidation &&
          isActive &&
          "bg-white data-[hover=true]:bg-white rounded-[4px] "
        ),
        helperWrapper: cn(
          props.classNames?.helperWrapper ?? "",
          props.inputValidation &&
          isActive &&
          "bg-white data-[hover=true]:bg-white rounded-[4px] "
        ),
        innerWrapper: cn(
          props.classNames?.innerWrapper ?? "",
          props.inputValidation &&
          isActive &&
          "bg-white data-[hover=true]:bg-white  rounded-[4px] border-1 border-[#A5A5A5] "
        ),
        input: cn(
          props.classNames?.input ?? "",
          "placeholder:text-zinc-400",
          props.inputValidation &&
          isActive &&
          "bg-white data-[hover=true]:bg-white rounded-[4px]  p-[5px] border-1 border-[#A5A5A5]"
        ),
        label: cn(
          props.classNames?.label ?? "",
          props.inputValidation &&
          isActive &&
          "bg-white data-[hover=true]:bg-white rounded-[4px] "
        ),
        mainWrapper: cn(
          props.classNames?.mainWrapper ?? "",
          props.inputValidation &&
          isActive &&
          "bg-white data-[hover=true]:bg-white rounded-[4px] border-1 border-[#A5A5A5] border-solid"
        ),
      }}
      startContent={
        props.startContent ? (
          <div className={cn("text-zinc-400", value && "text-zinc-800")}>
            {props.startContent}
          </div>
        ) : undefined
      }
      value={value}
      onChange={handleChange}
      onFocus={() => setIsActive(true)}
      onBlur={() => setIsActive(false)}
      autoFocus={false}
      onMouseDown={(e) => {
        const textarea = e.target as HTMLTextAreaElement;
        const clickPosition = textarea.selectionStart;
        textarea.focus();
        textarea.setSelectionRange(clickPosition, clickPosition);
      }}
    />
  );
};

export const getRandomCharacter = () => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"; // Set of characters
  const randomIndex = Math.floor(Math.random() * characters.length); // Generate a random index
  return characters[randomIndex]; // Return character at the random index
};
