import { StytchB2BUIClient } from "@stytch/vanilla-js/dist/b2b";
import { useCallback, useEffect, useRef, useState } from "react";
import { Socket } from "socket.io-client";
import appConfig from "../config/app";
import { useSocketActions } from "../states/sockets";

const useSocket = (
  socketName: string,
  getSocket: () => Socket,
  onReconnect?: (lastUpdatedTimestamp: number) => void,
) => {
  const [socket, setSocket] = useState<Socket | null>(null);

  const { addSocket, editSocket } = useSocketActions();

  const isConnectedRef = useRef(false);
  const lastUpdatedTimestampRef = useRef(new Date().getTime());
  const onReconnectRef = useRef(onReconnect);

  onReconnectRef.current = onReconnect;

  const addListener = useCallback(
    <T>(eventName: string, listener: (payload: T) => void) => {
      if (!socket) {
        return;
      }
      socket.on(eventName, listener);
    },
    [socket],
  );

  const removeListener = useCallback(
    <T>(eventName: string, listener: (payload: T) => void) => {
      if (!socket) {
        return;
      }
      socket.off(eventName, listener);
    },
    [socket],
  );

  const emit = useCallback(
    <T>(eventName: string, data: T) => {
      if (!socket) {
        return;
      }
      socket.emit(eventName, data, (response: any) => {});
    },
    [socket],
  );

  useEffect(() => {
    function setSocketWrapper() {
      setSocket(getSocket());
    }
    setSocketWrapper(); // react does not allow us to use useEffect with async functions
    if (!socket) return;
    addSocket(socketName, {
      isConnected: socket.connected,
      socket,
      lastUpdatedTimestamp: lastUpdatedTimestampRef.current,
    });
  }, [socket, addSocket, setSocket, getSocket, socketName]);

  useEffect(() => {
    if (!socket) return;
    const onConnect = () => {
      isConnectedRef.current = true;
      editSocket(socketName, {
        isConnected: true,
        isErrored: false,
      });
    };

    const onDisconnect = () => {
      if (!socket) {
        return;
      }
      if (navigator.onLine) {
        // multiple reason for socket to fail but to make sure lets create a new websocket connect with new JWT token as well.
        // calling stytch client to make sure we have an active session before getting a socket connection
        const stytch = new StytchB2BUIClient(appConfig.stytchKey);
        const sessionInfo = stytch.session.getInfo();

        if (sessionInfo.session) {
          const newSocket = getSocket();
          setSocket(newSocket);
        }

        return;
      }
      isConnectedRef.current = false;
      lastUpdatedTimestampRef.current = new Date().getTime();
      editSocket(socketName, {
        isConnected: false,
        lastUpdatedTimestamp: lastUpdatedTimestampRef.current,
      });
      socket.disconnect();
    };

    const onError = () => {
      if (!socket) {
        return;
      }
      if (socket.connected) {
        return;
      } else {
        isConnectedRef.current = false;
        editSocket(socketName, {
          isConnected: false,
          isErrored: true,
        });
      }
    };

    const onReconnectFailed = () => {
      isConnectedRef.current = false;
      editSocket(socketName, {
        isConnected: false,
        isErrored: true,
      });
    };

    socket.connect();
    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("connect_error", onError);
    socket.on("reconnect_error", onReconnectFailed);

    return () => {
      socket.disconnect();
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("connect_error", onError);
      socket.off("reconnect_error", onReconnectFailed);
    };
  }, [editSocket, getSocket, socket, socketName]);

  useEffect(() => {
    const reConnect = () => {
      if (!socket || isConnectedRef.current) return;

      socket.disconnect();
      socket.connect();

      onReconnectRef.current?.(lastUpdatedTimestampRef.current);
    };

    window.addEventListener("focus", reConnect);
    window.addEventListener("online", reConnect);

    return () => {
      window.removeEventListener("focus", reConnect);
      window.removeEventListener("online", reConnect);
    };
  }, [socket]);

  return {
    socket,
    addListener,
    removeListener,
    emit,
    isConnected: isConnectedRef.current,
  };
};

export default useSocket;
