import { cn } from "@/shared/utils/classname-merger";
import { useCallback, useState } from "react";
import { Outlet } from "react-router-dom";
import { useAppSocket } from "../../hooks/use-app-socket";
import Sidebar from "../sidebar";

const MainLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(() => {
    const local = localStorage.getItem("sidebar");
    return local ? !!local : true;
  });

  useAppSocket();

  const toggleSidebar = useCallback(() => {
    localStorage.setItem("sidebar", JSON.stringify(!isSidebarOpen));
    setIsSidebarOpen(!isSidebarOpen);
  }, [isSidebarOpen]);

  return (
    <main className="h-screen relative w-screen flex" style={{}}>
      <section
        className={cn(
          isSidebarOpen ? "flex-[15]" : "flex-[5]",
          "transition-all duration-300 ease-in-out",
        )}
      >
        <Sidebar isOpen={isSidebarOpen} onToggle={toggleSidebar} />
      </section>
      <section
        className={cn(
          isSidebarOpen ? "flex-[85]" : "flex-[95]",
          "overflow-hidden overflow-y-auto p-4 ",
        )}
      >
        <div className="overflow-hidden rounded-lg border-none w-full h-full">
          <Outlet />
        </div>
      </section>
    </main>
  );
};

export default MainLayout;
