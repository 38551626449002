import { Spinner } from "@nextui-org/react";
import { Sparkle } from "lucide-react";
import { useState } from "react";

export interface GenerateWithAIProps {
  contextText: string;
  existingText: string;
  handleClickForRefineControls: () => Promise<void>;
  isLoadingAIResponse: boolean;
}

const GenerateWithAI = ({
  contextText,
  existingText,
  handleClickForRefineControls,
  isLoadingAIResponse,
}: GenerateWithAIProps) => {
  // const [showAcceptAndRejectPopover, setShowAcceptAndRejectPopover] = useState(
  //   false
  // );
  // const [isError, setIsError] = useState(false);

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();

    handleClickForRefineControls();
  };

  return isLoadingAIResponse ? (
    <div>
      <Spinner size="sm" />
    </div>
  ) : (
    <div>
      {existingText ? (
        <div
          className="cursor-pointer flex items-center gap-2"
          onClick={handleClick}
          onMouseDown={handleClick}
          // onKeyDown={handleClick}
        >
          <div>
            <Sparkle size={12} color="#006FEE" />
          </div>
          <div className="text-[12px] leading-5 text-[#006FEE] font-medium">
            Refine with AI
          </div>
        </div>
      ) : (
        <div
          className="cursor-pointer flex items-center gap-2"
          onClick={handleClick}
          onMouseDown={handleClick}
        >
          <div>
            <Sparkle size={12} color="#006FEE" />
          </div>
          <div className="text-[12px] leading-5 text-[#006FEE] font-medium">
            Generate With AI
          </div>
        </div>
      )}
    </div>
  );
};

export default GenerateWithAI;
