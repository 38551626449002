import { WorkBook } from "xlsx";

export const createJSONForExcelMapper = async (
  file: File,
): Promise<{
  data: string[][];
  merges: { s: { r: number; c: number }; e: { r: number; c: number } }[];
}> => {
  const { read, utils } = await import("xlsx");
  const resultPromise = new Promise<{
    data: string[][];
    merges: { s: { r: number; c: number }; e: { r: number; c: number } }[];
  }>((resolve, reject) => {
    const fileReader = new FileReader();
    const isCsv = file.type === "text/csv";

    fileReader.onload = (e) => {
      const content = e.target?.result;

      if (!content) {
        reject("Error reading file");
        return;
      }

      if (isCsv && typeof content === "string") {
        const rows = content.split("\n");
        const data = rows.map((row) => row.split(","));
        resolve({ data, merges: [] });
      } else {
        const workbook = read(content, { type: "binary" });
        const sheetNames = filterVisibleSheets(workbook);
        const sheetName = sheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const data: string[][] = utils.sheet_to_json(sheet, {
          header: 1,
          blankrows: false,
          defval: "",
        });
        const merges = sheet["!merges"] ?? [];
        resolve({ data: data.slice(0, 50), merges });
      }
    };

    fileReader.onerror = () => reject("Error reading file");

    if (isCsv) {
      fileReader.readAsText(file);
    } else {
      fileReader.readAsArrayBuffer(file);
    }
  });

  return await resultPromise;
};

export const getFileContent = async (
  file: File,
): Promise<{
  content: string | ArrayBuffer;
  isCsv: boolean;
}> => {
  const resultPromise = new Promise<{
    content: string | ArrayBuffer;
    isCsv: boolean;
  }>((resolve, reject) => {
    const fileReader = new FileReader();
    const isCsv = file.type === "text/csv";

    fileReader.onload = (e) => {
      const content = e.target?.result;

      if (!content) {
        reject("Error reading file");
        return;
      }

      resolve({
        content,
        isCsv,
      });
    };

    fileReader.onerror = () => reject("Error reading file");

    if (isCsv) {
      fileReader.readAsText(file);
    } else {
      fileReader.readAsArrayBuffer(file);
    }
  });

  return await resultPromise;
};

export const getSheetData = async (content: string | ArrayBuffer) => {
  const { read } = await import("xlsx");
  const resultPromise = new Promise<{
    sheetNames: string[];
    workbook: WorkBook;
  }>((resolve, reject) => {
    const workbook = read(content, { type: "binary" });

    if (!workbook) {
      reject("Error reading file");
      return;
    }

    const sheetNames = filterVisibleSheets(workbook);
    resolve({
      sheetNames,
      workbook,
    });
  });
  return resultPromise;
};

export const getSheetDataFromUrl = async (url: string) => {
  const { read } = await import("xlsx");
  const resultPromise = new Promise<{
    sheetNames: string[];
    workbook: WorkBook;
  }>((resolve, reject) => {
    void fetch(url)
      .then((res) => res.arrayBuffer())
      .then((buffer) => {
        const workbook = read(buffer, { type: "binary" });

        if (!workbook) {
          reject("Error reading file");
          return;
        }
        const sheetNames = filterVisibleSheets(workbook);

        resolve({
          sheetNames,
          workbook,
        });
      });
  });
  return resultPromise;
};

export const getJSONFromSheet = async (
  workbook: WorkBook,
  sheetName: string,
) => {
  const { utils } = await import("xlsx");

  const resultPromise = new Promise<{
    data: string[][];
    merges: { s: { r: number; c: number }; e: { r: number; c: number } }[];
  }>((resolve) => {
    const sheet = workbook.Sheets[sheetName];
    const data: string[][] = utils.sheet_to_json(sheet, {
      header: 1,
      blankrows: true,
      defval: "",
    });
    const merges = sheet["!merges"] ?? [];
    resolve({ data: data, merges });
  });
  return resultPromise;
};

const filterVisibleSheets = (workbook: WorkBook): string[] => {
  if (!workbook.Workbook || !workbook.Workbook.Sheets) {
    return workbook.SheetNames;
  }

  return workbook.SheetNames.map((sheetName, index) => {
    const sheetVisibility = workbook.Workbook?.Sheets?.[index]?.Hidden;
    if (sheetVisibility === 1 || sheetVisibility === 2) {
      return `${sheetName}::hidden`;
    }
    return sheetName;
  });
};
