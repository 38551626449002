import { ZButton } from "@/shared/components/button";
import InProgressIcon from "@/shared/icons/inprogress";
import { cn } from "@/shared/utils/classname-merger";
import { Button, Tooltip } from "@nextui-org/react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { sidebarItems } from "../../constants/sidebar";
import { useAgentLogState } from "../../states/agentLog";
import { NavItemData } from "../../types/sidebar";
import { AgentConsoleNavigation } from "../../types/sidebar";

const aiAgent = "AI Agent";

interface NavProps {
  isCollapsed: boolean;
}

export const NavigationMenuItem = ({
  data,
  isCollapsed,
}: {
  data: NavItemData;
  isCollapsed: boolean;
}) => {
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const { title, Icon, path } = data;
  const isActive =
    pathname === "/" && path === "/" ? true : pathname.includes(path);
  const isAgenLogOpen = useAgentLogState.useIsOpen();
  const setOpenAgentLog = useAgentLogState.useActions().setIsOpen;

  if (isCollapsed) {
    return (
      <Tooltip content={title} placement="right">
        <Button
          onClick={() => navigate(path)}
          isIconOnly
          className={cn(
            "h-fit w-fit font-normal text-[#7A788E] bg-transparent rounded-[12px]  px-[16px] py-[10px]",
            isActive &&
              "bg-[#EEE1EC80] bg-opacity-50 text-[#11181C] font-semibold dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white",
          )}
        >
          <div className="flex flex-col items-center gap-y-[10px]">
            <div className="flex flex-row items-center gap-x-[10px]">
              <Icon
                color={isActive ? "#11181C" : "#7A788E"}
                className="min-h-[20px] min-w-[20px]  h-[20px]  w-[20px]"
              />
            </div>

            {(pathname.includes(AgentConsoleNavigation.riskAssessment) ||
              pathname.includes(AgentConsoleNavigation.doraAssessment) ||
              pathname.includes(AgentConsoleNavigation.questionnaire)) &&
              title === aiAgent && (
                <div
                  className={`px-[10px] py-[9px] rounded-[8px] bg-white w-full flex gap-x-[10px] `}
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenAgentLog(!isAgenLogOpen);
                  }}
                >
                  <motion.div
                    className="m-0 p-0 flex justify-center items-center"
                    animate={{
                      rotate: [0, 180, 180, 360, 360],
                    }}
                    transition={{
                      repeat: Number.POSITIVE_INFINITY,
                      repeatType: "loop",
                      duration: 4,
                      times: [0, 0.5, 0.75, 1],
                    }}
                  >
                    <InProgressIcon
                      className="w-[18px] h-[18px]"
                      color={"#1677FF"}
                    />
                  </motion.div>
                </div>
              )}
          </div>
        </Button>
      </Tooltip>
    );
  }

  return (
    <ZButton
      className={cn(
        "justify-start h-fit font-normal text-[#7A788E] bg-transparent rounded-[12px] px-[16px] py-[10px]",
        isActive && "bg-[#EEE1EC80] bg-opacity-50 text-[#11181C] font-semibold",
      )}
      onClick={() => navigate(path)}
    >
      <div className="flex flex-col gap-y-[8px] w-full  gap-x-[10px]">
        <div className="flex flex-row items-center gap-x-[10px]">
          <Icon
            color={isActive ? "#11181C" : "#7A788E"}
            className="min-h-[20px] min-w-[20px]  h-[20px]  w-[20px]"
          />
          <p className="text-[14px] leading-6">{title}</p>
        </div>

        {(pathname.includes(AgentConsoleNavigation.riskAssessment) ||
          pathname.includes(AgentConsoleNavigation.doraAssessment) ||
          pathname.includes(AgentConsoleNavigation.questionnaire)) &&
          title === aiAgent && (
            <div
              className={`px-[10px] py-[9px] rounded-[8px] bg-white w-full flex gap-x-[10px] `}
              onClick={(e) => {
                e.stopPropagation();
                setOpenAgentLog(!isAgenLogOpen);
              }}
            >
              <motion.div
                className="m-0 p-0 flex justify-center items-center"
                animate={{
                  rotate: [0, 180, 180, 360, 360],
                }}
                transition={{
                  repeat: Number.POSITIVE_INFINITY,
                  repeatType: "loop",
                  duration: 4,
                  times: [0, 0.5, 0.75, 1],
                }}
              >
                <InProgressIcon
                  className="w-[18px] h-[18px]"
                  color={"#1677FF"}
                />
              </motion.div>
              <p className="text-[#1677FF] text-[14px] ">In Progress</p>
            </div>
          )}
      </div>
    </ZButton>
  );
};

export default function NavigationMenu({ isCollapsed }: NavProps) {
  return (
    <div
      data-collapsed={false}
      className="group flex flex-col gap-4 py-2 data-[collapsed=true]:py-2"
    >
      <nav
        className={cn(
          `gap-2 p-[16px] `,
          isCollapsed
            ? "flex flex-col items-center justify-center gap-3"
            : "grid",
        )}
      >
        {sidebarItems.map((item, index) => (
          <NavigationMenuItem
            key={index}
            data={item}
            isCollapsed={isCollapsed}
          />
        ))}
      </nav>
    </div>
  );
}
