import { SVGProps } from "react";

const ShieldQuestionMarkIcon = (props: SVGProps<SVGSVGElement>) => {
  const { color, ...rest } = props;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M10.2783 8.63401C10.448 8.26401 10.7362 7.96114 11.0974 7.77333C11.4585 7.58552 11.872 7.52347 12.2723 7.59701C12.5466 7.63903 12.8071 7.74478 13.0331 7.90578C13.259 8.06677 13.4441 8.27852 13.5733 8.52401C13.6662 8.7204 13.7165 8.93421 13.721 9.15142C13.7255 9.36862 13.6841 9.58433 13.5994 9.78439C13.5147 9.98446 13.3887 10.1644 13.2296 10.3123C13.0705 10.4603 12.882 10.573 12.6763 10.643C12.4664 10.7227 12.2847 10.8627 12.1543 11.0455C12.0238 11.2283 11.9504 11.4456 11.9433 11.67V12.093"
        stroke={color ?? "#71717A"}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M11.9101 14.4331H11.9151M20.6721 11.8901V6.61005C20.6724 6.20486 20.545 5.80988 20.3081 5.48119C20.0711 5.15251 19.7366 4.90683 19.3521 4.77905L14.4381 3.14005C12.8545 2.61933 11.1458 2.61933 9.56213 3.14005L4.64813 4.77805C4.26369 4.90565 3.92923 5.15114 3.69227 5.47965C3.45531 5.80816 3.32789 6.203 3.32813 6.60805V11.8881C3.32821 13.1914 3.65875 14.4735 4.28885 15.6144C4.91895 16.7553 5.82803 17.7179 6.93113 18.4121L10.9791 20.9561C11.2853 21.1473 11.6391 21.2488 12.0001 21.2488C12.3612 21.2488 12.7149 21.1473 13.0211 20.9561L17.0681 18.4121C18.1713 17.7181 19.0806 16.7558 19.7108 15.6151C20.3411 14.4743 20.6719 13.1924 20.6721 11.8891"
        stroke={color ?? "#71717A"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ShieldQuestionMarkIcon;
