import { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  getCustomizeControlsActions,
  useCustomizeControlsStore,
} from "../../states/cusomize-controls";
import { handleAutoSaveCustomizeControls } from "../../use-cases/customize-control.use-case";

const CustomizeControlsAutoSaveComponent = () => {
  const { id = "" } = useParams<{ id: string }>();

  const changesMade = useCustomizeControlsStore().changesMade;

  useEffect(() => {
    if (changesMade > 0) {
      const setIsSavingControls = getCustomizeControlsActions()
        .setIsSavingControls;

      setIsSavingControls("EDITED");

      const timer = setTimeout(() => {
        setIsSavingControls("SAVING");
        handleAutoSaveCustomizeControls(id)
          .then(() => {
            setIsSavingControls("SAVED");
          })
          .catch((err) => console.error(err));
      }, 500);
    }
  }, [changesMade, id]);

  return null;
};

export default CustomizeControlsAutoSaveComponent;
