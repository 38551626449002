import { SVGProps } from "react";

const DoubleTickIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      {...props}
    >
      <path
        d="M1 4.99982L3.21053 7L3.85726 6.41473M9.8421 1L6.01284 4.46473M4.15789 4.99982L6.36842 7L13 1"
        stroke="#006FEE"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DoubleTickIcon;
