import { ChevronLeft, ChevronRight } from "lucide-react";

import { Button, SelectItem, Select } from "@nextui-org/react";
import clsx from "clsx";

interface TablePaginationProps {
    currentPage: number;
    totalPages: number;
    rowsPerPage: number;
    onPageChange: (page: number) => void;
    onRowsPerPageChange: (pageSize: number) => void;
    isDisabled: boolean;
  }
  
  export const TablePagination = ({
    currentPage,
    totalPages,
    rowsPerPage,
    onPageChange,
    onRowsPerPageChange,
    isDisabled
  }: TablePaginationProps) => {
  
    const getVisiblePages = () => {
      const delta = 2; // Pages to show before and after current page
      const range: (number | string)[] = [];
      
      if (totalPages <= 5) {
        // If total pages is small, show all pages
        for (let i = 1; i <= totalPages; i++) {
          range.push(i);
        }
        return range;
      }

      // Always show first page
      range.push(1);

      if (currentPage <= 3) {
        // Near the start
        for (let i = 2; i <= 4; i++) {
          range.push(i);
        }
        range.push('...');
        range.push(totalPages);
      } else if (currentPage >= totalPages - 2) {
        // Near the end
        range.push('...');
        for (let i = totalPages - 3; i <= totalPages; i++) {
          if (i !== 1) range.push(i);
        }
      } else {
        // In the middle
        range.push('...');
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          range.push(i);
        }
        range.push('...');
        range.push(totalPages);
      }

      return range;
    };

    const visiblePages = getVisiblePages();

    // Add check for empty state
    const hasNoData = totalPages === 0 ;

    return (
      <div className="flex justify-between items-center px-5 py-3 border-t border-[#E4E4E7] bg-white sticky bottom-0 left-0 right-0">
        <div className="flex items-center gap-2 bg-[#F5F5F5] rounded-lg">
          <Button
            isIconOnly
            variant="light"
            size="sm"
            isDisabled={currentPage === 1}
            onClick={() => onPageChange(currentPage - 1)}
            className="min-w-[32px] "
          >
            <ChevronLeft className="h-4 w-4" />
          </Button>
  
          {visiblePages.map((page, index) => {
            if (page === '...') {
              return (
                <span key="ellipsis" className="px-2 text-gray-400">
                  ...
                </span>
              );
            }

            return (
              <Button
                key={page}
                size="sm"
                variant={currentPage === page ? "solid" : "light"}
                onClick={() => onPageChange(page as number)}
                isDisabled={isDisabled}
                className={clsx(
                  "min-w-[32px] bg-[#F5F5F5]",
                  currentPage === page && "bg-[#006FEE] text-white"
                )}
              >
                {page}
              </Button>
            );
          })}
  
          <Button
            isIconOnly
            variant="light"
            size="sm"
            isDisabled={currentPage >= totalPages || isDisabled}
            onClick={() => onPageChange(currentPage + 1)}
            className="min-w-[32px]"
          >
            <ChevronRight className="h-4 w-4" />
          </Button>
        </div>
  
        <div className="flex items-center gap-2 pr-2">
          <span className="text-sm text-[#333333]">Rows per page:</span>
          <Select
            size="sm"
            selectedKeys={[rowsPerPage.toString()]}
            onChange={(e) => onRowsPerPageChange(Number(e.target.value))}
            className="w-[75px]"
            isDisabled={isDisabled || hasNoData}
            classNames={{
              base: `rounded outline-none bg-[#F5F5F5] ${hasNoData ? "opacity-50" : ""}`,
              trigger: "rounded text-sm h-[32px] min-h-[32px] bg-[#F5F5F5] px-2",
              value: "text-[#333333] truncate text-ellipsis",
              popoverContent: "bg-white",
              innerWrapper: "pr-0",
              selectorIcon: "right-2",
            }}
            items={[
              { value: "25", label: "25" },
              { value: "50", label: "50" },
              { value: "100", label: "100" }
            ]}
            aria-label="Rows per page"
          >
            {(item) => (
              <SelectItem 
                key={item.value} 
                value={item.value} 
                className="text-[#171717] w-[70px]"
                isDisabled={rowsPerPage === Number(item.value) || isDisabled || hasNoData}
              >
                {item.label}
              </SelectItem>
            )}
          </Select>
        </div>
      </div>
    );
  };