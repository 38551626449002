import { DoraAgentSession, MultiVendorAgentSession, QaAgentSession, RiskAgentSession, SOC2GapAgentSession, SOC2Type1GapAgentSession, SOC2Type2NewGapAgentSession } from "@/modules/sessions/types";
import { ZButton } from "@/shared/components/button";
import { ZCard, ZCardBody } from "@/shared/components/card";
import FileUpload from "@/shared/components/custom/file-upload";
import { doesFileHasError } from "@/shared/components/custom/file-upload/helper";
import { ZTooltip } from "@/shared/components/ZTooltip/ZTooltip";
import { UploadedFile } from "@/shared/types/file-upload";
import {
  Accordion,
  AccordionItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Radio,
  RadioGroup
} from "@nextui-org/react";
import { FileSpreadsheetIcon, InfoIcon, Trash } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AGENT_TYPES, AgentData, AgentSourceFile, GapAssessmentTypes, MultiVendorAssessmentTypes, QuestionnaireTypes, RiskAssessmentTypes } from "../../types";
import { processDataBasedOnAssessment } from "../upload-dialog/util";

interface ReassessModalProps {
  agentData: AgentData<AGENT_TYPES, RiskAssessmentTypes | GapAssessmentTypes | QuestionnaireTypes | MultiVendorAssessmentTypes> | undefined
  isOpen: boolean;
  onClose: () => void;
  loading?: boolean;
  uniqueSources: AgentSourceFile[];
}

const ReassessModal = ({
  agentData,
  isOpen,
  onClose,
  loading,
  uniqueSources,
}: ReassessModalProps) => {

  const [currentStep, setCurrentStep] = useState(0);
  const [selectedReassesOption, setSelectedReassessOption] = useState<string>('REASSESS_ALL');
  const [isFileUploadError, setIsFileUploadError] = useState<boolean>(false);
  const [allFiles, setAllFiles] = useState<UploadedFile[]>([]);
  const [prevFiles, setPrevFiles] = useState<AgentSourceFile[]>([]);
  const navigate = useNavigate();
  const assessmentOptions = [
    {
      'id': 'assess_1',
      'value': 'REASSESS_ALL',
      'label': 'Reassess all controls',
      'tooltipText': 'This action will override all previous responses'
    },
    {
      'id': 'assess_2',
      'value': 'RETAIN_ACCEPTED',
      'label': 'Retain all responses that have been accepted, and reassess the remaining controls',
      'tooltipText': 'Responses already accepted will not be reassessed. Please keep them accurate and relevant to the new files'
    },
    {
      'id': 'assess_3',
      'value': 'RETAIN_EDITED_ACCEPTED',
      'label': 'Retain all responses that have been edited or accepted, and reassess the remaining controls',
      'tooltipText': 'Responses already accepted or edited will not be reassessed. Please keep them accurate and relevant to the new files'
    }
  ]

  const handleFileChange = (newFiles: UploadedFile[]) => {
    const isErrorInFilesUploaded =
      newFiles.filter((file) => doesFileHasError(file)).length > 0;

    setIsFileUploadError(isErrorInFilesUploaded);
    setAllFiles(newFiles);
    // updateSignedUrl(newFiles);
  };

  const getInputs = (agentSessionData: RiskAgentSession | DoraAgentSession | SOC2GapAgentSession | SOC2Type2NewGapAgentSession | SOC2Type1GapAgentSession | QaAgentSession | MultiVendorAgentSession | undefined) => {
    const inputs = {
      title: agentSessionData?.name || '',
      vendorName: (agentSessionData as MultiVendorAgentSession)?.multi_file_vendor_assessment?.vendor_name || '',
      scope: (agentSessionData as MultiVendorAgentSession)?.multi_file_vendor_assessment?.scope_of_integration || ''
    }
    return inputs;
  }

  const onProceed = (step: string) => {
    setCurrentStep(prev => prev + 1);
    if (step === 'finish') {
      const inputs = getInputs(agentData?.sessionData) as { [key: string]: string; };
      processDataBasedOnAssessment(agentData?.agentType, allFiles, navigate, inputs, agentData?.subType, false, selectedReassesOption, agentData, prevFiles);
    }
  }

  const onDiscardOrBack = () => {
    if (currentStep === 0) {
      onClose();
    } else {
      setCurrentStep(prev => prev - 1);
    }
  }

  const onReassessOptionClick = (option: string) => {
    setSelectedReassessOption(option);
  }


  const onRemoveFile = (fileName: string) => {
    let filteredSources = prevFiles.filter((source) => source.fileName !== fileName);
    setPrevFiles(filteredSources);
  }

  const handleOnClose = () => {
    setCurrentStep(0);
    onClose();
  }

  useEffect(() => {
    if (uniqueSources) {
      setPrevFiles(uniqueSources);
    }
  }, [uniqueSources])

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose} size="xl">
      <ModalContent>
        <ModalHeader className="self-center">
          <div className="flex flex-col items-center justify-center gap-2">
            <p className="text-[18px] font-semibold">
              Reassess Controls
            </p>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="flex flex-col gap-4 text-[14px]">
            <div className="step-container font-medium text-zinc-700">
              <div className="flex flex-col items-center gap-2">
                <div className="step">
                  <span>{currentStep === 0 ? 'Step 1 of 2: Update Files' : 'Step 2 of 2: Select Controls'}</span>
                </div>
                <div className="step-description text-center text-gray-500 font-light">
                  <span>{currentStep === 0 ? 'Add or remove files to adjust the list used for control assessment' : 'Select the controls you want to reassess with the updated files'}</span>
                </div>
              </div>
            </div>
            {currentStep === 0 &&
              <>
                <FileUpload
                  maxFiles={30}
                  orientation="vertical"
                  isMultiple={true}
                  isShowNewFilesSection={true}
                  allowedFileTypes={[
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    "application/vnd.ms-excel",
                    "application/json",
                    "application/pdf",
                    "application/msword",
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    "image/png",
                    "image/jpeg",
                    "image/jpg",
                    "text/csv",
                  ]}
                  setFiles={handleFileChange}
                />
                {prevFiles.length > 0 && <Accordion defaultExpandedKeys={['prevFiles']}>
                  <AccordionItem key="prevFiles" aria-label="previous files" title={<span className="text-[14px] font-normal text-zinc-900">Previously Added Files</span>}>
                    <div className="max-h-[120px] overflow-auto">
                      {prevFiles.map((source) => (
                        <div className="flex justify-between items-center gap-2 mt-0 pl-2" key={source.fileName}>
                          <div className="flex">
                            <FileSpreadsheetIcon
                              size={16}
                              color="#22c55e"
                            />
                            <div>
                              <p className="text-sm cursor-pointer hover:underline text-green-600">{source.fileName}</p>
                            </div>
                          </div>
                          <div>
                            <ZButton
                              isIconOnly
                              className="bg-transparent"
                              onClick={() => onRemoveFile(source.fileName || '')}
                            >
                              <Trash size={16} color="gray" />
                            </ZButton>
                          </div>
                        </div>
                      ))}
                    </div>
                  </AccordionItem>
                </Accordion>
                }
              </>
            }
            {currentStep > 0 &&
              <>
                <RadioGroup label="" defaultValue={selectedReassesOption} className="pt-4">
                  {
                    assessmentOptions.map((option) => {
                      return (
                        <ZCard key={option.id} shadow="none" radius="md" className="border border-solid">
                          <ZCardBody>
                            <Radio value={option.value} onClick={() => onReassessOptionClick(option.value)}>
                              <div className="radio-container pl-2">
                                <span className="label text-[14px] font-medium text-zinc-900">{option.label}
                                  <ZTooltip
                                    size="lg"
                                    content={option.tooltipText}
                                    placement="right"
                                    delay={0}
                                    isDisabled={false}
                                    showArrow={true}
                                  ><InfoIcon className="pl-2 inline" />
                                  </ZTooltip></span>
                              </div> </Radio>
                          </ZCardBody>
                        </ZCard>
                      )
                    })
                  }
                </RadioGroup>
              </>
            }
          </div>
        </ModalBody>
        <ModalFooter className="border-t">
          <div className="flex justify-between w-full">
            <ZButton
              size="sm"
              color="primary"
              variant="bordered"
              onClick={onDiscardOrBack}
            >
              <span className="font-normal font-[14px]">{currentStep === 0 ? 'Discard' : 'Back to Files'}</span>
            </ZButton>

            <ZButton
              size="sm"
              color="primary"
              isDisabled={isFileUploadError || (allFiles.length === 0 && prevFiles.length === 0)}
              onClick={() => onProceed(currentStep === 0 ? 'next' : 'finish')}
            >
              <span className="font-normal font-[14px]">{currentStep === 0 ? 'Configure Reassessment' : 'Start Reassessment'}</span>
            </ZButton>
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ReassessModal;
