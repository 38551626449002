import {
  Modal,
  ModalBody,
  ModalBodyProps,
  ModalContent,
  ModalContentProps,
  ModalFooter,
  ModalFooterProps,
  ModalHeader,
  ModalHeaderProps,
  ModalProps,
} from "@nextui-org/modal";

const ZModal = ({ children, ...props }: ModalProps) => {
  return <Modal {...props}>{children}</Modal>;
};

const ZModalContent = ({ children, ...props }: ModalContentProps) => {
  return <ModalContent {...props}>{children}</ModalContent>;
};

const ZModalHeader = ({ children, ...props }: ModalHeaderProps) => {
  return <ModalHeader {...props}>{children}</ModalHeader>;
};

const ZModalBody = ({ children, ...props }: ModalBodyProps) => {
  return <ModalBody {...props}>{children}</ModalBody>;
};

const ZModalFooter = ({ children, ...props }: ModalFooterProps) => {
  return <ModalFooter {...props}>{children}</ModalFooter>;
};

export { ZModal, ZModalContent, ZModalHeader, ZModalBody, ZModalFooter };
