const ErrorIllustration = () => {
  return (
    <svg
      width="241"
      height="240"
      viewBox="0 0 241 240"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M175.262 162.145C198.494 155.425 217.157 143.717 222.019 118.767C226.291 96.8598 222.043 69.5478 204.638 53.967C187.33 38.4678 159.701 37.647 139.738 48.207C118.992 59.2086 111.955 82.7046 90.192 92.7414C80.1498 97.3556 69.1102 99.3757 58.0848 98.6166C48.3456 98.0166 38.1168 94.3974 28.8048 99.0006C6.30242 110.103 19.488 142.172 31.9104 155.818C51.2544 177.073 69.6528 168.063 94.3104 165.418C121.982 162.466 147.648 170.146 175.262 162.145Z"
        fill="url(#paint0_linear_1292_49263)"
      />
      <path
        opacity="0.9"
        d="M175.262 162.145C198.494 155.425 217.157 143.717 222.019 118.767C226.291 96.8598 222.043 69.5478 204.638 53.967C187.33 38.4678 159.701 37.647 139.738 48.207C118.992 59.2086 111.955 82.7046 90.192 92.7414C80.1498 97.3556 69.1102 99.3757 58.0848 98.6166C48.3456 98.0166 38.1168 94.3974 28.8048 99.0006C6.30242 110.103 19.488 142.172 31.9104 155.818C51.2544 177.073 69.6528 168.063 94.3104 165.418C121.982 162.466 147.648 170.146 175.262 162.145Z"
        fill="white"
      />
      <path
        d="M120.063 205.229C171.462 205.229 213.13 202.796 213.13 199.795C213.13 196.794 171.462 194.361 120.063 194.361C68.6632 194.361 26.9956 196.794 26.9956 199.795C26.9956 202.796 68.6632 205.229 120.063 205.229Z"
        fill="#F5F5F5"
      />
      <g clipPath="url(#clip0_1292_49263)">
        <g filter="url(#filter0_b_1292_49263)">
          <path
            d="M106.984 76.8814C113.019 66.4285 128.107 66.4285 134.142 76.8814L174.878 147.439C180.913 157.892 173.369 170.958 161.299 170.958H79.8267C67.7567 170.958 60.213 157.892 66.248 147.439L106.984 76.8814Z"
            fill="white"
          />
          <path
            d="M108.716 77.8814C113.981 68.7618 127.144 68.7618 132.41 77.8814L173.146 148.439C178.411 157.558 171.83 168.958 161.299 168.958H79.8267C69.2963 168.958 62.7148 157.558 67.98 148.439L108.716 77.8814Z"
            stroke="#71717A"
            strokeWidth="4"
          />
        </g>
        <g filter="url(#filter1_d_1292_49263)">
          <rect
            width="40"
            height="4"
            rx="2"
            transform="matrix(4.37114e-08 1 1 -4.37114e-08 118.562 96.2656)"
            fill="#71717A"
          />
        </g>
        <g filter="url(#filter2_d_1292_49263)">
          <rect
            width="6"
            height="6"
            rx="3"
            transform="matrix(4.37114e-08 1 1 -4.37114e-08 117.562 144.266)"
            fill="#71717A"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_b_1292_49263"
          x="22.3123"
          y="27.2303"
          width="196.501"
          height="185.539"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="20.9058" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1292_49263"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_1292_49263"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_1292_49263"
          x="108.109"
          y="85.8127"
          width="24.9058"
          height="60.9058"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="5.22646" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1292_49263"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1292_49263"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_1292_49263"
          x="107.109"
          y="133.813"
          width="26.9058"
          height="26.9058"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="5.22646" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1292_49263"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1292_49263"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1292_49263"
          x1="120.078"
          y1="41.1582"
          x2="120.078"
          y2="169.582"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DD9AB0" />
          <stop offset="1" stopColor="#A982CF" />
        </linearGradient>
        <clipPath id="clip0_1292_49263">
          <rect
            width="120"
            height="107.143"
            fill="white"
            transform="translate(60.5625 66.4287)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ErrorIllustration;
