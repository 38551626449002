import { AgentSessionStepType } from "@/modules/sessions/types";
import { addNotification } from "@/shared/states/notification";
import { UploadedFile } from "@/shared/types/file-upload";
import debounce from "@/shared/utils/debounce";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { VENDOR_PREDEFINED_COMPLIANCE_CERTS } from "../constants/vendorAssessmentConstants";
import { useAgentStepData } from "../states";
import {
  AllowedChildFields,
  AllowedParentFields,
  VendorIntakeFormData,
} from "../types/vendorIntakeForm";
import { handleAutoSaveOfVendorIntakeForm } from "../use-cases/vendor.use-case";

export const useVendorIntakeForm = (initialFormData: VendorIntakeFormData) => {
  const [formData, setFormData] =
    useState<VendorIntakeFormData>(initialFormData);
  const [signedUrl, setSignedUrl] = useState<string[]>([]);
  const { id: agentId } = useParams<{ id: string }>();
  const isInitialMount = useRef(true);
  const prevFormData = useRef<VendorIntakeFormData>(initialFormData);
  const prevSignedUrl = useRef<string[]>([]);

  const stepData = useAgentStepData(agentId!);

  useLayoutEffect(() => {
    if (stepData) {
      const vendorProfileStep = stepData.find(
        (step) => step.type === AgentSessionStepType.MULTI_VENDOR_PROFILE,
      );

      if (vendorProfileStep && vendorProfileStep.data) {
        const newFormData = {
          ...initialFormData,
          ...(vendorProfileStep.data
            .vendor_intake_form as VendorIntakeFormData),
          vendor_submitted_files:
            (vendorProfileStep.data.vendor_intake_form as VendorIntakeFormData)
              .vendor_submitted_files || [],
        };
        const newSignedUrl = vendorProfileStep.data.source_urls || [];

        setFormData(newFormData);
        setSignedUrl(newSignedUrl);

        // Update refs without triggering auto-save
        prevFormData.current = newFormData;
        prevSignedUrl.current = newSignedUrl;
      }
    }
  }, [stepData, initialFormData]);

  const updateFormData = useCallback(
    (newData: Partial<VendorIntakeFormData>, updatedSignedUrl?: string[]) => {
      setFormData((prevData) => {
        const updatedData = {
          ...prevData,
          ...newData,
          vendor_submitted_files:
            newData.vendor_submitted_files || prevData.vendor_submitted_files,
        };

        if (
          newData.customer_added_certifications &&
          newData.customer_added_certifications.length > 0
        ) {
          const newCert = newData.customer_added_certifications[0];
          if (!updatedData.customer_added_certifications.includes(newCert)) {
            updatedData.customer_added_certifications = [
              ...updatedData.customer_added_certifications,
              newCert,
            ];
          }
        }

        const hasFormDataChanged =
          JSON.stringify(updatedData) !== JSON.stringify(prevFormData.current);
        const hasSignedUrlChanged =
          JSON.stringify(updatedSignedUrl) !==
          JSON.stringify(prevSignedUrl.current);

        if (
          !isInitialMount.current &&
          agentId &&
          (hasFormDataChanged || hasSignedUrlChanged)
        ) {
          const currentSignedUrl = updatedSignedUrl || signedUrl;
          // Only auto-save if there are actual changes
          if (hasFormDataChanged || hasSignedUrlChanged) {
            handleAutoSaveOfVendorIntakeForm(
              agentId,
              updatedData,
              currentSignedUrl,
            ).catch(console.error);
          }
        }

        prevFormData.current = updatedData;
        return updatedData;
      });

      if (updatedSignedUrl) {
        setSignedUrl(updatedSignedUrl);
        prevSignedUrl.current = updatedSignedUrl;
      }
    },
    [agentId, signedUrl],
  );

  // Set isInitialMount to false after the first render
  useEffect(() => {
    isInitialMount.current = false;
  }, []);

  const handleSwitchChange = useCallback(
    (value: string) => {
      updateFormData({ vendor_ai_ml_usage: value });
    },
    [updateFormData],
  );

  const handleAddCustomCertification = useCallback(
    (certificateName: string) => {
      setFormData((prevData) => {
        // Check if the certification already exists (case-insensitive)
        const alreadyExists =
          VENDOR_PREDEFINED_COMPLIANCE_CERTS.some(
            (cert) =>
              cert.label.toLowerCase() === certificateName.toLowerCase(),
          ) ||
          prevData.customer_added_certifications.some(
            (cert) => cert.toLowerCase() === certificateName.toLowerCase(),
          );

        if (!alreadyExists) {
          const updatedData = {
            ...prevData,
            customer_added_certifications: [
              ...prevData.customer_added_certifications,
              certificateName,
            ],
          };

          if (agentId) {
            handleAutoSaveOfVendorIntakeForm(
              agentId,
              updatedData,
              signedUrl,
            ).catch(console.error);
          }

          return updatedData;
        } else {
          // Add notification for duplicate certificate
          addNotification({
            type: "warn",
            title: `${certificateName} is already present in the list`,
            message: `Please use the checkbox to select it.`,
          });
        }
        return prevData;
      });
    },
    [agentId, signedUrl],
  );

  const handleCheckboxChange = useCallback(
    (selectedItems: string[]) => {
      updateFormData({ compliance_certifications: selectedItems });
    },
    [updateFormData],
  );

  const handleSelectChange = useCallback(
    (field: string, selectedItems: string | string[] | Set<string>) => {
      let value;
      if (
        field === "business_criticality" ||
        field === "system_access_level" ||
        field === "incidents_in_last_36_months" ||
        field === "data_access_scope.scope"
      ) {
        value = Array.from(selectedItems)[0];
      } else {
        value = Array.isArray(selectedItems)
          ? selectedItems
          : Array.from(selectedItems);
      }

      if (!field.includes(".")) {
        // flat field, no nesting
        updateFormData({ [field]: value });
        return;
      } else {
        // nested field
        const splitFields = field.split(".");

        if (splitFields.length !== 2) {
          throw new Error(
            "Invalid field name. Only two levels of nesting are allowed.",
          );
        }

        const parentField = splitFields[0] as AllowedParentFields;
        const childField = splitFields[1] as AllowedChildFields;

        updateFormData({
          [parentField]: {
            ...formData[parentField],
            [childField]: value,
          },
        });
      }
    },
    [formData, updateFormData],
  );

  const updateSignedUrl = useCallback(
    (files: UploadedFile[]) => {
      const fileUrls = files.map((file) => file.file.name);
      const signedUrls = files.map((file) => file.signedUrl || "");
      // Only update if there are actual changes
      if (
        JSON.stringify(fileUrls) !==
          JSON.stringify(formData.vendor_submitted_files) ||
        JSON.stringify(signedUrls) !== JSON.stringify(signedUrl)
      ) {
        updateFormData({ vendor_submitted_files: fileUrls }, signedUrls);
      }
    },
    [updateFormData, formData.vendor_submitted_files, signedUrl],
  );

  const handleOtherValueChange = useCallback(
    (field: string, value: string) => {
      if (!field.includes(".")) {
        // flat field, no nesting
        updateFormData({ [field]: value });
        return;
      } else {
        // nested field
        const splitFields = field.split(".");

        if (splitFields.length !== 2) {
          throw new Error(
            "Invalid field name. Only two levels of nesting are allowed.",
          );
        }

        const parentField = splitFields[0] as AllowedParentFields;
        const childField = splitFields[1] as AllowedChildFields;

        updateFormData({
          [parentField]: {
            ...formData[parentField],
            [childField]: value,
          },
        });
      }
    },
    [formData, updateFormData],
  );

  const debouncedUpdateFormData = useMemo(
    () =>
      debounce((value: string) => {
        updateFormData({ vendor_usage_description: value });
      }, 400),
    [updateFormData],
  );

  const handleVendorUsageDescriptionChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const value = e.target.value;
      setFormData((prev) => ({ ...prev, vendor_usage_description: value.trimStart() }));
      debouncedUpdateFormData(value);
    },
    [debouncedUpdateFormData, setFormData],
  );

  return {
    formData,
    signedUrl, // Add this to the return object
    handleSwitchChange,
    handleCheckboxChange,
    handleSelectChange,
    updateSignedUrl,
    handleOtherValueChange,
    handleVendorUsageDescriptionChange,
    handleAddCustomCertification,
  };
};
