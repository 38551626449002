import { addNotification } from '@/shared/states/notification';
import {
  B2BAuthenticateResponseWithMFAType,
  StytchClientType,
} from '@/shared/types/stytch';
import { generateOrgSlug } from '@/shared/utils/organization-helper';
import { allowedEmailDomains, sessionDurationMins } from '../constants';
import { setAuth } from '../states';

export const createNewOrgUseCase = async ({
  stytch,
  orgName,
}: {
  stytch: StytchClientType;
  orgName: string;
}): Promise<B2BAuthenticateResponseWithMFAType> => {
  try {
    const data = await stytch.discovery.organizations.create({
      organization_name: orgName,
      organization_slug: generateOrgSlug(orgName),
      email_invites: "RESTRICTED",
      email_allowed_domains: allowedEmailDomains,
      session_duration_minutes: sessionDurationMins,
    });
    setAuth({
      isAuthenticated: true,
      isAuthenticationInProgress: false,
    });
    return data;
  } catch (error) {
    console.error(error);
    addNotification({
      message: (error as Error).message || "Failed to create organization",
      type: "error",
    });
    throw new Error((error as Error).message);
  }
};

export const selectOrgUseCase = async ({
  stytch,
  orgId,
}: {
  stytch: StytchClientType;
  orgId: string;
}) => {
  try {
    const data = await stytch.discovery.intermediateSessions.exchange({
      organization_id: orgId,
      session_duration_minutes: sessionDurationMins,
    });
    setAuth({
      isAuthenticated: true,
      isAuthenticationInProgress: false,
    });
    return data;
  } catch (error) {
    console.error(error);
    addNotification({
      message: (error as Error).message || "Failed to select organization",
      type: "error",
    });
    throw new Error((error as Error).message);
  }
};

export const signUpWithNewOrgUseCase = async ({
  stytch,
  orgName,
  name,
}: {
  stytch: StytchClientType;
  orgName: string;
  name: string;
}) => {
  try {
    const data = await createNewOrgUseCase({
      stytch,
      orgName,
    });
    const memberId = data.member_id;
    await stytch.organization.members.update({
      member_id: memberId,
      name,
    });
    setAuth({
      isAuthenticated: true,
      isAuthenticationInProgress: false,
    });
  } catch (error) {
    console.error(error);
    addNotification({
      message: (error as Error).message || "Failed to create organization",
      type: "error",
    });
    throw new Error((error as Error).message);
  }
};
