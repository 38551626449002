import { get } from "@/infra/rest";
import requestConfig from "@/shared/config/request";

export const addToSlack = async () => {
  const urlObj = new URL(requestConfig.slackUrl);
  return get<{
    install_url: string;
  }>({
    url: urlObj.pathname + urlObj.search,
    isAuthRequired: false,
    config: {
      baseURL: urlObj.origin,
    },
  });
};
