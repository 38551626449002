import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  cn,
} from "@nextui-org/react";
import { ChevronDownIcon } from "lucide-react";
import { useMemo, useState } from "react";
import { getSheetStatus, useSheetMapping } from "../../states/questionnaire";
import { SheetStatus } from "../../types/questionnaire";

const SheetSelector = ({
  sheetName,
  handleSheetChange,
  agentId,
  handleOnClickIgnoreSheet,
  selectedSheetId,
  showMenu = true,
}: {
  sheetName: string;
  handleSheetChange: (sheetName: string) => Promise<void>;
  agentId: string;
  handleOnClickIgnoreSheet: (
    sheetName: string,
    selectNextSheet?: boolean,
  ) => void;
  selectedSheetId: string;
  showMenu?: boolean;
}) => {
  const sheetMapping = useSheetMapping(agentId, sheetName);
  const [isOpen, setIsOpen] = useState(false);
  const sheetStatus = getSheetStatus(agentId, sheetName);
  const isSelected = sheetName === selectedSheetId;

  const isComplete = useMemo(() => {
    if (!sheetMapping) return false;
    return (
      sheetMapping.questions.length > 0 &&
      typeof sheetMapping.questionStartRow === "number" &&
      sheetMapping.answers.length > 0
    );
  }, [sheetMapping]);

  const handleIgnoreSheet = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    handleOnClickIgnoreSheet(sheetName, false);
    setIsOpen(false);
  };

  return (
    <Tooltip
      content={
        !isComplete
          ? "Question and Answer(s) not mapped"
          : sheetStatus === SheetStatus.IGNORED
            ? "Ignored from mapping"
            : null
      }
      isDisabled={
        !isComplete ? false : sheetStatus === SheetStatus.IGNORED ? false : true
      }
      classNames={{
        content: "bg-black bg-opacity-80 backdrop-blur-md text-white text-tiny",
        arrow: "bg-black bg-opacity-80 backdrop-blur-md",
      }}
    >
      <div
        key={sheetName}
        className={cn(
          "text-sm flex gap-2 items-center py-1 h-fit px-1 cursor-pointer rounded text-nowrap",
          !isComplete ? "text-[#F5A524]" : "",
          isSelected ? "font-medium bg-[#D6E5F8] bg-opacity-50" : "",
          sheetStatus === SheetStatus.IGNORED ? "text-[#71717A]" : "",
          !showMenu ? "px-2 py-2" : "pl-3",
        )}
        onClick={() => void handleSheetChange(sheetName)}
      >
        <span
          className={sheetStatus === SheetStatus.IGNORED ? "line-through" : ""}
        >
          {sheetName}
        </span>
        {showMenu ? (
          <Popover isOpen={isOpen} onOpenChange={setIsOpen}>
            <PopoverTrigger>
              <Button
                isIconOnly
                size="sm"
                variant="light"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsOpen(true);
                }}
              >
                <ChevronDownIcon className="h-4 w-4" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="rounded-md">
              <div className="flex flex-col p-2">
                <Button
                  variant="light"
                  size="sm"
                  color={
                    sheetStatus === SheetStatus.IGNORED ? "primary" : "danger"
                  }
                  className="text-tiny p-0 px-2 1 h-7 rounded-md"
                  onClick={handleIgnoreSheet}
                >
                  {sheetStatus === SheetStatus.IGNORED
                    ? "Reset Sheet"
                    : "Ignore Sheet from mapping"}
                </Button>
              </div>
            </PopoverContent>
          </Popover>
        ) : null}
      </div>
    </Tooltip>
  );
};

export default SheetSelector;
