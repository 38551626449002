import { Tab, Tabs } from "@nextui-org/react";
import clsx from "clsx";
import { useMemo } from "react";
import {
  TSCENUM,
  useCustomizeControlsStore,
} from "../../states/cusomize-controls";
import { convertSnakeToTitleCase } from "../../utils/snake-to-capital";

// export interface CustomizeControlsTabsProps {}

export const CustomizeControlsTabs = () => {
  const selectedTab = useCustomizeControlsStore().selectedTab;

  const handleChange = useCustomizeControlsStore().actions.setSelectedTab;
  const setSelectedControls = useCustomizeControlsStore().actions
    .setSelectedControls;

  const options = useMemo(() => Object.values(TSCENUM), []);

  return (
    <div className="bg-white w-full">
      <Tabs
        variant="underlined"
        selectedKey={selectedTab}
        classNames={{
          tab: "rounded-none px-6",
          base: "rounded-none",
          cursor: "rounded-none",
          panel: "rounded-none",
          tabContent: "rounded-none",
          tabList: "rounded-none p-0",
          wrapper: "rounded-none",
        }}
        className="bg-white w-full"
        onSelectionChange={(key) => {
          handleChange(key as TSCENUM);
          setSelectedControls(new Set());
        }}
      >
        {options.map((key) => (
          <Tab
            className={clsx("rounded-none", {
              'after:content-[""] after:absolute after:bottom-0 after:left-0 after:w-full after:h-full after:bg-gradient-to-t  after:from-[#E7F2FF] after:to-[#FFFFFF] after:border-b-2 after:border-b-[#006FEE]':
                selectedTab === key,
            })}
            key={key}
            title={
              key === TSCENUM.SECURITY
                ? "Security / Common Criteria"
                : convertSnakeToTitleCase(key)
            }
          />
        ))}
      </Tabs>
    </div>
  );
};

export default CustomizeControlsTabs;
