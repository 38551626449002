import { ZButton } from "@/shared/components/button";
import WarningIcon from "@/shared/icons/Warning";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";

interface EndSessionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDownload: () => void;
  responses: {
    approved?: number;
    total?: number;
  };
  loading?: boolean;
}

const EndSessionModal = ({
  isOpen,
  onClose,
  onDownload,
  responses,
  loading,
}: EndSessionModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md">
      <ModalContent>
        <ModalHeader className="self-center">
          <div className="flex flex-col items-center justify-center gap-2">
            <p className="text-[18px] font-semibold">
              Are you sure you want to end the session?
            </p>
            {responses &&
            responses.approved !== undefined &&
            responses.total ? (
              <p className="text-[14px] font-normal text-[#F31260]">
                {responses.approved === 0
                  ? "No responses have been reviewed"
                  : `${responses.total - responses.approved} of ${responses.total} responses have not been reviewed`}
              </p>
            ) : null}
          </div>
        </ModalHeader>
        <ModalBody className="self-center items-center">
          <WarningIcon />
          <p className="text-[#777780] text-tiny text-center">
            You will not be able to edit or review responses after
          </p>
          <p className="text-[#777780] text-tiny text-center">
            ending the session
          </p>
        </ModalBody>
        <ModalFooter className="border-t">
          <ZButton
            color="primary"
            className="w-full"
            variant="bordered"
            onClick={onClose}
          >
            Back to review
          </ZButton>

          <ZButton
            color="primary"
            className="w-full"
            onClick={onDownload}
            isLoading={loading}
          >
            Yes, End Session
          </ZButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EndSessionModal;
