import { SVGProps } from "react";

const PlusCircledIcon = (props: SVGProps<SVGSVGElement>) => {
  const { color, ...rest } = props;
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_3732_2538)">
        <path
          d="M8.03336 7.46668V7.96668H8.53336H11.2C11.2184 7.96668 11.2333 7.98157 11.2333 8.00001C11.2333 8.01846 11.2184 8.03335 11.2 8.03335H8.53336H8.03336V8.53335V11.2C8.03336 11.2184 8.01841 11.2333 8.00003 11.2333C7.98165 11.2333 7.9667 11.2184 7.9667 11.2V8.53335V8.03335H7.4667H4.80003C4.78163 8.03335 4.7667 8.01842 4.7667 8.00001C4.7667 7.98161 4.78163 7.96668 4.80003 7.96668H7.4667H7.9667V7.46668V4.80001C7.9667 4.78161 7.98162 4.76668 8.00003 4.76668C8.01844 4.76668 8.03336 4.7816 8.03336 4.80001V7.46668ZM1.43555 7.99971C1.43555 4.37433 4.37451 1.43536 7.9999 1.43536C11.6253 1.43536 14.5642 4.37433 14.5642 7.99971C14.5642 11.6251 11.6253 14.5641 7.9999 14.5641C4.37451 14.5641 1.43555 11.6251 1.43555 7.99971ZM7.9999 1.44869C4.38187 1.44869 1.44887 4.38169 1.44887 7.99971C1.44887 11.6177 4.38187 14.5508 7.9999 14.5508C11.6179 14.5508 14.5509 11.6177 14.5509 7.99971C14.5509 4.3817 11.6179 1.44869 7.9999 1.44869Z"
          fill={color ?? "#A1A1AA"}
          stroke={color ?? "#A1A1AA"}
        />
      </g>
      <defs>
        <clipPath id="clip0_3732_2538">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PlusCircledIcon;
