import {
  getCustomizeControlsActions,
  useCustomizeControlsStore,
} from "@/modules/agent/states/cusomize-controls";
import { memo } from "react";
import { ZInputWithOnChangeDelay } from "../helpers";

const CustomizeControlSearchBar = () => {
  const selectedControls = useCustomizeControlsStore().selectedControls;
  const searchQuery = useCustomizeControlsStore().searchQuery;
  return (
    <>
      {selectedControls.size === 0 && (
        <ZInputWithOnChangeDelay
          classNames={{
            inputWrapper: "rounded-md h-9 min-h-9",
            mainWrapper: "max-h-9 bg",
          }}
          className="grow max-w-96 min-w-[480px]  h-9"
          placeholder="Enter keywords to search"
          value={searchQuery}
          onChange={(e) => {
            getCustomizeControlsActions().setSearchQuery(e.target.value);
          }}
        />
      )}
    </>
  );
};

export default memo(CustomizeControlSearchBar);
