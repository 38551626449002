import ZAvatarGradient from "@/shared/components/avatarGradient";
import { ZButton } from "@/shared/components/button";
import {
  ZModal,
  ZModalBody,
  ZModalContent,
  ZModalFooter,
  ZModalHeader,
} from "@/shared/components/modal";
import { ROUTES } from "@/shared/constants/routes";
import { addNotification } from "@/shared/states/notification";
import { useStytch } from "@/shared/states/stytch";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getMember } from "../../states";
import { removeTeamMemberUseCase } from "../../use-cases/team.use-case";
import { printErrorMessage } from "../../utils";

const TeamMemberRemoveDialog = () => {
  const stytch = useStytch();
  const [loading, setLoading] = useState(false);
  const { memberId } = useParams();

  const member = getMember(memberId as string);

  const navigate = useNavigate();

  const onCloseDialog = useCallback(() => {
    navigate(`/${ROUTES.TEAM}`, { replace: true });
  }, [navigate]);

  useEffect(() => {
    if (!memberId || !member) {
      onCloseDialog();
    }
  }, [memberId, member, onCloseDialog]);

  const handleOnClickRemove = async () => {
    setLoading(true);
    try {
      await removeTeamMemberUseCase({ stytch, memberId: memberId as string });
      addNotification({
        type: "success",
        title: "Team Member Removed",
        message: `${member?.name ?? "Team member"} was removed from your team.`,
      });
      onCloseDialog();
    } catch (error) {
      console.error(error);
      addNotification({
        type: "error",
        title:
          ((error as Error).cause as string) ??
          `Could not remove ${member?.name ?? "team member"} from your team`,
        message: printErrorMessage(JSON.stringify(error)),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <ZModal defaultOpen onOpenChange={(isOpen) => !isOpen && onCloseDialog()}>
      <ZModalContent>
        <ZModalHeader className="w-full flex flex-col gap-2">
          <h4 className="text-center">
            Are you sure you want to remove this member from your team?
          </h4>
          <p className="text-center font-normal text-sm text-[#777780]">
            All sessions created by them will be permenantly deleted
          </p>
        </ZModalHeader>
        <ZModalBody className="w-full px-6 py-6 gap-6">
          <div className="flex items-center gap-3 bg-[#F7F7FA] px-6 py-4 rounded-lg">
            <ZAvatarGradient
              size={42}
              textSize={18}
              name={member?.name ?? ""}
            />
            <div className="flex flex-col items-start">
              <p className="font-bold">{member?.name}</p>
              <p className="font-medium text-[#777780] text-tiny">
                {member?.email_address}
              </p>
            </div>
          </div>
        </ZModalBody>
        <ZModalFooter className="w-full justify-stretch">
          <ZButton
            variant="bordered"
            onClick={onCloseDialog}
            disabled={loading}
            className="flex-auto"
          >
            Cancel
          </ZButton>
          <ZButton
            color="danger"
            isLoading={loading}
            onClick={() => void handleOnClickRemove()}
            disabled={loading}
            className="flex-auto"
          >
            {loading ? "Please Wait" : "Yes, Remove User"}
          </ZButton>
        </ZModalFooter>
      </ZModalContent>
    </ZModal>
  );
};

export default TeamMemberRemoveDialog;
