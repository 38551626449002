import { VendorRiskSectionEnum } from "@/modules/agent/types/vendorAssessment";
import { VendorRiskResponseItemForSecurityAssessment } from "@/modules/agent/types/vendorSecurityAssessment";
import { handleAutoSaveOfVendorAssessmentResponse } from "@/modules/agent/use-cases/vendor.use-case";
import parsePromise from "@/shared/utils/parsePromise";
import { useCallback, useEffect } from "react";
import { Params, useParams } from "react-router-dom";
import {
  processFileForVendorRiskReview,
  processVendorRiskReviewData,
} from "../../../use-cases/vendor-risk.use-case";
import { useVendorRiskAssessmentStore } from "./useVendorRiskAssessmentStore";

export type VendorPageType = "OVERVIEW" | "SUPPLY_CHAIN" | "ASSESSMENT";

export const useVendorRiskAssessment = () => {
  const { id } = useParams<keyof Params>() as Params;

  const {
    // states
    selectedTab,
    combinedVendorRiskData,
    vendorOverview,
    vendorSupplyChainData,
    vendorAssessmentData,
    changesMade,
    // functions and dispatchers
    setSelectedTab,
    setCombinedVendorRiskData,
    setVendorOverview,
    setVendorSupplyChain,
    setVendorAssessment,
    setIsSaving,
  } = useVendorRiskAssessmentStore();

  const handleTabChange = useCallback(
    (newTab: VendorPageType) => {
      setSelectedTab(newTab);
    },
    [setSelectedTab],
  );

  useEffect(() => {
    if (changesMade > 0 && id) {
      const t = setTimeout(() => {
        setIsSaving(true);
        handleAutoSaveOfVendorAssessmentResponse({ agentId: id })
          .then((res) => {
            setIsSaving(false);
          })
          .catch((err) => {
            setIsSaving(false);
            console.error(err);
          });
      }, 1000);
      return () => {
        clearTimeout(t);
      };
    }
  }, [changesMade, id, setIsSaving]);

  const loadVendorRiskData = useCallback(async () => {
    if (id) {
      const [error, res] = await parsePromise(
        processFileForVendorRiskReview(id),
      );

      if (error) {
        console.error(error);
        return;
      }

      setCombinedVendorRiskData(res);

      if (res[VendorRiskSectionEnum.vendor_overview]) {
        setVendorOverview(res[VendorRiskSectionEnum.vendor_overview], true);
      }
      if (res[VendorRiskSectionEnum.vendor_supply_chain]) {
        setVendorSupplyChain(
          res[VendorRiskSectionEnum.vendor_supply_chain],
          true,
        );
      }
      if (
        res[VendorRiskSectionEnum.zania_security_assessment] ||
        res[VendorRiskSectionEnum.ai_security_assessment]
      ) {
        const vendorRiskReviewData = [
          ...res[VendorRiskSectionEnum.zania_security_assessment],
          ...res[VendorRiskSectionEnum.ai_security_assessment],
        ];

        setVendorAssessment(vendorRiskReviewData, true);

        processVendorRiskReviewData(
          id,
          vendorRiskReviewData as VendorRiskResponseItemForSecurityAssessment[],
        );
      }
    }
  }, [
    id,
    setCombinedVendorRiskData,
    setVendorOverview,
    setVendorSupplyChain,
    setVendorAssessment,
  ]);

  return {
    // states
    selectedTab,
    combinedVendorRiskData,
    vendorOverview,
    vendorSupplyChainData,
    vendorAssessmentData,
    // functions and dispatchers
    handleTabChange,
    loadVendorRiskData,
  };
};
