import { ZTable, ZTableRowParams } from "@/shared/components/table";
import { cn } from "@/shared/utils/classname-merger";
import { Checkbox } from "@nextui-org/react";
import Fuse from "fuse.js";
import { useEffect, useMemo } from "react";
import { v4 as uuid } from "uuid";
import {
  Control,
  getCustomizeControlsActions,
  isControlValid,
  SecurityControlCategory,
  TSCABBRIVATIONS,
  useCustomizeControlsStore,
} from "../../states/cusomize-controls";
import {
  CustomizeControlCategoryRenderer,
  CustomizeControlDescriptionRenderer,
  CustomizeControlIdRenderer,
  CustomizeControlTestingProcedureRenderer,
} from "./column-cell-renderers";
import { getRandomCharacter } from "./helpers";
const CustomizeControlsTable = () => {
  const {
    dataState,
    accordianOpenState,
    selectedControls,
    searchQuery,
    selectedTab,
  } = useCustomizeControlsStore();
  const {
    setDataState,
    setAccordianOpenState,
    setSelectedControls,
    setNewControlPlaceHolderUid,
  } = useCustomizeControlsStore().actions;

  const dataStateBasedOnSelected = useMemo(
    () => dataState.filter((c) => c.tsc === selectedTab),
    [dataState, selectedTab]
  );

  const fuse = useMemo(() => {
    return new Fuse(dataStateBasedOnSelected, {
      keys: [
        "category",
        "toe",
        "control",
        "control_id",
        "tsc",
        "tsc_id",
        "zania_control_id",
      ],
      threshold: 0.3,
      findAllMatches: true,
      ignoreLocation: true,
    });
  }, [dataStateBasedOnSelected]);

  const filteredRows = useMemo(() => {
    return searchQuery
      ? fuse
          .search(searchQuery)
          .map((result) => result.item)
          .filter(Boolean)
      : dataStateBasedOnSelected;
  }, [dataStateBasedOnSelected, fuse, searchQuery]);

  const sectionWiseData = useMemo(() => {
    const output = filteredRows.filter(
      (control) => control.tsc === selectedTab
    );
    const uid = getRandomCharacter() + uuid().replace("-", "_");
    if (searchQuery.trim().length === 0) {
      output.push({
        category: "",
        toe: "",
        tod: "",
        control: "",
        control_id: "",
        criteria: "",
        tsc: selectedTab,
        tsc_id: "",
        uid,
        zania_control_id: "",
        edited_state: "NEW",
      });
    }

    return output.map((d) => ({ ...d, id: d.uid }));
  }, [filteredRows, selectedTab, searchQuery]);

  useEffect(() => {
    const newControlUid = sectionWiseData.find((c) => c.edited_state === "NEW")
      ?.uid;
    setNewControlPlaceHolderUid(newControlUid ?? "");
  }, [setNewControlPlaceHolderUid, sectionWiseData]);

  const handleChangeDataState = (
    key: keyof Control,
    uId: string,
    newValue: string
  ) => {
    setDataState((prev) => {
      const newDataState = [...prev];
      const foundIndexOfElement = newDataState.findIndex((d) => d.uid === uId);
      if (foundIndexOfElement > -1) {
        if (key === "tsc_id") {
          let modifiedNewValue = newValue;

          if (
            modifiedNewValue.trim().length > 0 &&
            Object.values(TSCABBRIVATIONS).reduce(
              (acc, abbrivation) =>
                modifiedNewValue.startsWith(abbrivation) || acc,
              false
            ) === false
          ) {
            modifiedNewValue = `${
              TSCABBRIVATIONS[newDataState[foundIndexOfElement].tsc]
            }${modifiedNewValue}`;
          }

          newDataState[foundIndexOfElement].control_id = modifiedNewValue;
          if (newDataState[foundIndexOfElement].edited_state !== "default") {
            newDataState[foundIndexOfElement].tsc_id = modifiedNewValue;
          }
        }
        if (key === "control") {
          newDataState[foundIndexOfElement].control = newValue;
          newDataState[foundIndexOfElement].changesInControl =
            (newDataState[foundIndexOfElement].changesInControl ?? 0) + 1;
        }
        if (key === "category") {
          newDataState[
            foundIndexOfElement
          ].category = newValue as SecurityControlCategory;
        }
        if (key === "toe") {
          newDataState[foundIndexOfElement].toe = newValue;
          newDataState[foundIndexOfElement].tod = newValue;
          newDataState[foundIndexOfElement].changesInTestingProcedure =
            (newDataState[foundIndexOfElement].changesInTestingProcedure ?? 0) +
            1;
        }

        if (key === "new_control") {
          newDataState[foundIndexOfElement].new_control = newValue;
        }
        if (key === "new_tod" || key === "new_toe") {
          newDataState[foundIndexOfElement].new_tod = newValue;
          newDataState[foundIndexOfElement].new_toe = newValue;
        }

        if (
          newDataState[foundIndexOfElement].toe.trim().length === 0 &&
          newDataState[foundIndexOfElement].tsc_id.trim() ===
            TSCABBRIVATIONS[newDataState[foundIndexOfElement].tsc] &&
          newDataState[foundIndexOfElement].category.trim().length === 0
        ) {
          const removedUid = newDataState[foundIndexOfElement].uid;
          setSelectedControls((prev) => {
            const newSelectedControls = new Set(prev);
            newSelectedControls.delete(removedUid);
            return newSelectedControls;
          });

          newDataState.splice(foundIndexOfElement, 1);
        } else {
          if (!isControlValid(newDataState[foundIndexOfElement])) {
            // setSelectedControls((prev) => {
            //   const newSelectedControls = new Set(prev)
            //   newSelectedControls.delete(newDataState[foundIndexOfElement].uid)
            //   return newSelectedControls
            // })
            // newDataState[foundIndexOfElement].selected = false
          } else if (
            isControlValid(newDataState[foundIndexOfElement]) &&
            newDataState[foundIndexOfElement].edited_state === "custom"
          ) {
            // setSelectedControls((prev) => {
            //   const newSelectedControls = new Set(prev)
            //   newSelectedControls.add(newDataState[foundIndexOfElement].uid)
            //   return newSelectedControls
            // })
            // newDataState[foundIndexOfElement].selected = true
          }
        }
      } else {
        const newObject = Object.values(sectionWiseData).find(
          (d) => d.uid === uId
        );
        if (newObject) {
          if (key === "tsc_id") {
            let modifiedNewValue = newValue;

            if (
              Object.values(TSCABBRIVATIONS).reduce(
                (acc, abbrivation) =>
                  modifiedNewValue.startsWith(abbrivation) || acc,
                false
              ) === false
            ) {
              modifiedNewValue = `${
                TSCABBRIVATIONS[newObject.tsc]
              }${modifiedNewValue}`;
            }

            newObject.control_id = modifiedNewValue;
            if (newObject.edited_state !== "default") {
              newObject.tsc_id = modifiedNewValue;
            }
          }
          if (key === "control") {
            newObject.control = newValue;
            newObject.changesInControl = 1;
          }
          if (key === "category") {
            newObject.category = newValue as SecurityControlCategory;
          }
          if (key === "toe") {
            newObject.toe = newValue;
            newObject.changesInTestingProcedure = 1;
          }

          newDataState.push({
            ...newObject,
            edited_state: "custom",
          });
          setTimeout(() => {
            const d = document.getElementById(uId + key);
            d?.focus();
          }, 0);
        }
      }

      getCustomizeControlsActions().setChangesMade((prev) => prev + 1);
      return newDataState;
    });
  };

  const getDuplicateErrorMessage = (control: Control): string | undefined => {
    const temp = dataState.filter((c) => c.uid !== control.uid);

    if (temp.find((c) => c.tsc_id.trim() === control.tsc_id.trim())) {
      return "Duplicate Control ID";
    }

    return undefined;
  };
  const onRowClickHandler = ({ row }: ZTableRowParams<Control>) => {
    if (row.edited_state === "ACCORDIAN") {
      setAccordianOpenState(row.tsc, !accordianOpenState[row.tsc]);
    }
  };
  return (
    <div className="grow overflow-hidden flex border-t  ">
      <div
        id="customize-control-table"
        className="flex-1 flex flex-row bg-white overflow-auto"
      >
        <ZTable<Control>
          aria-label="Customize Controls list"
          isCompact={true}
          removeWrapper={true}
          columns={[
            {
              fieldName: "id",
              headerName: "id",
              renderCell: (control: Control) => (
                <CustomizeControlIdRenderer
                  accordianOpenState={accordianOpenState}
                  control={control}
                  getDuplicateErrorMessage={getDuplicateErrorMessage}
                  handleChangeDataState={handleChangeDataState}
                  searchQuery={searchQuery}
                  selectedControls={selectedControls}
                  setAccordianOpenState={setAccordianOpenState}
                  setSelectedControls={setSelectedControls}
                />
              ),
              renderHeader: () => {
                return (
                  <div className="flex w-[216px]">
                    <Checkbox
                      isSelected={
                        filteredRows.length === selectedControls.size &&
                        selectedControls.size > 0
                      }
                      isIndeterminate={
                        selectedControls.size > 0 &&
                        filteredRows.length !== selectedControls.size
                      }
                      classNames={{
                        base: cn("group"),
                        wrapper: cn(
                          "group-data-[disabled=true]:border-default",
                          "after:group-data-[disabled=true]:bg-default-100"
                        ),
                        icon: "group-data-[disabled=true]:text-default-300",
                      }}
                      onChange={() => {
                        if (filteredRows.length === selectedControls.size) {
                          setSelectedControls(new Set([]));
                        } else {
                          setSelectedControls(
                            new Set(filteredRows.map((d) => d.uid))
                          );
                        }
                      }}
                      data-component="z-table-row-select"
                      color={"primary"}
                      disabled={searchQuery.length > 0}
                    />
                    <div className="text-[12px] leading-5 font-semibold">
                      ID
                    </div>
                  </div>
                );
              },
            },
            {
              fieldName: "category",
              headerName: "Category",
              renderCell: (control) => (
                <CustomizeControlCategoryRenderer
                  control={control}
                  handleChangeDataState={handleChangeDataState}
                  searchQuery={searchQuery}
                />
              ),
            },
            {
              fieldName: "control",
              headerName: "Control Description",
              minWidth: 652,
              renderCell: (control: Control) => (
                <CustomizeControlDescriptionRenderer
                  control={control}
                  handleChangeDataState={handleChangeDataState}
                  searchQuery={searchQuery}
                />
              ),
            },
            {
              fieldName: "testing_procedure",
              headerName: "Testing Procedure",
              minWidth: 372,
              renderCell: (control: Control) => (
                <CustomizeControlTestingProcedureRenderer
                  control={control}
                  handleChangeDataState={handleChangeDataState}
                  searchQuery={searchQuery}
                />
              ),
            },
          ]}
          tableRowProps={(control) => {
            return {
              className: cn(
                "border-t-1 border-b-1 border-[#E4E4E7] bg-white pb-1",
                "min-h-[36px]",
                control.edited_state === "ACCORDIAN" && "!bg-zinc-100",
                control.edited_state === "NEW" && "!bg-zinc-50"
              ),
            };
          }}
          tableBodyProps={{
            emptyContent: "No control found",
          }}
          tableColumnProps={({ fieldName }) => ({
            className: cn(
              fieldName === "control" && "pl-[16px]",
              fieldName === "" && "w-[216px]",
              fieldName === "category" && "w-[240px]",
              fieldName === "control" && "min-w-[420px]",
              fieldName === "testing_procedure" && "min-w-[372px]"
            ),
          })}
          classNames={{
            tr: "hover:bg-[#ECECEC] !rounded-none ",
            th: "bg-[#F9F7F7] !rounded-none",
            // td: 'h-full ',
          }}
          tableHeaderProps={{
            className: "!rounded-none",
          }}
          // className='transition'
          onRowClick={onRowClickHandler}
          tableCellProps={() => ({ className: cn("align-baseline ") })}
          rows={sectionWiseData}
          isHeaderSticky={true}
        />
        <span className="h-screen" />
      </div>
    </div>
  );
};

export default CustomizeControlsTable;
