import {
  AgentSessionStatus,
  AgentSessionStepType
} from "@/modules/sessions/types";
import { ZButton } from "@/shared/components/button";
import { useLoggedInMember } from "@/shared/hooks/use-login-member.ts";
import { addNotification } from "@/shared/states/notification";
import parsePromise from "@/shared/utils/parsePromise.ts";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/react";
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ArrowLeft, CheckCircleIcon, Ellipsis, RotateCcw, Users } from "lucide-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { helper } from "../../constants/helpers";
import {
  getAgentTypeFromId,
  useAgentData,
  useAgentMainData,
  useAgentStepData,
  useApprovedIds,
  useReviewResponse
} from "../../states";
import {
  isControlValid,
  useCustomizeControlsStore,
} from "../../states/cusomize-controls.ts";
import { isReadyToGenerateResponse } from "../../states/questionnaire";
import { AGENT_TYPES, AgentSourceFile } from "../../types/index.ts";
import { handleGenerateResponse } from "../../use-cases/customize-control.use-case";
import { generateResponseData } from "../../use-cases/quesitionnaire.use-case";
import { handleAgentAutoSave } from "../../utils/autosave";
import { generateAndDownloadSessionReport } from "../../utils/downloadReport.ts";
import { handleEndSession } from "../../utils/handle-end-session";
import ReassessModal from "../review-responses/ReassessModal.tsx";
import EndSessionModal from "./EndSessionModal";
import ShareSessionModal from "./ShareSessionModal";

interface QAGenerateButtonProps {
  onGenerateResponse: () => Promise<void>;
  loading: boolean;
}

const QAGenerateButton = ({
  onGenerateResponse,
  loading,
}: QAGenerateButtonProps) => {
  const { id } = useParams<keyof IdParams>() as IdParams;
  const { agentType } = getAgentTypeFromId(id);
  const agentMainData = useAgentMainData(id);


  const allowGenerate = useMemo(
    () =>
      agentMainData &&
      agentType === AGENT_TYPES.QUESTIONNAIRE &&
      isReadyToGenerateResponse(id),
    [id, agentType, agentMainData]
  );

  return (
    <div className="inline-flex items-center">
      <ZButton
        onClick={() => void onGenerateResponse()}
        isLoading={loading}
        className="px-3 py-1 h-auto"
        isDisabled={!allowGenerate}
      >
        Generate Responses
      </ZButton>
    </div>
  );
};

export interface IdParams {
  id: string;
}

const AgentHeader = () => {
  const navigate = useNavigate();
  const { id } = useParams<keyof IdParams>() as IdParams;
  const { agentType, agentSubType } = getAgentTypeFromId(id);
  const helperData = helper[agentSubType];
  const [downloadReportLoading, setDownloadReportLoading] = useState(false);
  const [isShareSessionModalOpen, setIsShareSessionModalOpen] = useState(false);
  const [isEndSessionModalOpen, setIsEndSessionModalOpen] = useState(false);
  const [endSessionLoading, setEndSessionLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openReassess, setOpenReassess] = useState<boolean>(false);
  const [uniqueFiles, setUniqueFiles] = useState<AgentSourceFile[]>([]);
  const [showReassessControl, setShowReassessControl] = useState<boolean>(false);

  const { ids } = useReviewResponse(id);
  const stepData = useAgentStepData(id);
  const agentMainData = useAgentMainData(id);
  const agentData = useAgentData(id);
  const approved = useApprovedIds(id);
  /*
  enableSoc2Reassess FF will have value in the below format
  {
    "{orgId}": true/false
  }
  */
  const { enableSoc2Reassess } = useFlags();
  const loggedInMember = useLoggedInMember();

  // customize control checking validation
  const { dataState, isSavingControls } = useCustomizeControlsStore();

  const enableReassess = [AGENT_TYPES.RISK_ASSESSMENT, AGENT_TYPES.GAP_ASSESSMENT];

  const handleDownloadReport = useCallback(async () => {
    setDownloadReportLoading(true);

    const [, autosaveError] = await parsePromise(
      handleAgentAutoSave(id, agentType, agentSubType, true)
    );

    if (autosaveError) {
      addNotification({
        type: "error",
        title: "Auto save failed",
        autoHideDuration: 8000,
        message:
          (autosaveError as Error)?.message ??
          "Auto save failed. Please try again later.",
      });
    }

    await parsePromise(generateAndDownloadSessionReport(id));

    setDownloadReportLoading(false);
    setIsEndSessionModalOpen(false);
  }, [id, agentType, agentSubType]);

  const inProgressOrInputNeeded = useMemo(() => {
    const inProgressSteps = [
      AgentSessionStatus.INPUT_NEEDED,
      AgentSessionStatus.IN_PROGRESS,
    ];
    return stepData?.find((step) => inProgressSteps.includes(step.status));
  }, [stepData]);

  const processStage = useMemo(() => {
    if (
      inProgressOrInputNeeded &&
      [
        AgentSessionStepType.GENERATE_RISK_ASSESSMENT_RESPONSE,
        AgentSessionStepType.SOC2_TYPE2_AUDIT,
        AgentSessionStepType.MULTI_FILE_VENDOR_ASSESSMENT,
        AgentSessionStepType.DORA_ASSESSMENT,
        AgentSessionStepType.MULTI_VENDOR_PROFILE,
      ].includes(inProgressOrInputNeeded.type)
    ) {
      return null;
    }

    if (inProgressOrInputNeeded?.type === AgentSessionStepType.EDIT_RESPONSE) {
      return AgentSessionStepType.EDIT_RESPONSE;
    }

    const prepareReportStep = stepData?.find(
      (step) => step.type === AgentSessionStepType.PREPARE_REPORT
    );
    if (
      prepareReportStep?.status === AgentSessionStatus.PENDING ||
      prepareReportStep?.status === AgentSessionStatus.COMPLETE
    ) {
      return AgentSessionStepType.PREPARE_REPORT;
    }

    return null;
  }, [stepData, inProgressOrInputNeeded]);

  const onGenerateResponse = async () => {
    try {
      setDownloadReportLoading(true);
      await generateResponseData({
        agentId: id,
      });
    } catch (error) {
      addNotification({
        type: "error",
        title: "Generate failed",
        message:
          (error as Error)?.message ??
          "Report generation failed. Please try again later",
      });
    } finally {
      setDownloadReportLoading(false);
    }
  };

  if (!processStage) return null;

  if (
    inProgressOrInputNeeded?.type ===
    AgentSessionStepType.MANUAL_EXCEL_STRUCTURE_MAPPING
  ) {
    return (
      <QAGenerateButton
        onGenerateResponse={onGenerateResponse}
        loading={downloadReportLoading}
      />
    );
  }

  const handleEndSessionWithLoading = async () => {
    setEndSessionLoading(true);
    try {
      await handleEndSession(id, agentType, navigate);
    } finally {
      setEndSessionLoading(false);
      setIsEndSessionModalOpen(false);
    }
  };

  const prepareReportStep = stepData?.find(
    (step) => step.type === AgentSessionStepType.PREPARE_REPORT
  );

  if (
    inProgressOrInputNeeded?.type === AgentSessionStepType.CONTROL_SELECTION
  ) {
    return (
      <>
        <div className="flex items-center gap-4">
          <ZButton
            onClick={() => {
              setLoading(true);
              handleGenerateResponse(id, navigate)
                .then(() => {
                  setLoading(false);
                })
                .catch((err) => {
                  console.error(err);
                });
            }}
            isLoading={loading}
            disabled={
              dataState.filter((d) => isControlValid(d)).length === 0 ||
              isSavingControls === "SAVING" ||
              isSavingControls === "EDITED"
            }
            isDisabled={
              dataState.filter((d) => isControlValid(d)).length === 0 ||
              isSavingControls === "SAVING" ||
              isSavingControls === "EDITED"
            }
            className="px-3 py-1 h-auto"
          >
            Generate Responses
          </ZButton>
        </div>
      </>
    );
  }

  const getPreviousFiles = () => {
    const fileNames: string[] = [];
    const uniqueSources: AgentSourceFile[] = [];
    for (let sourceFileMap of agentMainData?.sourceFilesUrls || []) {
      for (let item of sourceFileMap) {
        const file: AgentSourceFile = item as AgentSourceFile;
        if (file.url) {
          if (!fileNames.includes(file.fileName || '')) {
            fileNames.push(file.fileName || '');
            uniqueSources.push(file);
          }
        }

      }
    }
    setUniqueFiles(uniqueSources);
  }

  const handleReassess = () => {
    getPreviousFiles();
    setOpenReassess(true);
  }

  const onClose = () => {
    setOpenReassess(false);
  }

  useEffect(() => {
    if (agentData) {
      if (enableReassess.includes(agentData.agentType)) {
        if (agentData.agentType === AGENT_TYPES.GAP_ASSESSMENT) {
          if (loggedInMember?.organization_id && enableSoc2Reassess && enableSoc2Reassess[loggedInMember.organization_id]) {
            setShowReassessControl(true);
          } else {
            setShowReassessControl(false);
          }
        } else {
          setShowReassessControl(true);
        }
      } else {
        setShowReassessControl(false);
      }
    } else {
      setShowReassessControl(false);
    }
  }, [agentData, enableSoc2Reassess])

  if (
    processStage === AgentSessionStepType.EDIT_RESPONSE ||
    processStage === AgentSessionStepType.PREPARE_REPORT
  ) {
    return (
      <>
        <div className="flex items-center">
          <div className="p-1 py-[1px] bg-[#fafafa] px-2 rounded-lg mr-3">
            <p className="text-[10px] text-[#52525B] leading-4 font-medium">{`${approved?.length ?? 0
              } / ${ids?.length ?? 0} ${helperData.rowName}s accepted`}</p>
          </div>
          <ZButton
            onClick={() => void handleDownloadReport()}
            isLoading={downloadReportLoading}
            className="px-3 py-1 h-auto"
          >
            Download Report
          </ZButton>
          <div className="-ml-1">
            <Dropdown>
              <DropdownTrigger>
                <Button isIconOnly className="bg-transparent">
                  <Ellipsis className="h-5 w-5" color="#717179" />
                </Button>
              </DropdownTrigger>
              <DropdownMenu>
                <DropdownItem
                  key="reassess"
                  onClick={handleReassess}
                  startContent={<RotateCcw className="h-4 w-4 text-[#444444]" />}
                  isDisabled={
                    prepareReportStep?.status === AgentSessionStatus.COMPLETE
                  }
                  className={`text-[#444444] ${showReassessControl ? '' : 'hidden'}`}
                >
                  Reassess Controls
                </DropdownItem>
                <DropdownItem
                  key="share-session"
                  onClick={() => setIsShareSessionModalOpen(true)}
                  startContent={<Users className="h-4 w-4 text-[#444444]" />}
                  className="text-[#444444]"
                >
                  Share Session
                </DropdownItem>
                <DropdownItem
                  key="end-session"
                  onClick={() => {
                    if (approved.length !== ids?.length) {
                      setIsEndSessionModalOpen(true);
                      return;
                    }
                    return void handleEndSession(id, agentType, navigate);
                  }}
                  startContent={
                    <CheckCircleIcon className="h-4 w-4 text-[#444444]" />
                  }
                  isDisabled={
                    prepareReportStep?.status === AgentSessionStatus.COMPLETE
                  }
                  className="text-[#444444]"
                >
                  End Session
                </DropdownItem>
                <DropdownItem
                  key="back-to-agent-home"
                  onClick={() => {
                    navigate("/agent");
                  }}
                  startContent={
                    <ArrowLeft className="h-4 w-4 text-[#444444]" />
                  }
                  className="text-[#444444]"
                >
                  Back to Agent Home
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
          <div className="modal-container">
            <ReassessModal agentData={agentData} isOpen={openReassess} onClose={onClose} uniqueSources={uniqueFiles} />
          </div>
        </div>
        <EndSessionModal
          isOpen={isEndSessionModalOpen}
          onClose={() => setIsEndSessionModalOpen(false)}
          onDownload={() => void handleEndSessionWithLoading()}
          responses={{
            approved: approved.length,
            total: ids?.length ?? 0,
          }}
          loading={endSessionLoading}
        />
        {isShareSessionModalOpen && (
          <ShareSessionModal
            onClose={() => setIsShareSessionModalOpen(false)}
          />
        )}
      </>
    );
  }

  return null;
};

export default AgentHeader;
