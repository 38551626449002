import { getZaniaSocket } from "@/infra/sockets";
import { QuestionnaireStructure } from "@/modules/agent/types/questionnaire";
import { processFileForDoraAudit } from "@/modules/agent/use-cases/dora.use-case";
import {
  processFileForReview,
  processManualMapping,
} from "@/modules/agent/use-cases/quesitionnaire.use-case";
import { processFileForRiskReview } from "@/modules/agent/use-cases/risk.use-case";
import { processFileForSOC2Type2Audit } from "@/modules/agent/use-cases/soc2.use-case";
import { processFileForVendorAssessmentResponse } from "@/modules/agent/use-cases/vendor.use-case";
import useSocket from "@/shared/hooks/use-socket";
import { useCallback, useEffect } from "react";

export interface QAStructureCompletedResponse {
  agent_session_id: string;
  success: boolean;
  qa_structure: QuestionnaireStructure[];
}

export interface QAReviewResponseCompletedResponse {
  uid: string;
  success: boolean;
  agent_session_id: string;
  qa_filler: {
    filled_questionnaire_url: string;
  };
}

export interface RiskAssessmentComplete {
  agent_session_id: string;
  agent_session_step_id: string;
  success: boolean;
  risk_assessment_response: {
    file_url: string;
    file_type: string;
  };
}

export interface DoraAssessmentComplete {
  uid: string;
  success: boolean;
  dora_assessment_response: {
    file_url: string;
    file_type: string;
  };
}

export interface RagAuditComplete {
  agent_session_id: string;
  agent_session_step_id: string;
  success: boolean;
  rag_output: {
    file_url: string;
    file_type: string;
  };
  task: string;
}

const isIdInUrl = (id: string) => {
  const idInUrl = new URL(window.location.href).toString().includes(id);
  return idInUrl;
};

export const useAppSocket = () => {
  const { addListener, removeListener, isConnected } = useSocket("main", () =>
    getZaniaSocket(),
  );

  const onQAStructureComplete = useCallback(
    async ({
      success,
      qa_structure,
      agent_session_id,
    }: QAStructureCompletedResponse) => {
      if (!success || !isIdInUrl(agent_session_id)) return;
      await processManualMapping({
        agent_session_id,
        structureData: qa_structure,
      });
    },
    [],
  );

  const onQAReviewResponseComplete = useCallback(
    async ({
      success,
      qa_filler,
      agent_session_id,
    }: QAReviewResponseCompletedResponse) => {
      if (!success || !qa_filler || !isIdInUrl(agent_session_id)) return;
      await processFileForReview(
        qa_filler.filled_questionnaire_url,
        agent_session_id,
      );
    },
    [],
  );

  const onRagAuditComplete = useCallback(
    async ({
      success,
      rag_output,
      agent_session_id,
      task,
    }: RagAuditComplete) => {
      if (!success || !rag_output || !isIdInUrl(agent_session_id)) return;

      switch (task) {
        case "soc2_type2_audit":
        case "soc2_type2":
        case "soc2_type1":
          await processFileForSOC2Type2Audit(
            rag_output.file_url,
            agent_session_id,
          );
          break;
        case "dora_assessment":
          await processFileForDoraAudit(rag_output.file_url, agent_session_id);
          break;
        case "nist_csf_2_risk_assessment":
          await processFileForRiskReview(rag_output.file_url, agent_session_id);
          break;

        case "multi_file_vendor_assessment":
          await processFileForVendorAssessmentResponse(
            rag_output.file_url,
            agent_session_id,
          );
          break;
      }
    },
    [],
  );

  useEffect(() => {
    addListener<QAStructureCompletedResponse>(
      "task:qa:structure:complete",
      (data) => void onQAStructureComplete(data),
    );
    addListener<QAReviewResponseCompletedResponse>(
      "task:qa:filler:complete",
      (data) => void onQAReviewResponseComplete(data),
    );
    addListener<RagAuditComplete>(
      "task:rag:complete",
      (data) => void onRagAuditComplete(data),
    );

    return () => {
      removeListener<QAStructureCompletedResponse>(
        "task:qa:structure:complete",
        (data) => void onQAStructureComplete(data),
      );
      removeListener<QAReviewResponseCompletedResponse>(
        "task:qa:filler:complete",
        (data) => void onQAReviewResponseComplete(data),
      );
    };
  }, [
    addListener,
    onQAReviewResponseComplete,
    onQAStructureComplete,
    removeListener,
    onRagAuditComplete,
  ]);

  return {
    isConnected,
  };
};
