import { AgentSessionType } from '@/modules/sessions/types';
import { addNotification } from '@/shared/states/notification';
/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import { NavigateFunction } from 'react-router-dom';
// import { handleEndDoraSession } from '../use-cases/dora.use-case';
import { AGENT_TYPES } from '../types/index.ts';
import { handleEndDoraSession } from '../use-cases/dora.use-case';
import { handleEndRiskSession } from '../use-cases/risk.use-case';
import { handleEndSoc2Session } from '../use-cases/soc2.use-case';
import { handleEndVendorAssessment } from '../use-cases/vendor.use-case';
/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
// import { handleEndDoraSession } from '../use-cases/dora.use-case';

export const handleEndSession = async (
  sessionId: string,
  sessionType: AGENT_TYPES | AgentSessionType,
  navigate: NavigateFunction,
  setLoading?: (loading: boolean) => void,
) => {
  try {
    setLoading?.(true);
    switch (sessionType) {
      case AGENT_TYPES.RISK_ASSESSMENT:
        await handleEndRiskSession(sessionId, navigate);
        break;
      case AGENT_TYPES.GAP_ASSESSMENT:
        await handleEndSoc2Session(sessionId, navigate);
        break;
      case AgentSessionType.DORA_GAP:
        await handleEndDoraSession(sessionId, navigate);
        break;
      case AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT:
        await handleEndVendorAssessment(sessionId, navigate);
        break;
      default:
        throw new Error("Invalid session type");
    }
  } catch (error) {
    console.error(error);
    addNotification({
      type: "error",
      message: "Error in ending the session",
      title: "Error",
    });
  } finally {
    setLoading?.(false);
  }
};
