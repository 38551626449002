import { Selection } from "@nextui-org/react";
import { createSelectorHooks } from "auto-zustand-selectors-hook";
import { create } from "zustand";
import { mutative } from "zustand-mutative";
import {
  doraAssessmentData,
  questionnaireData,
  riskAssessmentData,
} from "../components/agent-logs/agentLogOptions";
import type {
  AgentLogStepsData,
  AgentLogStepsStateData,
} from "../types/sidebar";

interface AgentLogStepStatsActionData extends AgentLogStepsStateData {
  actions: {
    setRiskAssessmentTab: (
      data: Selection | "1" | "2" | "3" | "4" | "5",
    ) => void;
    setDoraAssessmentTab: (
      data: Selection | "1" | "2" | "3" | "4" | "5",
    ) => void;
    setQuestionnaireTab: (
      data: Selection | "1" | "2" | "3" | "4" | "5",
    ) => void;
    setRiskAssessmentStepData: (data?: [] | AgentLogStepsData[]) => void;
    setDoraAssessmentStepData: (data?: [] | AgentLogStepsData[]) => void;
    setQuestionnaireStepData: (data?: [] | AgentLogStepsData[]) => void;
    clearRiskAssessmentSetpData: () => void;
    clearDoraAssessmentSetpData: () => void;
    clearQuestionnaireSetpData: () => void;
    setCompletedSteps: (data: number) => void;
    updateRiskAssessmentStepData: (data: string) => void;
    updateDoraAssessmentStepData: (data: string) => void;
    updateQuestionnaireData: (data: string) => void;
    setIsOpen: (data: boolean) => void;
  };
}

const initialState: Omit<AgentLogStepStatsActionData, "actions"> = {
  riskAssessmentTab: "2",
  doraAssessmentTab: "2",
  questionnaireTab: "1",
  stepData: [],
  completedSteps: 1,
  isOpen: false,
};

export const useAgentLogStepsData = create<AgentLogStepStatsActionData>()(
  mutative((set) => ({
    ...initialState,
    actions: {
      setRiskAssessmentTab: (data: Selection | "1" | "2" | "3" | "4" | "5") =>
        set((state) => {
          state.riskAssessmentTab = data;
        }),
      setDoraAssessmentTab: (data: Selection | "1" | "2" | "3" | "4" | "5") =>
        set((state) => {
          state.doraAssessmentTab = data;
        }),
      setQuestionnaireTab: (data: Selection | "1" | "2" | "3" | "4" | "5") =>
        set((state) => {
          state.questionnaireTab = data;
        }),
      setRiskAssessmentStepData: (
        data: AgentLogStepsData[] = riskAssessmentData,
      ) =>
        set((state) => {
          state.stepData = data;
        }),
      setDoraAssessmentStepData: (
        data: AgentLogStepsData[] = doraAssessmentData,
      ) =>
        set((state) => {
          state.stepData = data;
        }),
      updateRiskAssessmentStepData: (data: string) =>
        set((state) => {
          if (!state.stepData[0]) {
            state.stepData[0] = {} as AgentLogStepsData;
          }
          if (!state.stepData[1]) {
            state.stepData[1] = {} as AgentLogStepsData;
          }
          if (!state.stepData[2]) {
            state.stepData[2] = {} as AgentLogStepsData;
          }
          if (!state.stepData[3]) {
            state.stepData[3] = {} as AgentLogStepsData;
          }
          if (!state.stepData[4]) {
            state.stepData[4] = {} as AgentLogStepsData;
          }
          switch (data) {
            case "GETTING_REVIEW_RESPONSE": {
              state.stepData[1].isCurrent = false;
              state.stepData[1].completed = true;
              if (!state.stepData[2].completed) {
                state.stepData[2].isCurrent = true;
                state.stepData[2].completed = false;
                state.completedSteps = 2;
                state.riskAssessmentTab = "3";
              }
              return;
            }
            case "MAPPING_REVIEW_RESPONSE": {
              state.stepData[2].isCurrent = false;
              state.stepData[1].completed = true;
              state.stepData[2].completed = true;
              if (!state.stepData[3].completed) {
                state.stepData[3].completed = false;
                state.stepData[3].isCurrent = true;
                state.stepData[3].isWaiting = true;
                state.completedSteps = 3;
                state.riskAssessmentTab = "4";
              }
              return;
            }
            case "DOWNLOAD": {
              state.stepData[1].completed = true;
              state.stepData[2].completed = true;
              state.stepData[3].completed = true;
              state.stepData[3].isCurrent = false;
              state.stepData[3].isWaiting = false;
              if (!state.stepData[4].completed) {
                state.stepData[4].completed = false;
                state.stepData[4].isCurrent = true;
                state.completedSteps = 4;
                state.riskAssessmentTab = "5";
              }
              return;
            }
            case "SUCCESS": {
              state.stepData[0].completed = true;
              state.stepData[1].completed = true;
              state.stepData[2].completed = true;
              state.stepData[3].completed = true;
              state.stepData[4].completed = true;
              state.stepData[4].isCurrent = false;
              state.completedSteps = 5;
              state.riskAssessmentTab = "5";
              return;
            }
            default:
              return state.stepData;
          }
        }),
      updateDoraAssessmentStepData: (data: string) =>
        set((state) => {
          if (!state.stepData[0]) {
            state.stepData[0] = {} as AgentLogStepsData;
          }
          if (!state.stepData[1]) {
            state.stepData[1] = {} as AgentLogStepsData;
          }
          if (!state.stepData[2]) {
            state.stepData[2] = {} as AgentLogStepsData;
          }
          if (!state.stepData[3]) {
            state.stepData[3] = {} as AgentLogStepsData;
          }
          if (!state.stepData[4]) {
            state.stepData[4] = {} as AgentLogStepsData;
          }
          switch (data) {
            case "GETTING_REVIEW_RESPONSE": {
              state.stepData[1].isCurrent = false;
              state.stepData[1].completed = true;
              state.stepData[2].isCurrent = true;
              state.stepData[2].completed = false;
              state.completedSteps = 2;
              state.doraAssessmentTab = "3";
              return;
            }
            case "MAPPING_REVIEW_RESPONSE": {
              state.stepData[2].isCurrent = false;
              state.stepData[2].completed = true;
              if (!state.stepData[3].completed) {
                state.stepData[3].isCurrent = true;
                state.stepData[3].completed = false;
                state.stepData[3].isWaiting = true;
                state.completedSteps = 3;
                state.doraAssessmentTab = "4";
              }
              return;
            }
            case "DOWNLOAD": {
              state.stepData[3].isCurrent = false;
              state.stepData[3].completed = true;
              state.stepData[4].isCurrent = true;
              state.stepData[4].completed = false;
              state.completedSteps = 4;
              state.doraAssessmentTab = "5";
              return;
            }
            case "SUCCESS": {
              state.stepData[4].isCurrent = false;
              state.stepData[4].completed = true;
              state.doraAssessmentTab = "5";
              state.completedSteps = 5;
              return;
            }
            default:
              return state.stepData;
          }
        }),
      updateQuestionnaireData: (data: string) =>
        set((state) => {
          if (!state.stepData[0]) {
            state.stepData[0] = {} as AgentLogStepsData;
          }
          if (!state.stepData[1]) {
            state.stepData[1] = {} as AgentLogStepsData;
          }
          if (!state.stepData[2]) {
            state.stepData[2] = {} as AgentLogStepsData;
          }
          if (!state.stepData[3]) {
            state.stepData[3] = {} as AgentLogStepsData;
          }
          if (!state.stepData[4]) {
            state.stepData[4] = {} as AgentLogStepsData;
          }
          if (!state.stepData[5]) {
            state.stepData[5] = {} as AgentLogStepsData;
          }
          switch (data) {
            case "GETTING_QA_STRUCTURE": {
              state.stepData[0].isCurrent = true;
              state.stepData[0].completed = false;
              state.completedSteps = 0;
              state.questionnaireTab = "1";
              return;
            }
            case "MAPPING_QA_STRUCTURE": {
              state.stepData[0].isCurrent = false;
              state.stepData[0].completed = true;
              if (!state.stepData[1].completed) {
                state.stepData[1].isCurrent = true;
                state.stepData[1].completed = false;
                state.stepData[1].isWaiting = true;
                state.completedSteps = 1;
                state.questionnaireTab = "2";
              }
              return;
            }
            case "GETTING_REVIEW_RESPONSE": {
              state.stepData[1].isCurrent = false;
              state.stepData[1].isWaiting = false;
              state.stepData[0].completed = true;
              state.stepData[1].completed = true;
              state.stepData[2].completed = true;
              if (!state.stepData[3].completed) {
                state.stepData[3].isCurrent = true;
                state.stepData[3].completed = false;
                state.completedSteps = 3;
                state.questionnaireTab = "4";
              }
              return;
            }
            case "MAPPING_REVIEW_RESPONSE": {
              state.stepData[0].completed = true;
              state.stepData[1].completed = true;
              state.stepData[2].completed = true;
              state.stepData[3].completed = true;
              state.stepData[3].isCurrent = false;
              if (!state.stepData[4].completed) {
                state.stepData[4].isCurrent = true;
                state.stepData[4].completed = false;
                state.stepData[4].isWaiting = true;
                state.completedSteps = 4;
                state.questionnaireTab = "5";
              }
              return;
            }
            case "DOWNLOAD": {
              state.stepData[0].completed = true;
              state.stepData[1].completed = true;
              state.stepData[2].completed = true;
              state.stepData[3].completed = true;
              state.stepData[4].completed = true;
              state.stepData[4].isCurrent = false;
              if (!state.stepData[5].completed) {
                state.stepData[5].isCurrent = true;
                state.stepData[5].completed = false;
                state.completedSteps = 5;
                state.questionnaireTab = "6";
              }
              return;
            }
            case "SUCCESS": {
              state.stepData[0].completed = true;
              state.stepData[1].completed = true;
              state.stepData[2].completed = true;
              state.stepData[3].completed = true;
              state.stepData[4].completed = true;
              state.stepData[5].isCurrent = false;
              state.stepData[5].completed = true;
              state.questionnaireTab = "6";
              state.completedSteps = 6;
              return;
            }
            default:
              return state.stepData;
          }
        }),
      setQuestionnaireStepData: (
        data: AgentLogStepsData[] = questionnaireData,
      ) =>
        set((state) => {
          state.stepData = data;
        }),
      setCompletedSteps: (data: number) => {
        set((state) => {
          state.completedSteps = data;
        });
      },
      clearRiskAssessmentSetpData: () =>
        set((state) => {
          state.stepData = [];
        }),
      clearDoraAssessmentSetpData: () =>
        set((state) => {
          state.stepData = [];
        }),
      clearQuestionnaireSetpData: () =>
        set((state) => {
          state.stepData = [];
        }),
      setIsOpen: (data: boolean) =>
        set((state) => {
          state.isOpen = data;
        }),
    },
  })),
);

export const useAgentLogState = createSelectorHooks(useAgentLogStepsData);
