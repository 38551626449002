import { ZButton } from "@/shared/components/button";
import { ZDivider } from "@/shared/components/divider";
import { ZInput } from "@/shared/components/input";
import { useStytch } from "@/shared/states/stytch";
import capitalizeFirstLetter from "@/shared/utils/capitalize-first-letter";
import { checkOrganizationName } from "@/shared/utils/organization-helper";
import { ChangeEvent, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useLoginDetails } from "../../states/login";
import { signUpWithNewOrgUseCase } from "../../use-cases/organization.use-case";
import AuthLayout from "../layout";
import LoginCard from "../login-card";

const SignUp = () => {
  const { emailAddress, organizationSlug } = useLoginDetails();
  const [searchParams] = useSearchParams();
  const orgBlank = searchParams.get("orgBlank");

  const [details, setDetails] = useState({
    firstName: "",
    lastName: "",
    email: emailAddress ?? "",
    organization: orgBlank ? "" : capitalizeFirstLetter(organizationSlug ?? ""),
  });
  const [loading, setLoading] = useState(false);

  const stytch = useStytch();

  const emailDisabled = !!emailAddress;

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDetails((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleClickCreateAccount = async () => {
    try {
      if (
        !checkOrganizationName(details.organization, organizationSlug ?? "")
      ) {
        throw new Error("Organization name is not valid");
      }
      setLoading(true);
      await signUpWithNewOrgUseCase({
        name: `${details.firstName} ${details.lastName}`,
        orgName: details.organization,
        stytch,
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthLayout>
      <LoginCard
        title="Sign Up"
        description="Enter your information to create an account"
      >
        <div className="grid gap-4">
          <div className="grid grid-cols-2 gap-4">
            <ZInput
              id="first-name"
              label="First name"
              name="firstName"
              placeholder="John"
              variant="faded"
              value={details.firstName}
              onChange={handleOnChange}
              required
            />
            <ZInput
              id="last-name"
              name="lastName"
              placeholder="Doe"
              label="Last name"
              value={details.lastName}
              onChange={handleOnChange}
              variant="faded"
              required
            />
          </div>
          <ZInput
            id="email"
            name="email"
            type="email"
            label="Email"
            placeholder="johndoe@email.com"
            onChange={handleOnChange}
            value={details.email}
            variant="faded"
            disabled={emailDisabled}
            required
          />
          <ZInput
            id="organization"
            name="organization"
            label="Organization Name"
            placeholder="Acme Inc."
            variant="faded"
            onChange={handleOnChange}
            value={details.organization}
          />
          <ZDivider />
          <ZButton
            type="submit"
            className="w-full text-medium"
            onClick={() => void handleClickCreateAccount()}
            disabled={loading}
            isLoading={loading}
          >
            Create an account
          </ZButton>
        </div>
      </LoginCard>
    </AuthLayout>
  );
};

export default SignUp;
