import { SVGProps } from "react";

const AlertIcon = (props: SVGProps<SVGSVGElement>) => {
  const { color, ...rest } = props;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.9922 16.0001H12.0012M12.0002 13.0001V9.00011M5.32221 9.68311C7.73521 5.41211 8.94221 3.27711 10.5982 2.72711C11.5085 2.42495 12.492 2.42495 13.4022 2.72711C15.0582 3.27711 16.2652 5.41211 18.6782 9.68311C21.0922 13.9531 22.2982 16.0891 21.9372 17.8291C21.7372 18.7871 21.2472 19.6551 20.5352 20.3091C19.2412 21.5001 16.8272 21.5001 12.0002 21.5001C7.17321 21.5001 4.75921 21.5001 3.46521 20.3101C2.75045 19.6501 2.2602 18.7829 2.06321 17.8301C1.70121 16.0901 2.90821 13.9541 5.32221 9.68311Z"
        stroke={color ?? "#71717A"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AlertIcon;
