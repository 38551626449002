import { Spinner } from "@nextui-org/react";

interface AIProgressProps {
  title?: string;
  subtitle?: string;
};

const AIProgress = ({ title, subtitle }: AIProgressProps) => {
  return (
    <div className="bg-white h-full w-full flex items-center justify-center px-4 py-4">
      <div className="flex flex-col items-center gap-3">
        <Spinner size="lg" />
        <h2 className="text-lg">AI is reassessing the controls</h2>
        <h6 className="text-[#71717A] text-sm">This process might take up to 10 minutes. Thank you for your patience</h6>
      </div>
    </div>
  );
};

export default AIProgress;
