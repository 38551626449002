import {
  AgentSessionStep,
  AgentSessionStepType,
  MultiVendorAgentSession,
  MultiVendorProfileStep,
} from "@/modules/sessions/types";
import debounce from "@/shared/utils/debounce";
import { Button } from "@nextui-org/react";
import { Loader } from "lucide-react";
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useVendorIntakeForm } from "../../hooks/useVendorIntakeForm";
import { useAgentSessionData, useAgentStepData } from "../../states";
import { VendorIntakeFormData } from "../../types/vendorIntakeForm";
import { processFileForVendorAssessment } from "../../use-cases/vendor.use-case";
import {
  DataHandlingSection,
  DocumentsSection,
  OverviewSection,
  RiskExposureSection,
} from "./sub-components";

interface IdParams {
  id: string;
}

const VendorAssessmentIntakeForm: React.FC = React.memo(() => {
  const navigate = useNavigate();
  const { id } = useParams<keyof IdParams>() as IdParams;
  const sessiondata = useAgentSessionData(id);
  const stepData = useAgentStepData(id);
  const [isSaving, setIsSaving] = useState(false);

  const initialFormData: VendorIntakeFormData = useMemo(() => {
    const vendorProfileStep = stepData?.find(
      (
        step: AgentSessionStep,
      ): step is AgentSessionStep & { type: AgentSessionStepType } =>
        step.type === AgentSessionStepType.MULTI_VENDOR_PROFILE,
    ) as AgentSessionStep;

    const vendorIntakeForm =
      (vendorProfileStep as MultiVendorProfileStep).data?.vendor_intake_form ??
      ({} as VendorIntakeFormData);

    return {
      vendor_name:
        (sessiondata as MultiVendorAgentSession)?.multi_file_vendor_assessment
          ?.vendor_name ?? "",
      integration_scope:
        (sessiondata as MultiVendorAgentSession)?.multi_file_vendor_assessment
          ?.scope_of_integration || " ",
      vendor_usage_description: vendorIntakeForm.vendor_usage_description || "",
      vendor_ai_ml_usage: vendorIntakeForm.vendor_ai_ml_usage || "No",
      compliance_certifications:
        vendorIntakeForm.compliance_certifications || [],
      collected_data_types: vendorIntakeForm.collected_data_types || {
        data_types: [],
        other_data_types: "",
      },
      data_access_scope: vendorIntakeForm.data_access_scope || {
        scope: "",
        other_scope: "",
      },
      data_storage: vendorIntakeForm.data_storage || [],
      business_criticality: vendorIntakeForm.business_criticality || "",
      incidents_in_last_36_months:
        vendorIntakeForm.incidents_in_last_36_months || "",
      system_access_level: vendorIntakeForm.system_access_level || "",
      vendor_submitted_files: vendorIntakeForm.vendor_submitted_files || [],
      customer_added_certifications:
        vendorIntakeForm.customer_added_certifications || [],
    };
  }, [sessiondata]);

  const {
    formData,
    signedUrl, // Get signedUrl from the hook
    handleSwitchChange,
    handleCheckboxChange,
    handleSelectChange,
    updateSignedUrl,
    handleOtherValueChange,
    handleVendorUsageDescriptionChange,
    handleAddCustomCertification,
  } = useVendorIntakeForm(initialFormData);

  const [isThereFileUploadError, setIsThereFileUploadError] = useState(true);

  const isFormFilled = useMemo(() => {
    return (
      formData.vendor_name &&
      formData.integration_scope &&
      formData.vendor_usage_description.trim().length > 0 &&
      formData.vendor_ai_ml_usage &&
      formData.compliance_certifications.length > 0 &&
      formData.collected_data_types.data_types.length > 0 &&
      formData.data_access_scope.scope &&
      formData.data_storage.length > 0 &&
      formData.business_criticality &&
      formData.incidents_in_last_36_months &&
      formData.system_access_level &&
      formData.vendor_submitted_files.length > 0 &&
      !isThereFileUploadError
    );
  }, [formData, isThereFileUploadError]);

  const saveFormData = useCallback(
    debounce(() => {
      setIsSaving(true);
      void new Promise((resolve) => setTimeout(resolve, 1000)).then(() => {
        setIsSaving(false);
      });
    }, 500),
    [formData],
  );

  useEffect(() => {
    const saveTimeout = setTimeout(() => {
      if (formData && Object.keys(formData).length > 0) {
        saveFormData();
      }
    }, 0);

    return () => clearTimeout(saveTimeout);
  }, [formData, saveFormData]);

  const handleSubmit = async () => {
    try {
      setIsSaving(true);
      await processFileForVendorAssessment(id, navigate);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="w-full h-full bg-[#FAFAFA] bg-opacity-90 flex flex-col items-center">
      <div className="flex px-16 py-6 justify-between items-start border-b w-full bg-[#FEFEFE]">
        <div className="flex flex-col gap-2">
          <span className="font-semibold text-2xl">
            {(sessiondata as any).multi_file_vendor_assessment?.vendor_name}
          </span>
          <span className="text-sm text-[#71717A]">
            Complete vendor profile and add documents to start assessment
          </span>
        </div>

        <div className="flex items-start gap-5">
          <div className="flex items-center h-7 gap-1">
            <Loader
              className={`w-4 h-4 animate-spin ${isSaving ? "" : "invisible"}`}
            />
            {isSaving && (
              <p className="text-tiny text-[#A5A5A5] text-[10px]">
                Auto-saving
              </p>
            )}
          </div>
          <Button
            color="primary"
            type="submit"
            className="rounded-md text-sm leading-5 h-7 w-[144px]"
            isDisabled={!isFormFilled}
            onClick={() => {
              void handleSubmit();
            }}
          >
            Start Assessment
          </Button>
        </div>
      </div>
      <div className="px-16 py-4 flex-1 w-full overflow-y-auto">
        <div className="bg-[#FEFEFE] w-full rounded-lg p-6 flex flex-col gap-12">
          <OverviewSection
            formData={formData}
            handleVendorUsageDescriptionChange={
              handleVendorUsageDescriptionChange
            }
            handleSwitchChange={handleSwitchChange}
            handleCheckboxChange={handleCheckboxChange}
            handleAddCustomCertification={handleAddCustomCertification}
          />

          <DataHandlingSection
            formData={formData}
            handleSelectChange={handleSelectChange}
            handleOtherValueChange={handleOtherValueChange}
          />
          <RiskExposureSection
            formData={formData}
            handleSelectChange={handleSelectChange}
          />
          <DocumentsSection
            updateSignedUrl={updateSignedUrl}
            setIsThereFileUploadError={setIsThereFileUploadError}
            initialFiles={formData.vendor_submitted_files.map(
              (fileName, index) => ({
                id: `file-${index}`,
                file: new File([], fileName),
                preview: "",
                status: "success",
                progressBar: false,
                type: "",
                signedUrl: signedUrl[index] || "",
              }),
            )}
          />
        </div>
      </div>
    </div>
  );
});

VendorAssessmentIntakeForm.displayName = "VendorAssessmentIntakeForm";

export default VendorAssessmentIntakeForm;
