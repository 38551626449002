import { VENDOR_PREDEFINED_COMPLIANCE_CERTS } from "@/modules/agent/constants/vendorAssessmentConstants";
import { ZTooltip } from "@/shared/components/ZTooltip/ZTooltip";
import ZAvatarGradient from "@/shared/components/avatarGradient";
import { Avatar, Tooltip } from "@nextui-org/react";
import { OverviewCardPropsForCompliance } from "./OverviewCards.types";

type Props = {};

const ComplianceAdherenceView = (props: OverviewCardPropsForCompliance) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-2 items-center flex-wrap">
        {props.values.map((certName) => {
          const img = VENDOR_PREDEFINED_COMPLIANCE_CERTS.find(
            (cert) => cert.label.toLowerCase() === certName.toLowerCase(),
          )?.logo;

          return (
            <ZTooltip
              key={certName}
              content={certName}
              placement="top"
              delay={0}
              isDisabled={false}
              showArrow={true}
            >
              <div>
                {img ? (
                  <Avatar
                    size="lg"
                    name={certName}
                    src={img}
                    className="max-w-8 max-h-8"
                  />
                ) : (
                  <ZAvatarGradient
                    name={certName}
                    textSize={14}
                    size={32}
                    backgroundColor="#E4E4E7"
                    shadow="shadow-none"
                    textColor="text-[#171717]"
                  />
                )}
              </div>
            </ZTooltip>
          );
        })}
      </div>
    </div>
  );
};

export default ComplianceAdherenceView;
