import { cn } from "@/shared/utils/classname-merger";
import {
  Card,
  CardBody,
  CardFooter,
  CardFooterProps,
  CardHeader,
  CardProps,
} from "@nextui-org/card";
import { HTMLAttributes, forwardRef } from "react";

const ZCard = ({ children, ...props }: CardProps) => {
  return <Card {...props}>{children}</Card>;
};

const ZCardHeader = CardHeader;

const ZCardBody = CardBody;

const ZCardFooter = ({ children, ...props }: CardFooterProps) => {
  return <CardFooter {...props}>{children}</CardFooter>;
};

const ZCardTitle = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h3
    ref={ref}
    className={cn("font-semibold leading-none tracking-tight", className)}
    {...props}
  />
));
ZCardTitle.displayName = "ZCardTitle";

const ZCardDescription = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn("text-sm text-muted-foreground", className)}
    {...props}
  />
));
ZCardDescription.displayName = "ZCardDescription";

export {
  ZCard,
  ZCardHeader,
  ZCardBody,
  ZCardFooter,
  ZCardTitle,
  ZCardDescription,
};
