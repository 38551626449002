import {
  AgentSubTypes,
  ConfidenceTypes,
  FilterCategory,
  FilterOperators,
  FilterSelectionType,
  GapAssessmentTypes,
  MultiVendorAssessmentTypes,
  QuestionnaireTypes,
  RiskAssessmentTypes,
} from "../types/index.ts";

export const ConfidenceFilters: FilterCategory = {
  category: "Response Type",
  type: FilterSelectionType.CHECKBOX,
  key: "confidence",
  options: [
    {
      key: "confidence",
      id: "high-confidence",
      label: "High Confidence",
      value: ConfidenceTypes.HIGH,
      className:
        'border rounded before:content-[""] before:w-2 before:h-2 before:bg-[#17C964] before:rounded-full before:mr-2',
      operator: FilterOperators.EQUALS,
    },
    {
      key: "confidence",
      id: "medium-confidence",
      label: "Medium Confidence",
      value: ConfidenceTypes.MEDIUM,
      className:
        'border rounded before:content-[""] before:w-2 before:h-2 before:bg-[#F5A524] before:rounded-full before:mr-2',
      operator: FilterOperators.EQUALS,
    },
    {
      key: "confidence",
      id: "low-confidence",
      label: "Low Confidence",
      value: ConfidenceTypes.LOW,
      className:
        'border rounded before:content-[""] before:w-2 before:h-2 before:bg-[#F4286F] before:rounded-full before:mr-2',
      operator: FilterOperators.EQUALS,
    },
    {
      key: "confidence",
      id: "data-not-found",
      label: "Data Not Found",
      value: ConfidenceTypes.DNF,
      className:
        'border rounded before:content-[""] before:w-2 before:h-2 before:bg-[#D4D4D8] before:rounded-full before:mr-2',
      operator: FilterOperators.EQUALS,
    },
  ],
};

export const StatusFilters: FilterCategory = {
  category: "Status",
  type: FilterSelectionType.CHECKBOX,
  key: "status",
  options: [
    {
      key: "status",
      id: "accepted",
      label: "Accepted",
      value: "accepted",
      operator: FilterOperators.EQUALS,
    },
    {
      key: "status",
      id: "edited",
      label: "Edited",
      value: "edited",
      operator: FilterOperators.EQUALS,
    },
  ],
};

export const RiskFilters: FilterCategory[] = [
  {
    category: "Function",
    type: FilterSelectionType.CHECKBOX,
    key: "tag",
    options: [
      {
        key: "tag",
        id: "govern",
        label: "Govern (GV)",
        value: "gv",
        className: "bg-[#F9F49D]",
        operator: FilterOperators.CONTAINS,
      },
      {
        key: "tag",
        id: "identify",
        label: "Identify (ID)",
        value: "id",
        className: "bg-[#4BB2E0]",
        operator: FilterOperators.CONTAINS,
      },
      {
        key: "tag",
        id: "protect",
        label: "Protect (PR)",
        value: "pr",
        className: "bg-[#9292EA]",
        operator: FilterOperators.CONTAINS,
      },
      {
        key: "tag",
        id: "detect",
        label: "Detect (DE)",
        value: "de",
        className: "bg-[#FAB746]",
        operator: FilterOperators.CONTAINS,
      },
      {
        key: "tag",
        id: "respond",
        label: "Respond (RS)",
        value: "rs",
        className: "bg-[#F97367]",
        operator: FilterOperators.CONTAINS,
      },
      {
        key: "tag",
        id: "recover",
        label: "Recover (RC)",
        value: "rc",
        className: "bg-[#7DF49F]",
        operator: FilterOperators.CONTAINS,
      },
    ],
  },
  // {
  //   category: "Risk Value",
  //   type: FilterSelectionType.CHECKBOX,
  //   key: "riskValue",
  //   options: [
  //     {
  //       key: "riskValue",
  //       label: "1",
  //       id: "1",
  //       value: { min: 1, max: 1 }, // equal was not working in case so used between only
  //       className: "text-[#74DFA2] bg-[#E8FAF0] rounded-full",
  //       operator: FilterOperators.BETWEEN,
  //     },
  //     {
  //       key: "riskValue",
  //       label: "2",
  //       id: "2",
  //       value: { min: 2, max: 2 },
  //       className: "text-[#17C964] bg-[#D1F4E0] rounded-full",
  //       operator: FilterOperators.BETWEEN,
  //     },
  //     {
  //       key: "riskValue",
  //       label: "3",
  //       id: "3",
  //       value: { min: 3, max: 3 },
  //       className: "text-[#F5A524] bg-[#FDEDD3] rounded-full",
  //       operator: FilterOperators.BETWEEN,
  //     },
  //     {
  //       key: "riskValue",
  //       label: "4",
  //       id: "4",
  //       value: { min: 4, max: 4 },
  //       className: "text-[#F31260] bg-[#FDD0DF] rounded-full",
  //       operator: FilterOperators.BETWEEN,
  //     },
  //     {
  //       key: "riskValue",
  //       label: "5",
  //       id: "5",
  //       value: { min: 5, max: 5 },
  //       className: "text-[#920B3A] bg-[#FAA0BF] rounded-full",
  //       operator: FilterOperators.BETWEEN,
  //     },
  //   ],
  // },
  {
    category: "Maturity Tier",
    type: FilterSelectionType.CHECKBOX,
    key: "maturity",
    options: [
      {
        key: "maturity",
        label: "1",
        id: "tier-1",
        value: 'Tier 1', // equal was not working in case so used between only
        className: "text-[#920B3A] bg-[#FAA0BF] rounded-full",
        operator: FilterOperators.CONTAINS,
      },
      {
        key: "maturity",
        label: "2",
        id: "tier-2",
        value: 'Tier 2',
        className: "text-[#F31260] bg-[#FDD0DF] rounded-full",
        operator: FilterOperators.CONTAINS,
      },
      {
        key: "maturity",
        label: "3",
        id: "tier-3",
        value: 'Tier 3',
        className: "text-[#F5A524] bg-[#FDEDD3] rounded-full",
        operator: FilterOperators.CONTAINS,
      },
      {
        key: "maturity",
        label: "4",
        id: "tier-4",
        value: 'Tier 4',
        className: "text-[#17C964] bg-[#D1F4E0] rounded-full",
        operator: FilterOperators.CONTAINS,
      },
    ],
  },
  ConfidenceFilters,
  StatusFilters,
];

export const ComplianceFilter: FilterCategory = {
  category: "Compliance Status",
  type: FilterSelectionType.CHECKBOX,
  key: "compliant",
  options: [
    {
      key: "compliant",
      label: "Fully Compliant",
      id: "Fully-Compliant",
      value: "Fully-Compliant",
      operator: FilterOperators.EQUALS,
    },
    {
      key: "compliant",
      label: "Partially Compliant",
      id: "Partially-Compliant",
      value: "Partially-Compliant",
      operator: FilterOperators.EQUALS,
    },
    {
      key: "compliant",
      label: "Non Compliant",
      id: "Non-Compliant",
      value: "Non-Compliant",
      operator: FilterOperators.EQUALS,
    },
    {
      key: "compliant",
      label: "Data Not Found",
      id: "Data-Not-Found",
      value: "Data-Not-Found",
      operator: FilterOperators.EQUALS,
    },
  ],
};

export const NewComplianceFilter: FilterCategory = {
  category: "Compliance Status",
  type: FilterSelectionType.CHECKBOX,
  key: "compliant",
  options: [
    {
      key: "compliant",
      label: "No Exceptions Noted",
      id: "No-Exceptions-Noted",
      value: "No-Exceptions-Noted",
      operator: FilterOperators.EQUALS,
    },

    {
      key: "compliant",
      label: "Exception Noted",
      id: "Exception-Noted",
      value: "Exception-Noted",
      operator: FilterOperators.EQUALS,
    },
    {
      key: "compliant",
      label: "Data Not Found",
      id: "Data-Not-Found",
      value: "Data-Not-Found",
      operator: FilterOperators.EQUALS,
    },
  ],
};

type FilterConfig = {
  [key in AgentSubTypes]: FilterCategory[];
};

export const FilterConfig: FilterConfig = {
  [RiskAssessmentTypes.NIST_CSF_2]: RiskFilters,
  [QuestionnaireTypes.QA]: [ConfidenceFilters, StatusFilters],
  [GapAssessmentTypes.SOC2]: [
    ComplianceFilter,
    ConfidenceFilters,
    StatusFilters,
  ],
  [GapAssessmentTypes.SOC2_TYPE1]: [
    NewComplianceFilter,
    ConfidenceFilters,
    StatusFilters,
  ],
  [GapAssessmentTypes.SOC2_TYPE2]: [
    NewComplianceFilter,
    ConfidenceFilters,
    StatusFilters,
  ],
  [GapAssessmentTypes.DORA]: [
    ComplianceFilter,
    ConfidenceFilters,
    StatusFilters,
  ],
  [MultiVendorAssessmentTypes.VENDOR_ASSESSMENT]: [
    ComplianceFilter,
    ConfidenceFilters,
    StatusFilters,
  ],
  // the following is really NOT NEEDED, just here to keep tsc happy,
  // without having to change the type to a Partial<>
  [MultiVendorAssessmentTypes.VENDOR_INTAKE_FORM]: [],
  [MultiVendorAssessmentTypes.VENDOR_SUPPLY_CHAIN_TABLE]: [],
};
