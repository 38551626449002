import { useEffect } from "react";

type PromiseVoidFunction = () => Promise<void>;

const useEffectAsync = (
  callback: PromiseVoidFunction,
  deps: Array<unknown>,
) => {
  // biome-ignore lint/correctness/useExhaustiveDependencies: <Callback is not required in deps>
  useEffect(() => {
    callback();
  }, [...deps]);
};

export default useEffectAsync;
