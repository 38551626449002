import { Tooltip, TooltipProps } from "@nextui-org/tooltip";
import React, { useRef, useState, useEffect } from "react";

// This is a wrapper around the Next UI Tooltip component with some preset defaults and styling,
// IMPORTANT: the tooltip is only shown if the childElement is truncated (i.e, scrollWidth > clientWidth)
// If you would like the tooltip to always show, regardless, you need to pass isDisabled={false}

const ZTooltip = ({ children, ...props }: TooltipProps) => {
  const childRef = useRef<HTMLElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    const checkForTruncation = () => {
      if (childRef.current) {
        const { scrollWidth, clientWidth } = childRef.current;
        setIsTruncated(scrollWidth > clientWidth);
      }
    };

    checkForTruncation();
    window.addEventListener("resize", checkForTruncation);

    return () => {
      window.removeEventListener("resize", checkForTruncation);
    };
  }, [children]);

  return (
    <Tooltip
      isDisabled={!isTruncated}
      delay={500}
      closeDelay={0}
      classNames={{
        content:
          "bg-black bg-opacity-80 backdrop-blur-md text-white text-tiny max-w-[600px]",
        arrow: "bg-black bg-opacity-80 backdrop-blur-md",
      }}
      {...props}
    >
      {React.cloneElement(children as React.ReactElement, { ref: childRef })}
    </Tooltip>
  );
};

export { ZTooltip };
