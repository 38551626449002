import { ComplianceStatus, ConfidenceTypes, ReviewSourceTypes, SOC2ComplianceStatusDeprecated } from ".";

export enum RiskAgentFrameworks {
  NIST_CSF_2 = "nist-csf-2",
}

export enum RiskValue {
  VERY_LOW = "0-1",
  LOW = "1-2",
  MEDIUM = "2-3",
  HIGH = "3-4",
  VERY_HIGH = "4-5",
}

export type RiskReviewResponse = NISTCSF2Response;

export interface NISTCSF2Response {
  function: string;
  category: string;
  subcategory: string;
  questions: string;
  sources: ReviewSourceTypes[];
  confidence: string;
  justification: string;
  observations: string;
  gaps: string;
  improvement_opportunities: string;
  risk_statement: string;
  recommendations: string;
  improvement_recommendations: string;
  risk_value: number;
  impact?: number;
  likelihood?: number;
  impact_label?: string;
  likelihood_label?: string;
  control_id?: string;
  zania_control_id?: string;
  version?: ResponseSchemaVersion;
  current_maturity_level?: string;
  description_of_current_maturity_level?: string;
  path_to_next_maturity_level?: string;
}

export type ResponseSchemaVersion = "v1" | "v2";

export interface NISTCSF2LLMResponseDist {
  observations?: string;
  gaps?: string;
  risk_statement?: string;
  risk_value?: number;
  reasoning_for_risk_value?: string;
  improvement_recommendations?: string;
  improvement_opportunities?: string;
  recommendations?: string;
  confidence?: ConfidenceTypes;
  justification?: string;
  sources?: ReviewSourceTypes[];
}

export interface TestResultsValue {
  test: string;
  result: string;
  sources: ReviewSourceTypes[];
}

export interface SOC2GapReviewResponse {
  id?: string;
  control_id?: string;
  zania_control_id?: string;
  sources?: ReviewSourceTypes[];
  confidence?: ConfidenceTypes;
  justification?: string;
  observations?: string;
  gaps?: string;
  recommendations?: string;
  improvement_opportunities?: string;
  improvement_recommendations?: string;
  compliance_status?: SOC2ComplianceStatusDeprecated;
  trust_id?: string;
  tsc?: string;
  tsc_id?: string;
  control_number?: number;
  control?: string;
  category?: string;
  assessment_criteria?: string[];
  retriever_questions?: string[];
  llm_instructions?: string;
  test_results?: TestResultsValue[];
  toe?: string;
  tod?: string;
  criteria?: string;
  version?: ResponseSchemaVersion;
}

export interface DoraReviewResponse {
  id?: string;
  control_id?: string;
  zania_control_id?: string;
  sources: ReviewSourceTypes[];
  confidence: ConfidenceTypes;
  justification: string;
  observations: string;
  gaps: string;
  recommendations: string;
  requirement: string;
  requirement_id?: number;
  compliance_status?: ComplianceStatus;
  assessment_criteria?: string[];
  questions: string[];
  chapter: string;
  article: string;
}

export interface GetRiskExcelFromJSONRequest {
  json_url: string;
  framework: string;
  client_id: string;
  response_quality: string;
}

export interface GetRiskExcelFromJSONResponse {
  success: boolean;
  message: string;
  data: {
    file_url: string;
  };
}
