import usePrevious from "@/shared/hooks/use-previous";
import { getSignedUrl } from "@/shared/requests/get-signed-url";
import { deleteFileReq, uploadFileReq } from "@/shared/requests/upload-file";
import { addNotification } from "@/shared/states/notification";
import { useIsDemoActive } from "@/shared/states/user";
import { UploadProgress, UploadedFile } from "@/shared/types/file-upload";
import { cn } from "@/shared/utils/classname-merger";
import { Accordion, AccordionItem, Spinner } from "@nextui-org/react";
import { AxiosProgressEvent } from "axios";
import { FileSpreadsheetIcon, RotateCw, Trash, Upload } from "lucide-react";
import React, {
  ChangeEvent,
  DragEvent,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid"; // Add this import
import { ZButton } from "../../button";
import { ZProgress } from "../../progress";

export const simplifyFileTypes = (types: string[]): string[] => {
  const typeMap: { [key: string]: string } = {
    "application/json": "JSON",
    "application/msword": "Word",
    "application/pdf": "PDF",
    "application/vnd.ms-excel": "Excel",

    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      "Excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.template":
      "Excel",
    "application/vnd.ms-excel.sheet.macroEnabled.12": "Excel",
    "application/vnd.ms-excel.template.macroEnabled.12": "Excel",
    "application/vnd.ms-excel.addin.macroEnabled.12": "Excel",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      "Word",
    "image/jpeg": "JPEG",
    "image/png": "PNG",
    "text/csv": "CSV",
    // 'application/atom+xml': 'Atom',
    // 'application/javascript': 'JavaScript',
    // 'application/rss+xml': 'RSS',
    // 'application/vnd.ms-excel.sheet.binary.macroEnabled.12': 'Excel Binary',
    // 'application/vnd.ms-powerpoint': 'PowerPoint',
    // 'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PowerPoint',
    // 'application/xhtml+xml': 'XHTML',
    // 'application/xml': 'XML',
    // 'application/xml-dtd': 'DTD',
    // 'audio/midi': 'MIDI',
    // 'audio/mpeg': 'MP3',
    // 'audio/wav': 'WAV',
    // 'image/bmp': 'Bitmap',
    // 'image/gif': 'GIF',
    // 'image/svg+xml': 'SVG',
    // 'text/css': 'CSS',
    // 'text/html': 'HTML',
    // 'text/javascript': 'JavaScript',
    // 'text/markdown': 'Markdown',
    // 'text/plain': 'Plain Text',
    // 'text/xml': 'XML',
    // 'video/mp4': 'MP4',
    // 'video/quicktime': 'QuickTime',
    // 'video/webm': 'WebM',
  };

  if (types.length === 0) {
    return [...new Set(Object.keys(typeMap))];
  }

  return [...new Set(types.map((type) => typeMap[type] || type))];
};

interface FileUploadProps {
  isMultiple: boolean;
  maxFiles: number;
  allowedFileTypes: string[];
  setFiles?: (uploadFiles: UploadedFile[], type: string) => void;
  type?: string;
  orientation?: "vertical" | "horizontal";
  isShowNewFilesSection?: boolean
}

const FileUpload = forwardRef<HTMLDivElement, FileUploadProps>(
  (
    {
      isMultiple,
      maxFiles,
      allowedFileTypes,
      setFiles,
      type,
      orientation = "vertical",
      isShowNewFilesSection = false
    },
    ref,
  ) => {
    const [uploadFiles, setUploadFiles] = useState<UploadedFile[]>([]);
    const prevUploadFiles = usePrevious(uploadFiles) || [];
    const [uploadProgress, setUploadProgress] = useState<UploadProgress>({});
    const inputRef: React.RefObject<HTMLInputElement> = useRef(null);
    const isDemo = useIsDemoActive();

    const isFileTooLarge = (file: File, megaBytes = 20) => {
      const sizeLimit = megaBytes * 1024 * 1024;
      return file.size > sizeLimit;
    };

    useEffect(() => {
      if (setFiles && prevUploadFiles !== uploadFiles) {
        setFiles(uploadFiles, type ?? "");
      }
    }, [uploadFiles, setFiles, prevUploadFiles, type]);

    const handleFiles = async (uploadedFiles: FileList) => {
      let counter = uploadFiles.length;
      let shouldShowFileTooLargeNotification = false;
      const largeFileNames: string[] = [];

      const files: UploadedFile[] = Array.from(uploadedFiles).map((file) => {
        counter = counter + 1;
        if (counter > maxFiles) {
          return {
            id: uuidv4(),
            file,
            preview: URL.createObjectURL(file),
            status: "too_many_files",
            progressBar: false,
            type,
          };
        }

        const fileTooLarge = isFileTooLarge(file);
        shouldShowFileTooLargeNotification =
          shouldShowFileTooLargeNotification || fileTooLarge;

        if (fileTooLarge) {
          largeFileNames.push(file.name);
        }

        return {
          id: uuidv4(),
          file,
          preview: URL.createObjectURL(file),
          status: fileTooLarge ? "too_large" : "gettingurl",
          progressBar: false,
          type,
        };
      });

      const fileName = new Set<string>(
        uploadFiles.map((oldFile) => oldFile.file.name),
      );
      for (let file of files) {
        if (fileName.has(file.file.name)) {
          file.status = "duplicate_files";
        } else {
          fileName.add(file.file.name);
        }
      }

      // uploadFiles.push(...files);
      const newUploadFiles = [...files];

      if (maxFiles && uploadFiles.length >= maxFiles) {
        addNotification({
          type: "warn",
          title: "Too many files!",
          message: `You can only upload up to ${maxFiles} for this section. Please remove ${uploadFiles.length - maxFiles} files to proceed.`,
        });
      }

      if (shouldShowFileTooLargeNotification) {
        addNotification({
          type: "error",
          title: "File size limit exceeded!",
          message:
            largeFileNames.length > 1
              ? `${largeFileNames.length} files exceed the maximum file size of 20mb. Please remove them to proceed.`
              : `The file ${largeFileNames[0]} exceeds the maximum file size of 20mb. Please remove it to proceed.`,
        });
      }

      const validFilesArray = newUploadFiles.filter(
        (fileObj: UploadedFile) => fileObj.status === "gettingurl",
      );
      const invalidFilesArray = newUploadFiles.filter(
        (fileObj: UploadedFile) => fileObj.status !== "gettingurl",
      );

      await Promise.all(
        validFilesArray.map(async (fileObj) => {
          try {
            const url = await getSignedUrl({
              file_names: [fileObj.file.name],
              ...(isDemo
                ? {
                  max_age: 120,
                }
                : {}),
            });
            fileObj.signedUrl = url[0];
            fileObj.status = "inprogress";
            setUploadFiles((prevFiles) => [
              ...prevFiles,
              {
                ...fileObj,
                status: prevFiles.find(
                  (fil) => fil.file.name === fileObj.file.name,
                )
                  ? "duplicate_files"
                  : fileObj.status,
              },
            ]);
            setUploadProgress((prevProgress) => ({
              ...prevProgress,
              [fileObj.file.name]: 0,
            }));

            await uploadFile(fileObj);
          } catch (e) {
            console.error(e);
            setUploadFiles((prevFiles) => [
              ...prevFiles,
              { ...fileObj, status: "failed" },
            ]);
            setUploadProgress((prevProgress) => {
              const newProgress = { ...prevProgress };
              delete newProgress[fileObj.file.name];
              return newProgress;
            });
          }
        }),
      );
      setUploadFiles((prev) => [...prev, ...invalidFilesArray]);
    };

    const handleRetry = async (fileObj: UploadedFile) => {
      try {
        fileObj.status = "retrying";
        setUploadFiles((prevFiles) => [
          ...prevFiles.map((f) => (f.id === fileObj.id ? { ...fileObj } : f)),
        ]);
        const url = await getSignedUrl({
          file_names: [fileObj.file.name],
        });
        fileObj.signedUrl = url[0];
        fileObj.status = "inprogress";
        setUploadFiles((prevFiles) => [
          ...prevFiles.map((f) => (f.id === fileObj.id ? { ...fileObj } : f)),
        ]);
        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          [fileObj.file.name]: 0,
        }));

        await uploadFile(fileObj);
      } catch (e) {
        console.error(e);
        setUploadFiles((prevFiles) =>
          prevFiles.map((f) =>
            f.id === fileObj.id ? { ...fileObj, status: "failed" } : f,
          ),
        );
        addNotification({
          message: "Error in uploading the file",
          type: "error",
        });
        setUploadProgress((prevProgress) => {
          const newProgress = { ...prevProgress };
          delete newProgress[fileObj.file.name];
          return newProgress;
        });
      }
    };

    const handleDrop = (e: DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();

      document.getElementById("drop-area")?.classList.remove("bg-[#F9FAFB]");

      const { files } = e.dataTransfer;
      void handleFiles(files);
    };

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
      const { files } = e.target;
      if (files) {
        void handleFiles(files);
        e.target.value = "";
      }
    };

    const uploadFile = async (fileObj: UploadedFile) => {
      const name = fileObj.file.name;
      if (!fileObj.signedUrl) {
        return;
      }
      try {
        await uploadFileReq(
          fileObj.signedUrl,
          fileObj.file,
          (progressEvent: AxiosProgressEvent) => {
            if (progressEvent.total) {
              const progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total,
              );
              setUploadProgress((prevProgress) => ({
                ...prevProgress,
                [name]: progress,
              }));
            }
          },
        );
        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          [name]: -1,
        }));
        setUploadFiles((prevFiles) =>
          prevFiles.map((f) =>
            f.file.name === name
              ? {
                ...f,
                status:
                  f.status === "duplicate_files"
                    ? "duplicate_files"
                    : "success",
              }
              : f,
          ),
        );
      } catch (error) {
        console.error(error);
        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          [name]: 100,
        }));

        setTimeout(() => {
          setUploadFiles((prevFiles) =>
            prevFiles.map((f) =>
              f.file.name === name ? { ...f, status: "failed" } : f,
            ),
          );
          setUploadProgress((prevProgress) => ({
            ...prevProgress,
            [name]: -1,
          }));
        }, 1000);
      }
    };

    const removeFile = (fileId: string) => {
      const fileToRemove = uploadFiles.find((f) => f.id === fileId);
      if (!fileToRemove) return;

      let removeDuplicateFileFlag = true;
      setUploadFiles((prevFiles) =>
        prevFiles
          .filter(({ id }) => id !== fileId)
          .map((file, idx) => {
            let status = file.status;

            if (
              file.status === "duplicate_files" &&
              fileToRemove.file.name === file.file.name &&
              removeDuplicateFileFlag
            ) {
              status = "success";
              removeDuplicateFileFlag = false;
            }

            if (file.status === "too_many_files" && idx < maxFiles) {
              status = "too_many_files";
            }

            return {
              ...file,
              status,
            };
          }),
      );

      if (fileToRemove.signedUrl) {
        void deleteFileReq(fileToRemove.signedUrl);
      }

      setUploadProgress((prevProgress) => {
        const newProgress = { ...prevProgress };
        delete newProgress[fileToRemove.file.name];
        return newProgress;
      });
    };

    const handleDropFile = () => {
      if (inputRef.current) {
        inputRef?.current.click();
      }
    };

    const doesFileHasError = useCallback((fileObj: UploadedFile) => {
      return (
        fileObj.status === "failed" ||
        fileObj.status === "too_large" ||
        fileObj.status === "too_many_files" ||
        fileObj.status === "duplicate_files"
      );
    }, []);

    const getFileNameSuffix = useCallback((fileObj: UploadedFile) => {
      let fileNameSuffix = "";

      switch (fileObj.status) {
        case "too_large":
          fileNameSuffix = " (too large)";
          break;
        case "too_many_files":
          fileNameSuffix = " (excess)";
          break;
        case "duplicate_files":
          fileNameSuffix = " (duplicate file)";
          break;
        default:
          break;
      }

      return fileNameSuffix;
    }, []);

    const simplifiedFileTypes = simplifyFileTypes(allowedFileTypes);

    const files = uploadFiles.map((fileObj: UploadedFile, index: number) => {
      return (
        <div key={index}>
          <div className="flex items-center justify-between gap-2 mt-0">
            <FileSpreadsheetIcon
              size={16}
              color={
                fileObj.status === "success"
                  ? "text-green-600"
                  : fileObj.status === "failed" ||
                    fileObj.status === "too_large" ||
                    fileObj.status === "too_many_files"
                    ? "text-red-600"
                    : "text-black"
              }
            />
            <div className="flex-1 flex flex-col justify-center ">
              <div className="flex items-center gap-4">
                <p
                  className={cn(
                    "text-sm cursor-pointer hover:underline",
                    fileObj.status === "success"
                      ? "text-green-600"
                      : doesFileHasError(fileObj)
                        ? "text-red-600"
                        : "text-black",
                  )}
                >
                  {fileObj.file.name + getFileNameSuffix(fileObj)}
                </p>
                {fileObj.status === "gettingurl" ? (
                  <Spinner size="sm" />
                ) : null}
              </div>
              <div>
                {fileObj.status !== "success" &&
                  !isNaN(uploadProgress[fileObj.file.name]) &&
                  uploadProgress[fileObj.file.name] != -1 ? (
                  <ZProgress
                    aria-label="file-upload-progress"
                    value={uploadProgress[fileObj.file.name] || 0}
                    maxValue={100}
                    className={cn(
                      "h-[3px] mt-1 [&>*]:" + "bg-blue-600",
                    )}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
            {fileObj.status === "failed" ||
              fileObj.status === "retrying" ? (
              <ZButton
                isIconOnly
                className="bg-transparent"
                onClick={() => void handleRetry(fileObj)}
                isLoading={fileObj.status === "retrying"}
              >
                <RotateCw size={16} color="gray" />
              </ZButton>
            ) : null}
            <ZButton
              isIconOnly
              className="bg-transparent"
              onClick={() => removeFile(fileObj.id)}
            >
              <Trash size={16} color="gray" />
            </ZButton>
          </div>
        </div>
      );
    });

    return (
      <div
        ref={ref}
        className={cn(orientation === "horizontal" && "flex gap-8")}
      >
        <div
          onDrop={handleDrop}
          id="drop-area"
          onClick={handleDropFile}
          onDragEnter={() => {
            document.getElementById("drop-area")?.classList.add("bg-[#F9FAFB]");
          }}
          onDragOver={(e) => {
            e.preventDefault();
          }}
          className={cn(
            "bg-[#F7F7FA] p-2 flex items-center flex-col justify-center h-32 w-full border-dashed border-[1px] border-gray-300 rounded-md cursor-pointer hover:bg-gray-50",
            orientation === "horizontal" && "w-[calc(50%-10px)]",
          )}
        >
          <input
            type="file"
            ref={inputRef}
            multiple={isMultiple}
            onChange={handleFileChange}
            className="hidden"
            id="file-input"
            accept={allowedFileTypes.join(",")}
          />

          <Upload size={24} />

          <label className="cursor-pointer text-sm mt-4">
            Drag and drop files here or{" "}
            <span className="text-[#006FEE]">browse your computer</span>
          </label>
          <p className="text-xs text-center text-gray-500 mt-2">
            {`(Accepted files: ${maxFiles && maxFiles > 1 ? "Up to" : "Only"} ${maxFiles ?? "30"} file${maxFiles && maxFiles > 1 ? "s" : ""} of ${simplifiedFileTypes.join(", ")} of up to 20mb)`}
          </p>
        </div>
        <div
          className={cn(
            "overflow-auto",
            orientation === "horizontal" && "w-[calc(50%-10px)]",
            orientation === "vertical" && "w-full max-h-[200px]",
            "",
          )}
        >
          {isShowNewFilesSection && files.length > 0 ?
            (<Accordion defaultExpandedKeys={['newFiles']}>
              <AccordionItem key="newFiles" aria-label="new files" title={<span className="text-[14px] font-normal text-zinc-900">Newly Added Files</span>} className="text-[14px] font-normal">
                <div className="max-h-[200px] overflow-y-auto">
                  {files}
                </div>
              </AccordionItem>
            </Accordion>) : <div className="max-h-[200px] overflow-y-auto">
              {files}
            </div>
          }

        </div>
      </div>
    );
  },
);

FileUpload.displayName = "FileUpload";

export default FileUpload;
