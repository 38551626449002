import { useAuthActions } from "@/modules/auth/states";
import { ZButton } from "@/shared/components/button";
import {
  ZModal,
  ZModalContent,
  ZModalFooter,
  ZModalHeader,
} from "@/shared/components/modal";
import { ROUTES } from "@/shared/constants/routes";
import { addNotification } from "@/shared/states/notification";
import { useStytch } from "@/shared/states/stytch";
/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getMember, useTeamState } from "../../states";
import { TeamRole } from "../../types";
import {
  removeTeamMemberUseCase,
  updateTeamMemberUseCase,
} from "../../use-cases/team.use-case";
import { printErrorMessage } from "../../utils";

const TeamOwnerTransferDialog = () => {
  const stytch = useStytch();
  const [loading, setLoading] = useState(false);
  const { memberId } = useParams();

  const member = getMember(memberId as string);
  const { team } = useTeamState();

  const navigate = useNavigate();
  const { logout } = useAuthActions();

  const onCloseDialog = useCallback(() => {
    navigate(`/${ROUTES.TEAM}`, { replace: true });
  }, [navigate]);

  const handleLogout = useCallback(async () => {
    await stytch.session.revoke();
    logout();
  }, [logout, stytch.session]);

  const hasAnotherTeamOwner =
    team.findIndex(
      ({ member_id, roles }) =>
        member?.member_id !== member_id &&
        roles.findIndex(({ role_id }) => role_id === TeamRole.ORG_ADMIN) > -1,
    ) > -1;
  const anotherTeamAdmin = team.find(
    ({ member_id, roles }) =>
      member?.member_id !== member_id &&
      roles.findIndex(({ role_id }) => role_id === TeamRole.ADMIN) > -1,
  );

  useEffect(() => {
    if (
      !memberId ||
      !member ||
      member.roles?.findIndex?.(
        ({ role_id }) => role_id === TeamRole.ORG_ADMIN,
      ) === -1 ||
      !team ||
      team.length === 0 ||
      (!hasAnotherTeamOwner && !anotherTeamAdmin)
    ) {
      onCloseDialog();
    }
  }, [
    memberId,
    member,
    team,
    hasAnotherTeamOwner,
    anotherTeamAdmin,
    onCloseDialog,
  ]);

  const handleOnClickTransfer = async () => {
    setLoading(true);
    try {
      if (!hasAnotherTeamOwner && !anotherTeamAdmin) {
        addNotification({
          type: "error",
          title: "Could not transfer ownership",
          message:
            "This team does not have another owner and also does not have another admin that can be promoted to owner",
        });
        return;
      }
      await updateTeamMemberUseCase({
        stytch,
        updates: {
          memberId: anotherTeamAdmin?.member_id as string,
          override_roles:
            anotherTeamAdmin?.roles
              ?.filter(({ role_id }) => role_id === TeamRole.ADMIN)
              ?.map?.(({ role_id }) => role_id)
              ?.concat?.(TeamRole.ORG_ADMIN as string) ?? ([] as TeamRole[]),
        },
      });
      await removeTeamMemberUseCase({ stytch, memberId: memberId as string });
      addNotification({
        type: "success",
        title: "Team Ownership Transferred",
        message: `${member?.name ?? "You"} have been removed from your team. Team ownership has been transferred.`,
      });
      await handleLogout();
      onCloseDialog();
    } catch (error) {
      console.error(error);
      addNotification({
        type: "error",
        title:
          ((error as Error).cause as string) ??
          `Could not transfer ownership and remove ${member?.name ?? "team member"} from your team`,
        message: printErrorMessage(JSON.stringify(error)),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <ZModal defaultOpen onOpenChange={(isOpen) => !isOpen && onCloseDialog()}>
      <ZModalContent>
        <ZModalHeader className="w-full flex flex-col gap-2">
          <h4 className="text-center">
            Are you sure you want to transfer the ownership?
          </h4>
          <p className="text-center font-normal text-sm text-[#777780]">
            Are you sure you want to make {anotherTeamAdmin?.name ?? ""} the
            owner of this organization account? You cannot undo this action.
          </p>
        </ZModalHeader>
        <ZModalFooter className="w-full justify-stretch">
          <ZButton
            variant="bordered"
            onClick={onCloseDialog}
            disabled={loading}
            className="flex-auto"
          >
            Cancel
          </ZButton>
          <ZButton
            color="danger"
            isLoading={loading}
            onClick={() => void handleOnClickTransfer()}
            disabled={loading}
            className="flex-auto"
          >
            {loading ? "Please Wait" : "Yes, Transfer Ownership"}
          </ZButton>
        </ZModalFooter>
      </ZModalContent>
    </ZModal>
  );
};

export default TeamOwnerTransferDialog;
