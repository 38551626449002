import AIProgress from "@/shared/components/ai-progress/AIProgressComponent";
import { ROUTES, TEAM_ROUTES } from "@/shared/constants/routes";
import { RouteObject } from "react-router-dom";
import Team from "../components";
import TeamInviteDialog from "../components/invite-dialog";
import TeamMemberRemoveDialog from "../components/remove-dialog";
import TeamOwnerTransferDialog from "../components/transfer-dialog";
import MemberUpdateDialog from "../components/update-dialog";

export const TeamRoutes: RouteObject[] = [
  {
    path: ROUTES.TEAM,
    // lazy: importLazy(() => import("@/modules/team/components")),
    Component: Team,
    children: [
      {
        path: TEAM_ROUTES.INVITE,
        // lazy: importLazy(() => import("../components/invite-dialog")), 
        Component: TeamInviteDialog,
      },
      {
        path: TEAM_ROUTES.REMOVE_MEMBER,
        // lazy: importLazy(() => import("../components/remove-dialog")),
        Component: TeamMemberRemoveDialog,
      },
      {
        path: TEAM_ROUTES.UPDATE_MEMBER,
        // lazy: importLazy(() => import("../components/update-dialog")),
        Component: MemberUpdateDialog,
      },
      {
        path: TEAM_ROUTES.TRANSFER_OWNER,
        // lazy: importLazy(() => import("../components/transfer-dialog")),
        Component: TeamOwnerTransferDialog,
      },
    ],
  },
  {
    path: 'reassess',
    Component: AIProgress
  }
];
