import { SVGProps } from "react";

const ClipboardListIcon = (props: SVGProps<SVGSVGElement>) => {
  const { color, ...rest } = props;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M16 4.00195C18.175 4.01395 19.353 4.11095 20.121 4.87895C21 5.75795 21 7.17195 21 9.99995V16C21 18.829 21 20.243 20.121 21.122C19.243 22 17.828 22 15 22H9C6.172 22 4.757 22 3.879 21.122C3 20.242 3 18.829 3 16V9.99995C3 7.17195 3 5.75795 3.879 4.87895C4.647 4.11095 5.825 4.01395 8 4.00195"
        stroke={color ?? "#71717A"}
        strokeWidth="1.5"
      />
      <path
        d="M10.5 14H17M7 14H7.5M7 10.5H7.5M7 17.5H7.5M10.5 10.5H17M10.5 17.5H17"
        stroke={color ?? "#71717A"}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M8 3.5C8 3.10218 8.15804 2.72064 8.43934 2.43934C8.72064 2.15804 9.10218 2 9.5 2H14.5C14.8978 2 15.2794 2.15804 15.5607 2.43934C15.842 2.72064 16 3.10218 16 3.5V4.5C16 4.89782 15.842 5.27936 15.5607 5.56066C15.2794 5.84196 14.8978 6 14.5 6H9.5C9.10218 6 8.72064 5.84196 8.43934 5.56066C8.15804 5.27936 8 4.89782 8 4.5V3.5Z"
        stroke={color ?? "#71717A"}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default ClipboardListIcon;
