import { Input, InputProps, TextAreaProps, Textarea } from "@nextui-org/react";

const ZInput = ({ children, ...props }: InputProps) => {
  return (
    <Input labelPlacement="outside" {...props}>
      {children}
    </Input>
  );
};

const ZTextarea = ({ children, ...props }: TextAreaProps) => {
  return <Textarea {...props}>{children}</Textarea>;
};

export { ZInput, ZTextarea };
