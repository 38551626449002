import { VENDOR_PREDEFINED_COMPLIANCE_CERTS } from "@/modules/agent/constants/vendorAssessmentConstants";
import ZAvatarGradient from "@/shared/components/avatarGradient";
import { Avatar, Checkbox } from "@nextui-org/react";
import AddCertificatePopup from "./AddCertificatePopup";
import { OverviewCardPropsForCompliance } from "./OverviewCards.types";

const ComplianceAdherenceEdit = (props: OverviewCardPropsForCompliance) => {
  const handleCheckboxChange = (isSelected: boolean, certName: string) => {
    if (isSelected) {
      props.handleChange([...props.values, certName]);
    } else {
      props.handleChange([
        ...props.values.filter(
          (v) => v.toLowerCase() !== certName.toLowerCase(),
        ),
      ]);
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="grid grid-cols-2 gap-4 items-center">
        {VENDOR_PREDEFINED_COMPLIANCE_CERTS.map((cert, idx) => (
          <div key={idx} className="flex gap-1 ">
            <Checkbox
              aria-label={props.title}
              size="md"
              isSelected={props.values.some(
                (v) => v.toLowerCase() === cert.label.toLowerCase(),
              )}
              onValueChange={(isSelected) =>
                handleCheckboxChange(isSelected, cert.label)
              }
            ></Checkbox>
            {cert.logo ? (
              <Avatar
                size="sm"
                name={cert.label}
                src={cert.logo}
                className="max-w-6 max-h-6 text-[8px]"
              />
            ) : (
              <ZAvatarGradient name={cert.label} size={24} textSize={8} />
            )}
            <div className="text-[#717179]">{cert.label}</div>
          </div>
        ))}
        {props.listOfExtraOptions.map((certName, idx) => (
          <div key={idx} className="flex gap-1 ">
            <Checkbox
              aria-label={props.title}
              size="md"
              isSelected={props.values.some(
                (v) => v.toLowerCase() === certName.toLowerCase(),
              )}
              onValueChange={(isSelected) =>
                handleCheckboxChange(isSelected, certName)
              }
            ></Checkbox>
            <ZAvatarGradient
              name={certName}
              size={24}
              textSize={8}
              backgroundColor="#E4E4E7"
              shadow="shadow-none"
              textColor="text-[#171717]"
            />
            <div className="text-[#717179]">{certName}</div>
          </div>
        ))}
      </div>

      <AddCertificatePopup />
    </div>
  );
};

export default ComplianceAdherenceEdit;
