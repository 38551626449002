import { AgentSessionStatus, AgentSessionStepType } from "@/modules/sessions/types";
import { Textarea } from "@nextui-org/react";
import clsx from "clsx";
import {
  type ChangeEvent,
  useCallback,
  useMemo,
  useRef
} from "react";
import { useParams } from "react-router-dom";
import { useAgentStepData } from "../../states";
import { ReviewSourceTypes } from "../../types/index.ts";
import { IdParams } from "../layout/header";
import AgentEditFieldWithInlineSources, {
  AgentEditFieldRef,
  FieldValueWithInlineSources,
} from "./AgentEditFieldWithInlineSources";

type Props = {
  fieldValueObjArray: FieldValueWithInlineSources[];
  title: string;
  showTitle?: boolean;
  onChange: (value: FieldValueWithInlineSources[]) => void;
  onSourceClick: (selectedSource: ReviewSourceTypes) => void;
  setListOfSourcesForSelectedSources: (
    selectedSource: ReviewSourceTypes[]
  ) => void;
  noBorder?: boolean;
  expandedView?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
};

const AgentEditFieldListWithInlineSources = ({
  fieldValueObjArray,
  onChange,
  onSourceClick,
  setListOfSourcesForSelectedSources,
  title,
  showTitle,
  noBorder,
  expandedView,
  isDisabled,
  placeholder,
}: Props) => {
  const placeholderString = useMemo(() => {
    return placeholder ?? "N/A";
  }, [placeholder]);
  // Create refs array for each field
  const fieldRefs = useRef<(AgentEditFieldRef | null)[]>([]);

  const handleChange = useCallback(
    (fieldValueObj: FieldValueWithInlineSources, index: number) => {
      const newFieldValueObjArray = [...fieldValueObjArray];
      if (!fieldValueObj.value) {
        // remove the item from the list if it is an empty list
        newFieldValueObjArray.splice(index, 1);
      } else {
        newFieldValueObjArray[index] = fieldValueObj;
      }
      onChange(newFieldValueObjArray);
    },
    [fieldValueObjArray, onChange],
  );

  const handleAddingNewItemToList = (e: ChangeEvent<HTMLInputElement>) => {
    const newText = e.target.value.trimStart();
    
    if (newText) {
      // Blur the add new item field immediately to stop multiple items from being added if the user types really fast
      e.target.blur();

      const newFieldValueObj: FieldValueWithInlineSources = {
        value: newText.trim(),
        sources: [],
        isEdited: true,
      };

      const newArray = [...fieldValueObjArray, newFieldValueObj];
      onChange(newArray);

      // Focus the newly added item after a short delay
      setTimeout(() => {
        const newIndex = newArray.length - 1;
        fieldRefs.current[newIndex]?.focus();
      }, 0);
    }
  };

  const { id } = useParams<keyof IdParams>() as IdParams;
  const stepData = useAgentStepData(id);
  const isReportReady =
    stepData?.find((step) => step.type === AgentSessionStepType.PREPARE_REPORT)
      ?.status === AgentSessionStatus.COMPLETE;

  return (
    <>
      {showTitle && title && (
        <div
          className="bg-[#F9F7F7] text-[#717171] font-medium py-2 px-4 text-[10px] leading-4"
          style={{
            border: "1px solid #E4E4E7",
            borderLeft: "0px",
            borderRight: "0px",
          }}
        >
          {title}
        </div>
      )}
      <ol className="relative flex flex-col">
        {fieldValueObjArray.map((fieldValueObj, index) => (
          <li
            key={index + title}
            className="bg-white flex gap-2 font-light px-4 py-2 text-small"
          >
            <span className="w-[18px]">{index + 1}.</span>
            <AgentEditFieldWithInlineSources
              // biome-ignore lint/suspicious/noAssignInExpressions: <explanation>
              ref={(el) => (fieldRefs.current[index] = el)}
              fieldValueObj={fieldValueObj}
              onChange={(newFieldValueObj) =>
                handleChange(newFieldValueObj, index)
              }
              onSourceClick={onSourceClick}
              setListOfSourcesForSelectedSources={setListOfSourcesForSelectedSources}
              noBorder={noBorder}
              noPadding={true}
              expandedView={expandedView}
              autoFocus={false}
              placeholder={`Add ${title}`}
              maxRows={999999}
              minRows={2}
              isDisabled={isDisabled}
            />
          </li>
        ))}
        {!isReportReady && (
          <li className="bg-white flex gap-2 font-light px-4 py-2 text-small opacity-70">
            <span>{fieldValueObjArray.length + 1}.</span>
            <div className="relative w-full">
              <Textarea
                className="text-[#121212] font-light bg-white focus-within:text-[#000000] h-fit"
                classNames={{
                  base: "shadow-none !border-none rounded-none truncate",
                  inputWrapper: clsx(
                    "p-0 rounded-md border-[1px]",
                    noBorder && "border-none",
                  ),
                }}
                variant="bordered"
                value={""}
                maxRows={2}
                minRows={2}
                placeholder={placeholderString}
                onChange={handleAddingNewItemToList}
                isReadOnly={isDisabled}
                onMouseDown={(e) => {
                  const textarea = e.target as HTMLTextAreaElement;
                  const clickPosition = textarea.selectionStart;
                  textarea.focus();
                  textarea.setSelectionRange(clickPosition, clickPosition);
                }}
              />
            </div>
          </li>
        )}
      </ol>
    </>
  );
};

export default AgentEditFieldListWithInlineSources;
