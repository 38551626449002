import { Button } from "@nextui-org/react";
import { useAgentSelector } from "../../states";

interface AgentActionsProps {
  selectedRows: string[];
  resetSelectedRows: () => void;
  agentId: string;
  isApproved: boolean;
  selectedId: string;
}

const AgentActions = ({
  selectedRows,
  resetSelectedRows,
  agentId,
  isApproved,
  selectedId,
}: AgentActionsProps) => {
  const { setApproved } = useAgentSelector.useActions();

  const disabled = selectedRows.length === 0 && isApproved;

  const approveAll = selectedRows.length > 1;

  const handleApprove = () => {
    if (selectedRows.length > 0) {
      setApproved(agentId, {
        id: Array.from(selectedRows),
        isApproved: true,
      });
      resetSelectedRows();
      return;
    }
    setApproved(agentId, {
      id: selectedId,
      isApproved: true,
    });
  };

  return (
    <>
      <div className="flex gap-2">
        {/* <Button
        onClick={resetSelectedRows}
        color="primary"
        variant="bordered"
        className="rounded-md text-sm leading-5 px-3 py-1  h-7"
        isDisabled={disabled}
      >
        Clear Selection
      </Button> */}
        <Button
          onClick={handleApprove}
          color="primary"
          className="rounded-md text-sm leading-5 px-3 py-1  h-7"
          isDisabled={disabled}
        >
          {approveAll
            ? "Accept all"
            : isApproved
              ? "Accepted"
              : "Accept Response"}
        </Button>
      </div>
    </>
  );
};

export default AgentActions;
