import ZAvatarGradient from "@/shared/components/avatarGradient";
import { ZButton } from "@/shared/components/button";
import ZBreadCrumbs from "@/shared/components/custom/breadcrumbs";
import { LoadingSpinner } from "@/shared/components/custom/spin";
import ErrorBoundary from "@/shared/components/error-boundary";
import { ZTableDeprecrated } from "@/shared/components/table-deprecrated/TableDeprecrated";
import { ROUTES, TEAM_ROUTES } from "@/shared/constants/routes";
import useQuery from "@/shared/hooks/use-query";
import { addNotification } from "@/shared/states/notification";
import { useStytch } from "@/shared/states/stytch";
import capitalizeFirstLetter from "@/shared/utils/capitalize-first-letter";
import throttle from "@/shared/utils/throttle";
import {
  Button,
  Chip,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Tooltip,
  cn,
} from "@nextui-org/react";
import { useStytchMember } from "@stytch/react/b2b";
/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import { Member } from "@stytch/vanilla-js";
import { Ellipsis } from "lucide-react";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import {
  getZaniaTeamMemberAccess,
  getZaniaTeamMemberRoles,
  useTeamState,
} from "../states";
import { TeamRole } from "../types";
import {
  inviteTeamMemberUseCase,
  reactivateTeamMemberUseCase,
  removeTeamMemberUseCase,
} from "../use-cases/team.use-case";
import { printErrorMessage } from "../utils";

const Team = () => {
  const {
    team,
    actions: { setTeam, setPermissions },
  } = useTeamState();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredMembers, setFilteredMembers] = useState<Member[]>([]);

  const navigate = useNavigate();
  const stytch = useStytch();

  const { isLoading: usersLoading } = useQuery({
    queryKey: ["users"],
    setter: setTeam,
    queryFn: async () => {
      const { members } = await stytch.organization.members.search({
        limit: 1000,
      });
      return members;
    },
  });

  const { isLoading: permissionsLoading } = useQuery({
    queryKey: ["permissions"],
    setter: setPermissions,
    queryFn: async () => {
      const permissions = await stytch.rbac.allPermissions();
      return permissions;
    },
  });

  const stytchMember = useStytchMember();
  const isLoading = loading || usersLoading || permissionsLoading;

  const handleSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(e.target.value);
      const filtered = team.filter(
        (member) =>
          member?.name
            ?.toLowerCase?.()
            ?.includes?.(e.target.value?.toLowerCase?.()) ||
          member?.email_address
            ?.toLowerCase?.()
            ?.includes?.(e.target.value?.toLowerCase?.()),
      );
      setFilteredMembers(filtered);
    },
    [team, setSearchTerm, setFilteredMembers],
  );

  const revokeInviteTeamMember = async ({
    memberId,
    name,
  }: {
    memberId: string;
    name: string;
  }) => {
    setLoading(true);
    try {
      await removeTeamMemberUseCase({ stytch, memberId });
      addNotification({
        type: "info",
        title: "Invitation Revoked",
        message: `Invitation sent to ${name} to join your team was revoked.`,
      });
    } catch (error) {
      console.error(error);
      addNotification({
        type: "error",
        title:
          ((error as Error).cause as string) ??
          `Could not remove ${name ?? "team member"} from your team`,
        message: printErrorMessage(JSON.stringify(error)),
      });
    } finally {
      setLoading(false);
    }
  };

  const reactivateTeamMember = async ({
    memberId,
    name,
  }: {
    memberId: string;
    name: string;
  }) => {
    setLoading(true);
    try {
      await reactivateTeamMemberUseCase({ stytch, memberId });
      addNotification({
        type: "success",
        title: "Team Member Reactivated",
        message: `${name ?? "Team member"} has been reactivated for your team.`,
      });
    } catch (error) {
      console.error(error);
      addNotification({
        type: "error",
        title:
          ((error as Error).cause as string) ??
          `Could not re-send invite to ${name ?? "team member"}`,
        message: (error as Error).message,
      });
    } finally {
      setLoading(false);
    }
  };

  const canZaniaInviteMember = useMemo(
    () =>
      stytchMember?.member?.roles?.some?.(
        ({ role_id }) =>
          role_id === TeamRole.ORG_ADMIN || role_id === TeamRole.ADMIN,
      ) ?? false,
    [stytchMember?.member?.roles],
  );

  const getMemberAccess = useCallback(
    (memberId: string) => {
      const roles =
        team?.find?.((member) => member.member_id === memberId)?.roles ?? [];
      if (roles.some(({ role_id }) => role_id === TeamRole.ORG_ADMIN)) {
        return TeamRole.ORG_ADMIN;
      } else if (roles.some(({ role_id }) => role_id === TeamRole.ADMIN)) {
        return TeamRole.ADMIN;
      } else {
        return TeamRole.MEMBER;
      }
    },
    [team],
  );

  return (
    <ErrorBoundary fallback={null}>
      <div className="w-full h-full flex flex-col">
        <div className="flex items-center pb-4 justify-between min-h-[44px] max-h-[44px]">
          <div className="flex gap-4 items-center">
            <ZBreadCrumbs
              items={[{ title: "Team", href: `/${ROUTES.TEAM}` }]}
            />
          </div>
          {canZaniaInviteMember && (
            <ZButton
              className="px-3 py-1 h-auto"
              disabled={isLoading}
              onClick={() =>
                navigate(`/${ROUTES.TEAM}/${TEAM_ROUTES.INVITE}`, {
                  replace: false,
                })
              }
            >
              Invite Member
            </ZButton>
          )}
        </div>
        <div className="h-full w-full overflow-hidden rounded-lg flex flex-col">
          <div className="px-6 py-4 flex flex-col bg-white  gap-2 border-b border-border">
            <div className="flex items-center grow justify-between w-full">
              <div className="flex items-center grow flex-1 gap-2">
                <p className="text-sm font-medium leading-5 text-[#171717]">
                  All Members
                </p>
                <div className="p-1 bg-[#F4F4F5] px-2 rounded-lg">
                  <p className="text-[10px] text-[#52525B] leading-4 font-medium">
                    {team?.length ?? 0}
                  </p>
                </div>
              </div>
              <div className="flex justify-center grow flex-1 items-center ">
                <div className="w-full max-w-96">
                  <Input
                    className="grow max-w-96 rounded-md bg-[#F4F4F5]"
                    placeholder="Search by Name or Email"
                    disabled={isLoading}
                    // NOTE: Use throttle as long as search is performed locally
                    // Use debounce if search is performed on the server via API
                    onChange={throttle(handleSearch, 200)}
                  />
                </div>
              </div>
              <div className="flex-1" />
            </div>
          </div>
          <div className="grow overflow-hidden flex">
            <div className="flex-1 flex flex-col bg-white overflow-auto p-4">
              <ZTableDeprecrated<Member>
                className="border-1 border-[#E4E4E7] rounded-lg overflow-auto scroll-auto"
                loading={isLoading}
                rows={searchTerm ? filteredMembers : team}
                columns={[
                  {
                    type: "string",
                    fieldName: "name",
                    headerName: "Name",
                    sortable: true,
                    renderCell: (row) => {
                      return (
                        <div className="flex items-center gap-3">
                          <ZAvatarGradient name={row.name} />
                          <p className="font-medium">{row.name}</p>
                        </div>
                      );
                    },
                  },
                  {
                    type: "string",
                    fieldName: "email_address",
                    headerName: "Email",
                    renderCell: ({ email_address }) => {
                      return <div className="text-[#333]">{email_address}</div>;
                    },
                  },
                  {
                    type: "string",
                    fieldName: "role",
                    headerName: "Role",
                    sortable: true,
                    getSortValue: ({ member_id }) => {
                      const { isTeamOrgAdmin, isTeamAdmin, isTeamMember } =
                        getZaniaTeamMemberRoles(member_id);
                      return isTeamOrgAdmin
                        ? 0
                        : isTeamAdmin
                          ? 1
                          : isTeamMember
                            ? 2
                            : 3;
                    },
                    renderCell: ({ member_id }) => {
                      const { isTeamOrgAdmin, isTeamAdmin, isTeamMember } =
                        getZaniaTeamMemberRoles(member_id);
                      return (
                        <div className="text-[#333]">
                          {isTeamOrgAdmin
                            ? "Owner"
                            : isTeamAdmin
                              ? "Admin"
                              : isTeamMember
                                ? "Member"
                                : ""}
                        </div>
                      );
                    },
                  },
                  {
                    type: "string",
                    fieldName: "access",
                    headerName: "Access",
                    renderCell: ({ member_id }) => {
                      const permissions = getZaniaTeamMemberAccess(member_id);
                      if (
                        !Array.isArray(permissions) ||
                        permissions.length === 0
                      ) {
                        return null;
                      }
                      return (
                        <div className="flex items-center gap-1">
                          {permissions.slice(0, 2).map((data) => {
                            return (
                              <Chip
                                key={data?.roleId}
                                variant="bordered"
                                radius="sm"
                                size="sm"
                                className="bg-white border-small text-[10px] leading-3 py-0 px-1"
                                isDisabled={isLoading}
                              >
                                {data?.label}
                              </Chip>
                            );
                          })}
                          {permissions.length > 2 && (
                            <Tooltip
                              showArrow={true}
                              content={permissions
                                .slice(2)
                                .map(({ roleId, label }) => (
                                  <p
                                    key={roleId}
                                    className="text-white text-tiny"
                                  >
                                    {label}
                                  </p>
                                ))}
                              classNames={{
                                content:
                                  "bg-black bg-opacity-80 backdrop-blur-md px-3 py-2 items-start gap-1.5",
                                arrow:
                                  "bg-black bg-opacity-80 backdrop-blur-md",
                              }}
                            >
                              <Chip
                                variant="bordered"
                                radius="sm"
                                className="bg-white border-none px-0"
                                classNames={{
                                  content:
                                    "px-0 text-blue-500 highlight font-medium text-[10px] leading-3 ",
                                }}
                                isDisabled={isLoading}
                              >
                                +{permissions.length - 2} other
                                {permissions.length > 3 ? "s" : ""}
                              </Chip>
                            </Tooltip>
                          )}
                        </div>
                      );
                    },
                  },
                  {
                    type: "string",
                    fieldName: "status",
                    headerName: "Account Status",
                    sortable: true,
                    renderCell: (row) => {
                      return (
                        <div className="text-[#333]">
                          {capitalizeFirstLetter(row.status)}
                        </div>
                      );
                    },
                  },
                  {
                    type: "custom",
                    fieldName: "",
                    headerName: "",
                    renderCell: ({
                      member_id: memberId,
                      name,
                      email_address: email,
                      status,
                      roles,
                    }) => {
                      // NOTE: status: 'active' | 'invited' | 'deleted'
                      const dropdownMenuItems = [];

                      const isCurrentMemberAdmin =
                        stytchMember?.member?.roles?.some?.(
                          ({ role_id }) => role_id === TeamRole.ADMIN,
                        );
                      const isCurrentMemberOrgAdmin =
                        stytchMember?.member?.roles?.some?.(
                          ({ role_id }) => role_id === TeamRole.ORG_ADMIN,
                        );
                      const { isTeamOrgAdmin, isTeamAdmin, isTeamMember } =
                        getZaniaTeamMemberRoles(memberId);
                      if (
                        isCurrentMemberOrgAdmin ||
                        (isCurrentMemberAdmin &&
                          !isTeamOrgAdmin &&
                          !isTeamAdmin)
                      ) {
                        dropdownMenuItems.push(
                          <DropdownItem
                            key="update-team-member"
                            onClick={() =>
                              navigate(`update/${memberId}`, { replace: false })
                            }
                          >
                            Update Profile Settings
                          </DropdownItem>,
                        );
                      }

                      if (
                        (isCurrentMemberOrgAdmin ||
                          (isTeamMember && isCurrentMemberAdmin)) &&
                        status !== "invited"
                      ) {
                        dropdownMenuItems.push(
                          <DropdownItem
                            key="remove-team-member"
                            onClick={() =>
                              navigate(
                                isCurrentMemberOrgAdmin &&
                                  memberId === stytchMember?.member?.member_id
                                  ? `transfer/${memberId}`
                                  : `remove/${memberId}`,
                                { replace: false },
                              )
                            }
                          >
                            <span className="text-red-500 highlight">
                              Remove Member
                            </span>
                          </DropdownItem>,
                        );
                      }

                      if (isCurrentMemberOrgAdmin || isCurrentMemberAdmin) {
                        if (status === "invited") {
                          dropdownMenuItems.push(
                            <DropdownItem
                              key="invite-again"
                              onClick={() => {
                                void inviteTeamMemberUseCase({
                                  stytch,
                                  inviteDetails: {
                                    name,
                                    email,
                                    override_roles: roles?.map(
                                      ({ role_id }) => role_id,
                                    ),
                                  },
                                  isReInvite: true,
                                })
                                  .then(() => {
                                    addNotification({
                                      type: "success",
                                      title: "Invitation Resent",
                                      message: `Invitation has been resent to ${name}.`,
                                    });
                                  })
                                  .catch((error) => {console.error(error);
                                    addNotification({
                                      type: "error",
                                      title: "Failed to Resend Invitation",
                                      message: printErrorMessage(
                                        JSON.stringify(error),
                                      ),
                                    });
                                  });
                              }}
                            >
                              <span className="text-black-500 highlight">
                                Invite Again
                              </span>
                            </DropdownItem>,
                          );
                          dropdownMenuItems.push(
                            <DropdownItem
                              key="remove-team-member"
                              onClick={() =>
                                void revokeInviteTeamMember({ memberId, name })
                              }
                            >
                              <span className="text-red-500 highlight">
                                Revoke Invitation
                              </span>
                            </DropdownItem>,
                          );
                        } else if (status === "deleted") {
                          dropdownMenuItems.push(
                            <DropdownItem
                              key="remove-team-member"
                              onClick={() =>
                                void reactivateTeamMember({ memberId, name })
                              }
                            >
                              <span className="text-blue-500 highlight">
                                Reactivate Profile
                              </span>
                            </DropdownItem>,
                          );
                        }
                      }

                      if (dropdownMenuItems.length === 0) {
                        return <div className="h-10 w-5"></div>;
                      }

                      return (
                        <div className="relative flex justify-end items-center h-10">
                          <Dropdown>
                            <DropdownTrigger disabled={isLoading}>
                              <Button
                                isIconOnly
                                disabled={isLoading}
                                className={cn(
                                  "bg-transparent",
                                  (isTeamOrgAdmin ||
                                    (isCurrentMemberAdmin && isTeamAdmin) ||
                                    memberId ===
                                      stytchMember.member?.member_id) &&
                                    "invisible",
                                )}
                              >
                                <Ellipsis className="h-5 w-5" color="#717179" />
                              </Button>
                            </DropdownTrigger>
                            <DropdownMenu>{dropdownMenuItems}</DropdownMenu>
                          </Dropdown>
                        </div>
                      );
                    },
                    className: "w-[100px]",
                  },
                ]}
                isHeaderSticky={true}
                isCompact={true}
                removeWrapper={true}
                getRowId={(row) => row.member_id}
                tableHeaderProps={{
                  className: "shadow-none rounded-none",
                }}
                tableBodyProps={{
                  emptyContent: isLoading ? (
                    <div className="flex">
                      <LoadingSpinner
                        containerClassName="bg-transparent relative"
                        className="mx-auto"
                      />
                    </div>
                  ) : (
                    "No team members found"
                  ),
                }}
                tableRowProps={(_, rowIndex) => {
                  return {
                    className: cn(
                      rowIndex === 0 ? "border-none" : "border-y",
                      "&:hover:bg-[#F2F2F2]",
                    ),
                  };
                }}
                classNames={{
                  wrapper: "shadow-none rounded-none",
                }}
                aria-label="Team Member List"
              />
            </div>
          </div>
        </div>
        <div className="grow overflow-hidden rounded-xl">
          <Outlet />
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default Team;
