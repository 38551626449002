import {
  isControlValid,
  useCustomizeControlsStore,
} from "@/modules/agent/states/cusomize-controls";
import { memo } from "react";

const CustomizeControlTopLeftSection = () => {
  const selectedControls = useCustomizeControlsStore().selectedControls;
  const selectedTab = useCustomizeControlsStore().selectedTab;

  const validControlNumber = useCustomizeControlsStore().dataState.filter(
    (c) => isControlValid(c) && c.tsc === selectedTab
  ).length;

  return (
    <div>
      {selectedControls.size > 0 ? (
        <>
          <div className="text-sm font-medium">
            {selectedControls.size} control
            {selectedControls.size > 1 ? "s" : ""} selected
          </div>
        </>
      ) : (
        <div className="flex gap-2">
          <div className="text-sm font-medium">Edit or Add Controls</div>
          <div className="p-1 bg-[#F4F4F5] px-2 rounded-lg">
            <p className="text-[10px] text-[#52525B] leading-4   font-medium">
              {`${validControlNumber}`}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(CustomizeControlTopLeftSection);
