import { AgentLogId, AgentLogStepsData } from "../../types/sidebar";

export const riskAssessmentData: AgentLogStepsData[] = [
  {
    id: AgentLogId.Id1,
    completed: true,
    title: "Onboard NIST CSF 2.0 report template",
    isWaiting: false,
    isCurrent: false,
    description: [
      "Load NIST CSF 2.0 risk assessment report template on the platform",
    ],
  },
  {
    id: AgentLogId.Id2,
    completed: false,
    title: "Extract controls and specifications",
    isWaiting: false,
    isCurrent: true,
    description: [
      "Extract controls of NIST CSF 2.0 framework and compile them into a list",
    ],
  },
  {
    id: AgentLogId.Id3,
    completed: false,
    title: "Generate responses for controls",
    isWaiting: false,
    isCurrent: false,
    description: [
      "Generate observations, gaps, risk statement and recommendations using evidences provided",
      "Assign risk level and write reasoning",
      "Add sources and justification",
    ],
  },
  {
    id: AgentLogId.Id4,
    completed: false,
    title: "Gather input from owner to verify or edit responses",
    isCurrent: false,
    description: ["Edit or accept responses generated by AI for all controls"],
    isWaiting: false,
  },
  {
    id: AgentLogId.Id5,
    completed: false,
    title: "Prepare file for download",
    isWaiting: false,
    isCurrent: false,
    description: [
      "Fill risk assessment report template with all responses generated",
    ],
  },
];

export const doraAssessmentData: AgentLogStepsData[] = [
  {
    id: AgentLogId.Id1,
    completed: true,
    title: "Onboard DORA report template",
    isWaiting: false,
    isCurrent: false,
    description: ["Load DORA gap assessment report template on the platform"],
  },
  {
    id: AgentLogId.Id2,
    completed: false,
    title: "Extract controls and specifications",
    isWaiting: false,
    isCurrent: true,
    description: [
      "Extract controls of DORA framework and compile them into a list",
    ],
  },
  {
    id: AgentLogId.Id3,
    completed: false,
    title: "Generate responses for controls",
    isWaiting: false,
    isCurrent: false,
    description: [
      "Generate observations, gaps, gap statement and recommendations using evidences provided",
      "Assign gap level and write reasoning",
      "Add sources and justification",
    ],
  },
  {
    id: AgentLogId.Id4,
    completed: false,
    title: "Gather input from owner to verify or edit responses",
    isCurrent: false,
    description: ["Edit or accept responses generated by AI for all controls"],
    isWaiting: false,
  },
  {
    id: AgentLogId.Id5,
    completed: false,
    title: "Prepare file for download",
    isWaiting: false,
    isCurrent: false,
    description: [
      "Fill gap assessment report template with all responses generated",
    ],
  },
];

export const questionnaireData: AgentLogStepsData[] = [
  {
    id: AgentLogId.Id1,
    completed: false,
    title: "Map out the structure and format of the questionnaire file",
    isWaiting: false,
    isCurrent: false,
    description: [
      "Auto-map functions to columns on all sheets of the questionnaire file",
    ],
  },
  {
    id: AgentLogId.Id2,
    completed: false,
    title: "Gather input from owner to verify structure and format",
    isWaiting: false,
    isCurrent: false,
    description: [
      "Designate functions to columns on all sheets containing questions to be answered",
      "Retrieve the Questions, Answers, and Comments columns",
    ],
  },
  {
    id: AgentLogId.Id3,
    completed: false,
    title: "Extract questions from the questionnaire file",
    isWaiting: false,
    isCurrent: false,
    description: [
      "Extract questions from the questionnaire file and compile them into a list",
    ],
  },
  {
    id: AgentLogId.Id4,
    completed: false,
    title: "Generate responses for questions",
    isWaiting: false,
    isCurrent: false,
    description: [
      "Generate answers and comments for the questions",
      "Add sources and justification",
    ],
  },
  {
    id: AgentLogId.Id5,
    completed: false,
    title: "Gather input from owner to verify or edit responses",
    isCurrent: false,
    description: ["Edit or accept responses generated by AI for all questions"],
    isWaiting: false,
  },
  {
    id: AgentLogId.Id6,
    completed: false,
    title: "Prepare file for download",
    isWaiting: false,
    isCurrent: false,
    description: ["Fill questionnaire file with all responses generated"],
  },
];
