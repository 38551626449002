/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import { StytchClientType } from "@/shared/types/stytch";
import { addMember, removeMember, updateMember } from "../states";
import { TeamRole } from "../types";

export const defaultTeamMemberRoles = [];

export const inviteTeamMemberUseCase = async ({
  stytch,
  inviteDetails,
  isReInvite = false,
}: {
  inviteDetails: {
    name: string;
    email: string;
    role?: TeamRole;
    access?: TeamRole[];
    override_roles?: string[];
    invite_redirect_url?: string;
  };
  stytch: StytchClientType;
  isReInvite?: boolean;
}) => {
  const { member } = await stytch.magicLinks.email.invite({
    email_address: inviteDetails.email,
    name: inviteDetails.name,
    roles:
      Array.isArray(inviteDetails.override_roles) &&
      inviteDetails.override_roles.length > 0
        ? [...inviteDetails.override_roles, ...defaultTeamMemberRoles].filter(
            (item) => {
              return item !== TeamRole.MEMBER;
            },
          )
        : [
            inviteDetails.role as string,
            ...(inviteDetails.access ?? []),
            ...defaultTeamMemberRoles,
          ]
            .filter((item) => {
              return item !== TeamRole.MEMBER;
            })
            .filter(Boolean),
    invite_redirect_url:
      inviteDetails.invite_redirect_url ?? `${window.location.origin}/auth`,
  });

  if (!isReInvite) {
    addMember(member);
  }

  return member;
};

export const removeTeamMemberUseCase = async ({
  stytch,
  memberId,
}: {
  memberId: string;
  stytch: StytchClientType;
}) => {
  await stytch.organization.members.delete(memberId);
  removeMember(memberId);
};

export const updateTeamMemberUseCase = async ({
  stytch,
  updates,
}: {
  updates: {
    memberId: string;
    role?: TeamRole;
    access?: TeamRole[];
    override_roles?: string[];
  };
  stytch: StytchClientType;
}) => {
  const { member } = await stytch.organization.members.update({
    member_id: updates.memberId,
    roles:
      Array.isArray(updates.override_roles) && updates.override_roles.length > 0
        ? updates.override_roles.filter((item) => {
            return item !== TeamRole.MEMBER;
          })
        : [updates.role as string, ...(updates.access ?? [])]
            .filter((item) => {
              return item !== TeamRole.MEMBER;
            })
            .filter(Boolean),
  });
  updateMember(member);
  return member;
};

export const reactivateTeamMemberUseCase = async ({
  stytch,
  memberId,
}: {
  memberId: string;
  stytch: StytchClientType;
}) => {
  const { member } = await stytch.organization.members.reactivate(memberId);
  updateMember(member);
  return member;
};
