import ErrorIllustration from "../ErrorIllustration/ErrorIllustration";
import { ZButton } from "../button";

const ErrorComponent = () => {
  return (
    <div className="flex flex-col h-3/4 w-full items-center justify-center">
      <div className="p-8">
        <ErrorIllustration />
      </div>

      <div className="font-normal text-center text-lg ">
        Something went wrong...
      </div>
      <div className="font-normal text-center text-[#71717A] text-sm mt-4">
        We’re sorry for this inconvenience. Your progress will not be lost.
        Please reload the page to proceed
      </div>
      <div>
        <ZButton
          variant="bordered"
          color="primary"
          onClick={() => window.location.reload()}
          className="mt-10"
        >
          Reload
        </ZButton>
      </div>
    </div>
  );
};

export default ErrorComponent;
