import { get } from "@/infra/rest";
import { StytchClientType } from "@/shared/types/stytch";
import { getEmailCompanySlug } from "@/shared/utils/organization-helper";
import { ListOfOrganizationByEmail } from "../types";

export const validateSSOLogin = async ({
  stytch,
  email,
}: {
  stytch: StytchClientType;
  email: string;
}) => {
  const slug = getEmailCompanySlug(email);
  const { organization } = await stytch.organization.getBySlug({
    organization_slug: slug,
  });

  if (!organization || !organization.sso_default_connection_id) {
    throw new Error("SSO not allowed for this email id");
  }

  await stytch.sso.start({
    connection_id: organization?.sso_default_connection_id,
    login_redirect_url: `${window.location.origin}/auth`,
    signup_redirect_url: `${window.location.origin}/auth`,
  });

  return;
};

export const getListOfOrganizationBasedOnEmail: (
  q: string,
) => Promise<string[]> = async (q) => {
  try {
    const organizations = await get<ListOfOrganizationByEmail>({
      url: `/v0/sso/get-orgs?user_email=${q}`,
      isAuthRequired: false,
    });

    return organizations.payload;
  } catch (err) {
    console.error(err);
    throw new Error('Failed to get organization');
  }
};
