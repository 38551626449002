import type { AxiosError } from "axios";

interface ErrorResponse extends Omit<AxiosError["response"], "data"> {
  data: Record<string, Array<string>>;
}
interface Error extends Omit<AxiosError, "response"> {
  response: ErrorResponse;
}

export type ParsePromiseReturnType<T> = [Error, null] | [null, T];

/**
 * function to replace try/catch
 *  block with async await.
 * This resolves promise and returns
 * it with array of [error, response]
 * @param {*} promise
 * @returns {Array<*>}
 */
const parsePromise = <T>(
  promise: Promise<T>,
): Promise<ParsePromiseReturnType<T>> =>
  promise
    .then((response) => [null, response] as ParsePromiseReturnType<T>)
    .catch((error) => [error, null] as ParsePromiseReturnType<T>);

export default parsePromise;
