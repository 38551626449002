/* eslint-disable @typescript-eslint/no-unsafe-argument */
type SomeFunction = (...args: any[]) => void;

/**
 *
 * @param func The original, non throttled function (You can pass any number of args to it)
 * @param delay The delay (in ms) for the function to return
 * @returns The throttled function, which will run at most once every (delay) ms
 */
function throttle<Func extends SomeFunction>(func: Func, delay = 1000) {
  let shouldWait = false;
  let waitingArgs: any;

  const timeoutFunc = () => {
    if (waitingArgs == null) {
      shouldWait = false;
    } else {
      func(...waitingArgs);
      waitingArgs = null;
      setTimeout(timeoutFunc, delay);
    }
  };

  return (...args: any[]) => {
    if (shouldWait) {
      waitingArgs = args;
      return;
    }
    func(...args);
    shouldWait = true;
    setTimeout(timeoutFunc, delay);
  };
}

export default throttle;
