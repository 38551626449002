import { SVGProps } from "react";

const WarningtIcon = (props: SVGProps<SVGSVGElement>) => {
  const { color, ...rest } = props;
  return (
    <svg
      width="240"
      height="240"
      viewBox="0 0 240 240"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M176.647 91.5113C185.213 105.37 192.891 120.695 188.551 136.49C184.76 150.291 169.733 155.842 157.111 162.627C146.121 168.534 135.229 171.874 122.793 171.836C96.4705 171.755 57.8186 186.805 48.4171 162.291C39.0986 137.993 83.6574 128.243 97.5678 106.062C111.576 83.7252 101.148 41.7089 127.025 36.9474C152.596 32.2422 163.043 69.501 176.647 91.5113Z"
        fill="url(#paint0_linear_1297_54039)"
      />
      <path
        opacity="0.9"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M176.647 91.5113C185.213 105.37 192.891 120.695 188.551 136.49C184.76 150.291 169.733 155.842 157.111 162.627C146.121 168.534 135.229 171.874 122.793 171.836C96.4705 171.755 57.8186 186.805 48.4171 162.291C39.0986 137.993 83.6574 128.243 97.5678 106.062C111.576 83.7252 101.148 41.7089 127.025 36.9474C152.596 32.2422 163.043 69.501 176.647 91.5113Z"
        fill="white"
      />
      <path
        d="M120.001 205.229C171.4 205.229 213.068 202.796 213.068 199.795C213.068 196.794 171.4 194.361 120.001 194.361C68.6012 194.361 26.9336 196.794 26.9336 199.795C26.9336 202.796 68.6012 205.229 120.001 205.229Z"
        fill="#F5F5F5"
      />
      <rect
        x="74"
        y="62"
        width="92"
        height="116"
        rx="4.75"
        fill="white"
        stroke="#71717A"
        strokeWidth="4"
      />
      <g opacity="0.5">
        <rect
          x="90"
          y="77.25"
          width="20.5"
          height="20.5"
          rx="6"
          stroke="#71717A"
          strokeWidth="4"
          strokeLinecap="round"
        />
        <path
          d="M104 85.188L98.6292 90.688L96.5 88.5075"
          stroke="#71717A"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <line
          x1="122.5"
          y1="82.5"
          x2="146.25"
          y2="82.5"
          stroke="#71717A"
          strokeWidth="4"
          strokeLinecap="round"
        />
        <line
          x1="122.5"
          y1="88.5"
          x2="135"
          y2="88.5"
          stroke="#71717A"
          strokeWidth="4"
          strokeLinecap="round"
        />
      </g>
      <g opacity="0.5">
        <rect
          x="90"
          y="109.75"
          width="20.5"
          height="20.5"
          rx="6"
          stroke="#71717A"
          strokeWidth="4"
          strokeLinecap="round"
        />
        <path
          d="M104 117.687L98.6292 123.187L96.5 121.007"
          stroke="#71717A"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <line
          x1="122.5"
          y1="115"
          x2="146.25"
          y2="115"
          stroke="#71717A"
          strokeWidth="4"
          strokeLinecap="round"
        />
        <line
          x1="122.5"
          y1="121"
          x2="135"
          y2="121"
          stroke="#71717A"
          strokeWidth="4"
          strokeLinecap="round"
        />
      </g>
      <rect
        x="90"
        y="142.25"
        width="20.5"
        height="20.5"
        rx="6"
        stroke="#71717A"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <line
        x1="122.5"
        y1="147.5"
        x2="146.25"
        y2="147.5"
        stroke="#71717A"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <line
        x1="122.5"
        y1="153.5"
        x2="135"
        y2="153.5"
        stroke="#71717A"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1297_54039"
          x1="174.17"
          y1="70.0411"
          x2="63.8516"
          y2="180.36"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DD9AB0" />
          <stop offset="1" stopColor="#A982CF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default WarningtIcon;
